<template>
  <div class="container ">
      <div class="row g-4">
        <div class="col-12 col-xxl-12">
          <div class="card border-0 shadow-sm">
            <div class="card-body p-4">
              <div id="history" class="w-100 mb-3">
                <div class="tab-content">
                  <div class="tab-pane fade show active" id="tab1" role="tabpanel" aria-labelledby="tab--1">
                    <div class="table-responsive border shadow-sm dashboard-table activity-table">
                      <table class="table mb-0">
                        <tbody>
                          
                          <tr>
                            <th><span class="d-inline-block fw-bold fz-14"><span>Date</span></span></th>
                            <td><span class="d-inline-block fw-bold fz-14"><span>Message</span></span></td>
                            <td><span class="d-inline-block fw-bold fz-14"><span>Type</span></span></td>
                          </tr>
                          <tr v-if="user.status === 'verified'">
                            <th>
                              <span class="d-inline-block fw-bold fz-14">
                                <span>{{ user.updated_at }}</span>
                              </span>
                            </th>
                            <td>
                              <span class="d-inline-block fw-bold fz-14">
                                Verification is completed!
                              </span>
                            </td>
                            <td>
                              <span class="d-inline-block fw-bold fz-14">
                                INFO
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <th>
                              <span class="d-inline-block fw-bold fz-14">
                                <span>{{ user.created_at }}</span>
                              </span>
                            </th>
                            <td>
                              <span class="d-inline-block fw-bold fz-14">
                                Your's account registered successfully!
                              </span>
                            </td>
                            <td>
                              <span class="d-inline-block fw-bold fz-14">
                                INFO
                              </span>
                            </td>
                          </tr>
                          
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "Notification",
  computed: {
    ...mapState(["user"]),
  }
};
</script>

