<template>
    <div class="register-area">
      <FlashMessage position="right top">></FlashMessage>
      <div class="container pt-5">
        <div class="row g-4 g-lg-5 align-items-center justify-content-center">
          <div class="col-xl-6">
            <div class="register-card text-center">
              <h2>Welcome Back!</h2>
               <img class="login-logo" src="~@/assets/img/trade-images/logregfor/log1.png">
              <p>Didn't have an account?<router-link class="ms-1 hover-primary" to="registration">Register now!</router-link></p>
              <!-- Register Form -->
              <div class="register-form mt-5">
                
                  <div class="form-group mb-4">
                    <input 
                      class="form-control" 
                      type="email" 
                      placeholder="Email Address" 
                      v-model="user.email" 
                      required>
                  </div>
                  <div class="form-group mb-4">
                    <label class="label-psswd" for="registerPassword" @click="toglePasswordView">Show</label>
                    <input 
                      class="form-control" 
                      id="registerPassword" 
                      :type="isPasswordView" 
                      placeholder="Password" 
                      v-model="user.password" 
                      required
                    >
                  </div>
                  <button 
                    class="btn btn-success w-100" 
                    type="submit" 
                    @click="submitLoginData()"
                  >Sign In</button>
                
                <!-- <div class="login-meta-data d-flex align-items-center justify-content-between">
                  <div class="form-check mt-4">
                    <input class="form-check-input" id="rememberMe" type="checkbox" value="" checked>
                    <label class="form-check-label" for="rememberMe">Keep me logged in</label>
                  </div><a class="forgot-password mt-4 text-primary fz-16" href="forget-password.html">Forgot Password?</a>
                </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  <!-- <div class="row">
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 login-form">
      <div
        class="row center-lg center-md center-sm center-xs middle-lg middle-md middle-sm middle-xs"
      >
        <div class="col-lg-3 login-body">
          <div class="row center-lg center-md center-sm center-xs">
            <div class="col-lg-10">
              <div class="login-title">
                {{ $t("login.title") }}
              </div>
              <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 login-email">
                <div class="row start-lg">
                  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <label>{{ $t("login.email_address") }}</label>
                  </div>
                  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <input type="email" placeholder="" v-model="user.email" />
                  </div>
                </div>
              </div>

              <div class="login-Password">
                <div class="row start-lg">
                  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <label>{{ $t("login.password") }}</label>
                  </div>
                  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <input
                      type="password"
                      placeholder=""
                      v-model="user.password"
                    />
                  </div>
                </div>
              </div>
              <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="row center-lg center-md center-sm center-xs">
                  <div
                    class="col-lg-12 col-md-12 col-sm-12 col-xs-12 login-submit"
                  >
                    <button class="lg-sb" @click="submitLoginData()">
                      {{ $t("login.continue") }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr />
          <div class="login-footer">
            <div class="login-sing-up">
              {{ $t("login.not_account") }}
              <router-link to="/registration">{{
                $t("login.sign_up")
              }}</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> -->
</template>
<script>
import router from "@/router";
import { required, sameAs, minLength, email, numeric, alpha } from 'vuelidate/lib/validators';

export default {
  name: "Login",
  data() {
    return {
      user: {
        email: "",
        password: ""
      },
      isPasswordView: "password",
    };
  },
  methods: {
    submitLoginData() {
      if (!this.user.email || !this.user.password) {
        return;
      }

      this.$store.dispatch("auth", this.user)
        .then(res => {
          this.$store.commit("updateToken", res.body.token);
          this.$store.commit("updateUser", res.body.user);

          localStorage.setItem("token", res.body.token);
          localStorage.setItem("user", JSON.stringify(res.body.user));
          
          router.push("/terminal");
          
          return res.status === 202;
        })
        .catch(() => {
          this.$store.commit("updateToken", "");
          this.$store.commit("updateUser", {});
          localStorage.setItem("token", "");
          localStorage.setItem("user", JSON.stringify({}));

          this.flashMessage.show({
            status: 'error',
            title: 'The Login Form is Unathorized!',
            message: 'Please, check your account credentials or try to send letter to admin!'
          });

          return false;
        });
    },
    toglePasswordView(){
      if(this.isPasswordView == "password"){
        this.isPasswordView = "text"
      }else {
        this.isPasswordView = "password"
      }
    }
  }
};
</script>

<style scoped>
.login-logo {
  max-width: 60%;
}

/deep/._vue-flash-msg-body {
  z-index: 999;
}
</style>