<template>
  <div class="coming-soon-wrapper stastic__image">
    <div class="cs-content w-100">
      <div class="container">
        <div class="justify-content-center">
          <div class="col-12 col-lg-12">
            <!-- Countdown -->
            <h2 class="display-4 mb-4 fw-bold">Statistic Is Coming Soon</h2>
            <div class="bid-ends">
              <div><span class="days">{{days}}</span><span>Days</span></div>
              <div><span class="hours">{{hours}}</span><span>Hours</span></div>
              <div><span class="minutes">{{minutes}}</span><span>Min</span></div>
              <div><span class="seconds">{{seconds}}</span><span>Sec</span></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment"; 
import { mapState } from "vuex";
export default {
  name: "Statistic",
  data(){
    return{
      days: null,
      hours: null,
      minutes: null,
      seconds: null,
      interval: null,
    }
  },
  computed: mapState(["user"]),
  mounted(){
    clearInterval(this.interval)
    this.time()
  },
  methods: {
    time(){
      let userTime = moment(this.user.created_at).add(30, 'd');
      this.interval = setInterval(() => {
         const now = moment();
         const delta = userTime - now;
         this.days = userTime.diff(now, "days");
         this.hours = userTime.diff(now, "hours") % userTime.diff(now, "days");
         this.minutes = userTime.diff(now, "minutes") - (userTime.diff(now, "hours") * 60);
         this.seconds = userTime.diff(now, "seconds") - userTime.diff(now, "minutes") * 60;
      }, 1000)
    }   
  }
};
</script>
<style scoped>
.stastic__image{
  background: url("~@/assets/img/bg-img/1.jpg") no-repeat;
    -moz-background-size: 100%; /* Firefox 3.6+ */
    -webkit-background-size: 100%; /* Safari 3.1+ и Chrome 4.0+ */
    -o-background-size: 100%; /* Opera 9.6+ */
    background-size: 100%;
    background-size: cover;
}
.container{
  margin: auto !important;
}

.coming-soon-wrapper {
  min-width: 100%;
}
</style>
