<template>
  <section class="features">
    <div class="container">
      <div class="row features-row">
        <!-- Feature Box Starts -->
        <div class="feature-box col-md-4 col-sm-12">
          <span class="feature-icon">
            <img
              id="download-bitcoin"
              src="@/assets/main-page/download-bitcoin.png"
              alt="download bitcoin"
            />
          </span>
          <div class="feature-box-content">
            <h3 class="Count" data-stop="9721">9 721</h3>
            <!--  <p>active traders last month</p> -->
            <p>{{ $t("live_counts.text1") }}</p>
          </div>
        </div>
        <!-- Feature Box Ends -->
        <!-- Feature Box Starts -->
        <div class="feature-box two col-md-4 col-sm-12">
          <span class="feature-icon">
            <img
              id="add-bitcoins"
              src="@/assets/main-page/add-bitcoins.png"
              alt="add bitcoins"
            />
          </span>
          <div class="feature-box-content">
            <h3 class="Count" data-stop="19510128">$ 19 510 128</h3>
            <p>{{ $t("live_counts.text2") }}</p>
          </div>
        </div>
        <!-- Feature Box Ends -->
        <!-- Feature Box Starts -->
        <div class="feature-box three col-md-4 col-sm-12">
          <span class="feature-icon">
            <img
              id="buy-sell-bitcoins"
              src="@/assets/main-page/buy-sell-bitcoins.png"
              alt="buy and sell bitcoins"
            />
          </span>
          <div class="feature-box-content">
            <h3 class="Count" data-stop="21572109">21 572 109</h3>
            <p>{{ $t("live_counts.text3") }}</p>
          </div>
        </div>
        <!-- Feature Box Ends -->
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "LiveCounts"
};
</script>

<script type="text/javascript">
jQuery(document).ready(function() {
  $(".Count").each(function() {
    var $this = $(this);
    jQuery({ Counter: 0 }).animate(
      { Counter: $this.attr("data-stop") },
      {
        duration: 2700,
        easing: "swing",
        step: function(now) {
          $this.text(Math.ceil(now));
        }
      }
    );
  });
});
</script>

