<template>
<div class="admin-sidebar-wrap" >
      <div class="overflowY-scroll">
        <!-- User Profile -->
        <div class="user-profile">
          <!-- User Name -->
          <div class="user-name mb-5">
            <div class="d-flex align-items-center"><img @click="toHome()" src="@/assets/img/core-img/dashboard-logo.png" alt="">
              <div class="ms-3" @click="toTerminal()">
                <h6 class="lh-1 text-dark fz-18">
                  {{ user.first_name + " " + user.last_name }}
                  <span class="badge bg-primary fz-12 mt-2">To Terminal</span>
                </h6>
              </div>
            </div>
          </div>
          <!-- Balance -->
          <div class="card shadow">
            <button 
              class="btn btn-sm btn-primary rounded-pill d-sm-block"
              @click="pushDepositPage()"
            >{{ $t("dashboard.add_balance") }}</button>
            <div class="card-body text-center p-4">
              <h6 class="mb-1">Balance: </h6>
              <h5 class="mb-0 text-dark d-flex align-items-center justify-content-center">
                <span class="counter btn btn-warning rounded-pill btn-sm w-100 ">{{ userBalance ? userBalance.balance : 0 }}<span class="ms-2">$</span></span>
              </h5>
              <h6 class="mb-1 mt-2">Available: </h6>
              <h5 class="mb-0 text-dark d-flex align-items-center justify-content-center">
                <span class="counter btn btn-warning rounded-pill btn-sm w-100 ">{{ userBalance ? userBalance.available : 0 }}<span class="ms-2">$</span></span>
              </h5>
              <h6 class="mb-1 mt-2">Profit/Loss: </h6>
              <h5 class="mb-0 text-dark d-flex align-items-center justify-content-center">
                <span class="counter btn btn-warning rounded-pill btn-sm w-100 ">{{ userBalance ? userBalance.profit : 0 }}<span class="ms-2">$</span></span>
              </h5>
            </div>
          </div>
        </div>
        <!-- Sidenav -->
        <div class="sidenav">
          <ul>            
            <li @click="setActiveMenu('personal-info'); changeRoute();">
              <img class="side-menu-logo" src="~@/assets/img/trade-images/personal-account/green-monitor.png">
              {{ $t("dashboard.personal_info") }}
            </li>
            <li @click="setActiveMenu('withdraw'); changeRoute();">
              <img class="side-menu-logo" src="~@/assets/img/trade-images/personal-account/time-money.png">
              Add Balance/Withdrawal
            </li>
            <li @click="setActiveMenu('history'); changeRoute();">
              <img class="side-menu-logo" src="~@/assets/img/trade-images/personal-account/time.png">
              {{ $t("dashboard.history") }}
            </li>
            <li @click="setActiveMenu('statistic'); changeRoute();">
              <img class="side-menu-logo" src="~@/assets/img/trade-images/personal-account/green-monitor.png">
              {{ $t("dashboard.statistic") }}
            </li>
            <li @click="setActiveMenu('notification'); changeRoute();">
              <img class="side-menu-logo" src="~@/assets/img/trade-images/personal-account/letter.png">
              {{ $t("dashboard.notification") }}
            </li>
            <li @click="setActiveMenu('logout'); logout();">
              <img class="side-menu-logo" src="~@/assets/img/trade-images/personal-account/door.png">
              {{ $t("dashboard.log_out") }}
            </li>
          </ul>
        </div>
      </div>
    </div>
</template>

<script>
import { mapState } from "vuex";
import router from "@/router";

export default {
  name: "AccountMenu",
  data() {
    return {
      activeMenu: "forex",
      checked: true,
      isExpandedBalance: false,
      balance: null,
      available: null,
      profit: null
    };
  },
  computed: mapState(["user", "userBalance"]),
  mounted() {
    this.fetchBalance();
  },
  methods: {
    pushDepositPage() {
      router.push("/account-profile/withdraw");
    },
    toTerminal: function() {
      document.querySelector('body').classList.remove("dashboard-header-active");
      router.push("/terminal");
    },
    fetchBalance: function() {
      this.$store.dispatch("userBalance");
    },
    expandBalance: function() {
      this.isExpandedBalance = !this.isExpandedBalance;
    },
    toogleTheme(e){
        let currentTheme = localStorage.getItem('theme');
        if (currentTheme) {
            document.documentElement.setAttribute('data-theme', currentTheme);
            if (currentTheme === 'light') {
                if (this.checked) {
                    this.checked = true;
                }
            }
        }
        if (e.target.checked) {
                document.documentElement.setAttribute('data-theme', 'light');
                localStorage.setItem('theme', 'light');
        } else {
                document.documentElement.setAttribute('data-theme', 'dark');
                localStorage.setItem('theme', 'dark');
        }
    },
    logout() {
      this.$store.dispatch("logout").then(() => {
        window.location = "https://centercroos.nl";
      });
    },
    openUserProfile: function() {
      if (this.$route.name !== "PersonalInfo") {
        router.push("/account-profile");
      }
    },
    setActiveMenu: function(type) {
      this.activeMenu = type;
      document.querySelector('body').classList.remove("dashboard-header-active");
    },
    changeRoute: function() {
      if (this.activeMenu === "personal-info") {
        router.push("/account-profile");
      } else {
        router.push("/account-profile/" + this.activeMenu);
      }
    }
  }
};
</script>

<style scoped>
.overflowY-scroll {
  padding-top: 10px;
}

.side-menu-logo {
  max-width: 15%;
}

.sidenav li {
  padding: 5px;
  cursor: pointer !important;
}

span.badge {
  background-color: #ffc007 !important;
}

.sidenav li:hover,
span.badge:hover 
{
  background-color: #0c6dfd !important;
  cursor: pointer !important;
}

.admin-sidebar-wrap {
  top: 12px;
  left: 12px;
  border-radius: 1rem;
}

.admin-sidebar-wrap .overflowY-scroll {
  height: calc(100vh - 12px);
}
</style>
