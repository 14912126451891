<template>
  <div class="row">
    <div class="col-lg-12">
      <div class="faq-tabs">
        <tabs class="row">
          <tab
            :name="
              this.$i18n.messages[this.$i18n.locale].compliance.compliance.title
            "
            :selected="selectedTab === 'Compliance'"
          >
            <div class="col-lg-12">
              <div class="row">
                <div
                  v-for="item in this.$i18n.messages[this.$i18n.locale]
                    .compliance.compliance.items"
                  v-bind:key="item.title"
                >
                  <h3>{{ item.title }}</h3>
                  <p v-html="item.text"></p>
                </div>
                <!-- <p>
                  At CME, we ensure we are fully transparent about our business
                  model. We disclose all related information and inform users
                  about charges that apply when trading with our platform.
                </p>
                <h3>Leveraged Trading</h3>
                <p>
                  CME-invest.nl trading platform allows users to trade on
                  leveraged funds. This means that you can trade greater amounts
                  than you deposited, i.e. invest on margin.
                </p>
                <h3>Example</h3>
                <p>
                  Let’s say you’ve deposited $1,000 on your CME-invest.nl
                  account. The leverage is 1:50. That means you can trade
                  investments valued up to $50,000. The minimum account value
                  you need to maintain in your account at all times (e.g. the
                  margin requirement) is nearly 20% of yours investment.
                </p>

                <p>
                  Be aware that trading on margin magnifies both potential
                  returns and potential losses. CME-invest.nl provides its users
                  with an effective way to manage risk and avoid a negative
                  account balance.
                </p>

                <h3>Negative Balance Protection</h3>
                <p>
                  The Negative Balance Protection feature in the CME-invest.nl
                  trading terminal ensures users cannot lose more than they’ve
                  deposited in their accounts.
                </p>
                <h3>Spread</h3>
                <p>
                  The Negative Balance Protection feature in the CME-invest.nl
                  trading terminal ensures users cannot lose more than they’ve
                  deposited in their accounts.
                </p>
                <h3>Example</h3>
                <p>
                  Apple CFDs are quoted at $141.50/$141.70, then the spread
                  equals to 20 cents. If this spread remains at 20 cents, when
                  you close your trade you will effectively pay 20 cents for
                  every share traded as a spread.
                </p>
                <p>
                  Visit our
                  <router-link to="/about/careers">courses</router-link> to
                  learn more about
                  <router-link to="/about/why-us">Spread</router-link>.
                </p> -->
              </div>
            </div>
          </tab>
          <tab
            :name="
              this.$i18n.messages[this.$i18n.locale].compliance.regulations
                .title
            "
            :selected="selectedTab === 'Regulations'"
          >
            <div class="col-lg-12">
              <div class="row">
                <h2>
                  {{
                    this.$i18n.messages[this.$i18n.locale].compliance
                      .regulations.subtitle
                  }}
                </h2>
                <div
                  v-for="item in this.$i18n.messages[this.$i18n.locale]
                    .compliance.regulations.items"
                  v-bind:key="item.title"
                >
                  <h3>{{ item.title }}</h3>
                  <p v-html="item.text"></p>
                </div>
                <!-- <h3>
                  Licenses
                </h3>
                <p>
                  CME Investment Firm B.V. is a regulated Netherlands Investment
                  Firm, authorised and regulated by he Dutch Authority for the
                  Financial Markets (AFM) and the Belgian Financial Services and
                  Markets Authority (FSMA)
                </p>
                <p>
                  Based in the European Union, CME-invest.nl complies with the
                  requirements imposed by the Markets in Financial Instruments
                  Directive (MiFID II).
                </p>
                <h3>
                  Segregated Funds
                </h3>
                <p>
                  CME-invest.nl keeps its clients’ money in segregated bank
                  accounts in accordance with our regulator’s rules on client
                  money. In other words, your funds are held separately from our
                  funds and are thus not exposed to any unexpected financial
                  difficulties that may arise in the Company. The Company does
                  not claim any entitlement to these funds, as they belong to
                  you.
                </p>
                <p>
                  Clients’ funds are spread across a number of prominent banks
                  (Bank of Netherlands, OTP Bank, ING Bank, Raffeissen,
                  Eurobank) that are constantly reviewed to ensure they are in
                  line with the CME Investment Firm B.V. guidelines.
                </p>
                <p>
                  Our clients’ funds, therefore, cannot be affected by sovereign
                  and corporate debts.
                </p>
                <h3>
                  Investor Compensation warranties
                </h3>
                <p>
                  CME-invest.nl segregates all retail client funds from its own
                  money in accordance with relevant regulations. CME Investment
                  Firm B.V. is a member of the MiFID II (Markets in Financial
                  Instruments Directive), which provide compensation for Retail
                  Investors should CME-invest.nl declare default. MiFID II
                  guarantees insurance claims up to € 100,000.
                </p> -->
              </div>
            </div>
          </tab>
          <tab
            :name="
              this.$i18n.messages[this.$i18n.locale].compliance.terms.title
            "
          >
            <div class="col-lg-12">
              <div class="row">
                <h2>
                  {{
                    this.$i18n.messages[this.$i18n.locale].compliance.terms
                      .subtitle
                  }}
                </h2>
                <div
                  v-for="item in this.$i18n.messages[this.$i18n.locale]
                    .compliance.terms.items"
                  v-bind:key="item.title"
                >
                  <h3>{{ item.title }}</h3>
                  <p v-html="item.text"></p>
                </div>
                <!-- <h3>What is a market order?</h3>
                <p>
                  A market order is an instruction to buy or sell a CFD, in a
                  specified size, at the best available market price for that
                  size. It is important to note that a market order can be
                  executed at a price different from quoted at the time it is
                  placed. Once executed, a market order immediately becomes an
                  open trade that can be watched in the ‘Portfolio’ tab. Market
                  orders can only be placed during the trading hours of the
                  underlying asset. A market order can have Take Profit/Stop
                  Loss Orders attached.
                </p>
                <h3>How long can I keep my trade open?</h3>
                <p>
                  If you have enough money on your account, you can keep your
                  trades open for as long as you want. Please note that if your
                  trade remains open at the end of the day, an overnight fee is
                  charged.
                </p>
                <h3>What should I know about margin trading?</h3>
                <p>
                  Trading on margin means to trade securities using funds
                  borrowed from a broker. To trade on margin, you have to open a
                  margin account and deposit a certain amount of money, which
                  will literally serve as a collateral for a loan. Margin
                  trading offers you an exposure to bigger trades and profits,
                  but involves greater risks as well.
                </p>
                <h3>What is margin call?</h3>
                <p>
                  A margin call is sent when the ratio between your equity and
                  your required margin fails to meet our requirements. A margin
                  call is a key risk management tool preventing your losses from
                  piling up. If your equity drops below 125% of the required
                  margin, you’ll receive our first margin call message. You will
                  still be able to open new trades and place orders. The next
                  margin call is sent if your equity goes below 100% of the
                  required margin. You will no longer be able to open new trades
                  or place orders. If your equity to margin ratio drops below
                  75%, you’ll receive the third margin call. You will still not
                  be able to open new trades or place orders. If the equity is
                  equal to or less than 50% of the required margin, it means you
                  have reached the minimum-allowed margin level and your trades
                  will be gradually closed out.
                </p>
                <h3>How does gradual margin closeout work?</h3>
                <p>
                  Once your account drops below 50% of the required margin, the
                  trades will be closed out in the following order: At first,
                  Good-Till-Cancel (GTC) orders are closed; If the margin level
                  remains below 50%, all losing open positions on the open
                  markets are closed; If the margin level is still below 50%,
                  the remaining positions on the open markets are closed; If the
                  margin level stays below 50%, all the remaining positions are
                  closed as soon as the markets are open.
                </p> -->
              </div>
            </div>
          </tab>
        </tabs>
      </div>
      <why-us-promo></why-us-promo>
      <advantages></advantages>
    </div>
    <footer-promo></footer-promo>
  </div>
</template>

<script>
import FooterPromo from "../../components/layout/footer/FooterPromo";
import Advantages from "../../components/Advantages";
import WhyUsPromo from "../../components/layout/WhyUsPromo";
import Tabs from "@/components/layout/Tabs.vue";
import Tab from "@/components/layout/Tab.vue";

export default {
  name: "Compliance",
  components: {
    WhyUsPromo,
    Advantages,
    FooterPromo,
    Tabs,
    Tab
  },
  data() {
    return {
      selectedTab: "Compliance"
    };
  },
  created() {
    if (this.$route.query.tab === "regulations") {
      this.selectedTab = "Regulations";
    }
  }
};
</script>