<template>
  <div class="row">
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <div class="row center-lg center-md center-sm center-xs markets-hdr">
        <h1>{{ $t("menu.markets") }}</h1>
      </div>
    </div>
    <markets-types></markets-types>
    <actives class="col-lg-12 col-md-12 col-sm-12 col-xs-12"></actives>
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 info-block center-lg">
      {{ $t("main.markets_all_text") }}
    </div>
    <advantages></advantages>
    <footer-promo></footer-promo>
  </div>
</template>

<script>
import Advantages from "@/components/layout/Advantages.vue";
import Actives from "@/components/layout/Actives.vue";
import MarketsTypes from "@/components/layout/MarketsTypes.vue";
import FooterPromo from "@/components/layout/footer/FooterPromo.vue";

export default {
  name: "Markets",
  components: {
    Advantages,
    Actives,
    FooterPromo,
    MarketsTypes
  }
};
</script>
