<template>
  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
    <div
      class="row center-lg around-lg center-md around-md center-sm around-sm center-xs around-xs market-types"
    >
      <div class="col">
        <div class="row">
          <router-link to="/markets/markets-shares">
            <img src="@/assets/market-types/grapsh.jpg" />
          </router-link>
        </div>
        <div class="row">
          <div class="col-lg-12 numb-text">Shares</div>
        </div>
      </div>
      <div class="col">
        <div class="row">
          <router-link to="/markets/markets-indices">
            <img src="@/assets/market-types/wall-street.jpg" />
          </router-link>
        </div>
        <div class="row">
          <div class="col-lg-12 numb-text">Indices</div>
        </div>
      </div>
      <div class="col">
        <div class="row">
          <router-link to="/markets/markets-crypto-currencies">
            <img src="@/assets/market-types/cryptocurrency-predictions.jpg" />
          </router-link>
        </div>
        <div class="row">
          <div class="col-lg-12 numb-text">Cryptocurrencies</div>
        </div>
      </div>
      <div class="col">
        <div class="row">
          <router-link to="/markets/markets-commodities">
            <img src="@/assets/market-types/commodities.jpg" />
          </router-link>
        </div>
        <div class="row">
          <div class="col-lg-12 numb-text">Commodities</div>
        </div>
      </div>
      <div class="col">
        <div class="row">
          <router-link to="/markets/markets-forex">
            <img src="@/assets/market-types/forex-money-markets.jpg" />
          </router-link>
        </div>
        <div class="row">
          <div class="col-lg-12 numb-text">Forex</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MarketsTypes.vue"
};
</script>


