<template>
  <div class="modal__buy_sell" @click="closeModal" v-if="showModal">
    <div class="container-fluid mt-5">
      <div class="centerized">
        <div class="card border-0 shadow-sm">
          <div class="card-body">
            <div id="buy-sell-deal">
              <div
                id="timer_holder"
                class=""
                v-if="isCreditDateLoaded && isLimitedAccess"
              >
                <div id="timer" :data-date="getCurrentTimer">
                  <div><span id="hours"></span>hours</div>
                  <div><span id="minutes"></span>minutes</div>
                  <div><span id="seconds"></span>seconds</div>
                </div>
              </div>
              <div v-if="isCreditDateLoaded && !isLimitedAccess">
                
                  <div class="col-xs-12 center-lg center-md center-sm center-xs text-right close-modal"><a href="#" @click="closeModalByX()">x</a></div>
                  <div class="col-xs-12 center-lg center-md center-sm center-xs">
                    <label for="lot">
                      {{
                        Object.keys(dealSymbol).length === 0
                          ? $t("dashboard.choose_your_instrument")
                          : "Lot for " + dealSymbol.front_view
                      }}
                    </label>
                  </div>
                  <div
                    class="col-xs-12 center-lg center-md center-sm center-xs mt-3"
                    v-if="Object.keys(dealSymbol).length !== 0"
                  >
                    <div class="col-xs-12">
                      Market Price:
                      {{
                        dealDirect === "buy"
                          ? (dealSymbol.ask * lot).toFixed(dealSymbol.digits)
                          : (dealSymbol.bid * lot).toFixed(dealSymbol.digits)
                      }}
                    </div>
                    <div class="col-xs-12">
                      With leverage:
                      {{
                        dealDirect === "buy"
                          ? ((dealSymbol.ask * lot) / user.leverage).toFixed(
                              dealSymbol.digits
                            )
                          : ((dealSymbol.bid * lot) / user.leverage).toFixed(
                              dealSymbol.digits
                            )
                      }}
                    </div>
                  </div>
                
                
                  <div class="col-xs-12 mt-3">
                    <input
                      id="lot"
                      type="number"
                      min="0"
                      v-model="lot"
                      class="form-control bg-gray border-0"
                    />
                  </div>
                
                
                  <div class="col-xs-12 mt-3">
                    <div class="row">
                      <div class="col-xs-6">
                        <label for="stop-loss">{{ $t("dashboard.stop_loss") }}</label>
                      </div>
                      <div class="col-xs-6 text-right">
                        <toggle-button
                          id="stop-loss"
                          v-model="isStopLoss"
                          color="#0d6efd"
                          :switch-color="{checked: '#ffc107', unchecked: '#0d6efd'}"
                          :sync="true"
                        />
                      </div>
                    </div>
                  </div>
                
                  <div class="col-xs-12 mt-3" v-show="isStopLoss">
                    <input type="number" min="0" v-model="stopLoss" class="form-control bg-gray border-0" />
                  </div>
                
                  <div class="col-xs-12 mt-3">
                    <div class="row">
                      <div class="col-xs-6">
                        <label for="take-profit">{{ $t("dashboard.take_profit") }}</label>
                      </div>
                      <div class="col-xs-6 text-right">
                        <toggle-button
                          id="take-profit"
                          v-model="isTakeProfit"
                          color="#0d6efd"
                          :switch-color="{checked: '#ffc107', unchecked: '#0d6efd'}"
                          :sync="true"
                        />
                      </div>
                    </div>
                    
                    
                  </div>
                
                  <div class="col-xs-12 mt-3" v-show="isTakeProfit">
                    <input
                      type="number"
                      min="0"
                      v-model="takeProfit"
                      class="form-control bg-gray border-0"
                    />
                  </div>
                
                  <div class="col-xs-12 mt-3">
                    <button
                      class="btn"
                      :disabled="checkIsLimited"
                      :class="{
                        'btn-primary': dealDirect === 'buy' || dealDirect.length == 0,
                        'btn-danger': dealDirect === 'sell'
                      }"
                      @click="openTrade()"
                    >
                      {{
                        dealDirect === "sell" ? $t("dashboard.sell") : $t("dashboard.buy")
                      }}
                    </button>
                  </div>
                
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ToggleButton } from "vue-js-toggle-button";
import { mapState } from "vuex";

export default {
  name: "BuySellDeal",
  props: ['showModal'],
  components: {
    ToggleButton,
  },
  computed: {
    ...mapState([
      "dealSymbol",
      "dealDirect",
      "user",
      "profitLossReachedBalance",
      "checkIsLimited",
      "isLimitedAccess",
      "lastCreditDate",
      "isCreditDateLoaded"
    ]),
    getCurrentTimer() {
      let timer;
      if (
        this.lastCreditDate &&
        this.lastCreditDate != null &&
        this.lastCreditDate != false
      ) {
        let compareDate = new Date(this.lastCreditDate);
        compareDate.setDate(compareDate.getDate() + 3);
        timer = setInterval(function() {
          timeBetweenDates(compareDate);
        }, 1000);
      }

      function timeBetweenDates(toDate) {
        let dateEntered = toDate;
        let now = new Date();
        let difference = dateEntered.getTime() - now.getTime();

        if (difference <= 0) {
          clearInterval(timer);
        } else {
          let seconds = Math.floor(difference / 1000);
          let minutes = Math.floor(seconds / 60);
          let hours = Math.floor(minutes / 60);

          minutes %= 60;
          seconds %= 60;
          if (seconds.toString().length == 1) {
            seconds = "0" + seconds;
          }
          $("#timer_holder").css("display", "block");
          $("#hours").text(hours);
          $("#minutes").text(minutes);
          $("#seconds").text(seconds);
        }
      }
    }
  },
  data() {
    return {
      isStopLoss: false,
      isTakeProfit: false,
      stopLoss: 0,
      takeProfit: 0,
      lot: 0
    };
  },
  methods: {
    openTrade: function(event) {
      /* Validation */
      if (!this.lot || this.lot < 1) {
        return;
      }

      this.$store.dispatch("openTrade", {
        direct: this.dealDirect,
        lot: this.lot,
        sl: this.stopLoss,
        symbol: this.dealSymbol.symbol,
        tp: this.takeProfit
      });
      this.lot = 0;
      this.isStopLoss = false;
      this.isTakeProfit = false;
      this.stopLoss = 0;
      this.takeProfit = 0;

      this.$emit('closeModal')
    },
    closeModalByX() {
      this.$emit('closeModal');
    },
    closeModal(event){
      if(event.target.className == "modal__buy_sell" ){
        this.$emit('closeModal')
      }
    }
  }
};
</script>
<style scoped>
.close-modal {
  color: white;
  font-size: 24px;
}

.close-modal > a {
  color: white
}

.modal__buy_sell{
  position: absolute;
  z-index: 100;
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0, 0.7);

}

.centerized {
    position: absolute;
    top: 50%;
    left: 50%;
    margin: auto;
    transform: translate(-70%, -50%);  
    width: 30%;
}

@media only screen and (max-width: 992px) {
  .centerized {
    transform: translate(-50%, -50%);  
    width: 50%;
  }
}

@media only screen and (max-width: 662px) {
  .centerized {
    transform: translate(-50%, -50%);  
    width: 80%;
  }
}

.text-right {
  text-align: right;
}
</style>
