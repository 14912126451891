<template>
  <div class="is-safe">
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 head">
        <span class="title">{{
          this.$i18n.messages[this.$i18n.locale].why_us.title
        }}</span>
        <div
          v-html="this.$i18n.messages[this.$i18n.locale].why_us.subtitle"
        ></div>
        <div class="promo-action col-lg-12">
          <button class="promo-button" @click="redirectToRegistration()">
            {{ this.$i18n.messages[this.$i18n.locale].main.banner_button_text }}
          </button>
        </div>
      </div>
    </div>

    <div class="row section">
      <div class="col-lg-6 col-md-6 col-sm-12">
        <h3>
          {{ this.$i18n.messages[this.$i18n.locale].why_us.block1_title }}
        </h3>
        <p>
          {{ this.$i18n.messages[this.$i18n.locale].why_us.block1_text }}
          <router-link
            onclick="window.scrollTo(0,0);"
            to="/registration"
            class="border-button"
          >
            <img src="~@/assets/about-us/icon-1.png" alt="image" />
            <span
              v-html="
                this.$i18n.messages[this.$i18n.locale].why_us.block1_button
              "
            ></span>
          </router-link>
        </p>
      </div>

      <div class="col-lg-6 col-md-6 col-sm-12">
        <img src="~@/assets/about-us/i_first.png" alt="image" />
      </div>
    </div>

    <div class="row section bg-gray">
      <div class="col-lg-6 col-md-6 col-sm-12">
        <img src="~@/assets/about-us/i_second.png" alt="image" />
      </div>
      <div class="col-lg-6 col-md-6 col-sm-12">
        <h3>
          {{ this.$i18n.messages[this.$i18n.locale].why_us.block2_title }}
        </h3>
        <p>
          {{ this.$i18n.messages[this.$i18n.locale].why_us.block2_text }}

          <router-link
            onclick="window.scrollTo(0,0);"
            to="/markets"
            class="border-button"
          >
            <img src="~@/assets/about-us/stocks.png" alt="image" />
            <span
              v-html="
                this.$i18n.messages[this.$i18n.locale].why_us.block2_button
              "
            ></span>
          </router-link>
        </p>
      </div>
    </div>

    <div class="row section">
      <div class="col-lg-6 col-md-6 col-sm-12">
        <h3>
          {{ this.$i18n.messages[this.$i18n.locale].why_us.block3_title }}
        </h3>
        <p>
          {{ this.$i18n.messages[this.$i18n.locale].why_us.block3_text }}

          <router-link
            onclick="window.scrollTo(0,0);"
            to="/registration"
            class="border-button"
          >
            <img src="~@/assets/about-us/settings.png" alt="image" />
            <span
              v-html="
                this.$i18n.messages[this.$i18n.locale].why_us.block3_button
              "
            ></span>
          </router-link>
        </p>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-12">
        <img src="~@/assets/about-us/i_third.png" alt="image" />
      </div>
    </div>

    <div class="row section bg-gray">
      <div class="col-lg-6 col-md-6 col-sm-12">
        <img src="~@/assets/about-us/4_Block.png" alt="image" />
      </div>
      <div class="col-lg-6 col-md-6 col-sm-12">
        <h3>
          {{ this.$i18n.messages[this.$i18n.locale].why_us.block4_title }}
        </h3>
        <p>
          {{ this.$i18n.messages[this.$i18n.locale].why_us.block4_text }}

          <router-link
            onclick="window.scrollTo(0,0);"
            to="/faq?tab=loss"
            class="border-button"
          >
            <img src="~@/assets/about-us/shield.png" alt="image" />
            <span
              v-html="
                this.$i18n.messages[this.$i18n.locale].why_us.block4_button
              "
            ></span>
          </router-link>
        </p>
      </div>
    </div>

    <div class="row section">
      <div class="col-lg-6 col-md-6 col-sm-12">
        <h3>
          {{ this.$i18n.messages[this.$i18n.locale].why_us.block5_title }}.
        </h3>
        <p>
          {{ this.$i18n.messages[this.$i18n.locale].why_us.block5_text }}
          <router-link
            onclick="window.scrollTo(0,0);"
            to="/learn-to-trade"
            class="border-button"
          >
            <img src="~@/assets/about-us/bank.png" alt="image" />
            <span
              v-html="
                this.$i18n.messages[this.$i18n.locale].why_us.block5_button
              "
            ></span>
          </router-link>
        </p>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-12">
        <img src="~@/assets/about-us/i_fourth.png" alt="image" />
      </div>
    </div>

    <div class="row section bg-gray">
      <div class="col-lg-6 col-md-6 col-sm-12 afmf-image-block">
        <img src="~@/assets/why-us/afmf.jpg" alt="image" class="afmf-image" />
      </div>
      <div class="col-lg-6 col-md-6 col-sm-12">
        <h3>
          {{ this.$i18n.messages[this.$i18n.locale].why_us.block6_title }}.
        </h3>
        <p>
          {{ this.$i18n.messages[this.$i18n.locale].why_us.block6_text }}
          <router-link
            onclick="window.scrollTo(0,0);"
            to="/about/compliance?tab=regulations"
            class="border-button"
          >
            <img src="~@/assets/about-us/info.png" alt="image" />
            <span
              v-html="
                this.$i18n.messages[this.$i18n.locale].why_us.block6_button
              "
            ></span>
          </router-link>
        </p>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12">
        <why-us-promo></why-us-promo>
        <advantages></advantages>
      </div>
      <footer-promo></footer-promo>
    </div>
  </div>
</template>

<script>
import FooterPromo from "../../components/layout/footer/FooterPromo";
import Advantages from "../../components/Advantages";
import WhyUsPromo from "../../components/layout/WhyUsPromo";
import router from "@/router";

export default {
  name: "AboutWhyUs",
  components: {
    WhyUsPromo,
    Advantages,
    FooterPromo
  },
  methods: {
    redirectToRegistration: function() {
      router.push("/registration");
    }
  }
};
</script>
