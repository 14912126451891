<template>
    <div class="container ">
      <div class="row g-4">
        <div class="col-12 col-xxl-12">
          <div class="card border-0 shadow-sm">
            <div class="card-body p-4">
              <div id="history" class="w-100 mb-3">
                <div class="tab-content">
                  <div class="tab-pane fade show active" id="tab1" role="tabpanel" aria-labelledby="tab--1">
                    <div class="table-responsive border shadow-sm dashboard-table activity-table">
                      <table class="table mb-0">
                        <tbody>
                          <tr>
                            <th><span class="d-inline-block fw-bold fz-14"><span>{{ $t("dashboard.date") }}</span></span></th>
                            <td><span class="d-inline-block fw-bold fz-14"><span>{{ $t("dashboard.currency") }}</span></span></td>
                            <td><span class="d-inline-block fw-bold fz-14"><span>{{ $t("dashboard.type_of_transaction") }}</span></span></td>
                            <td><span class="d-inline-block fw-bold fz-14"><span>{{ $t("dashboard.total") }}</span></span></td>
                            <td><span class="d-inline-block fw-bold fz-14"><span>{{ $t("dashboard.status") }}</span></span></td>
                          </tr>
                          <tr v-for="history in transactionHistory" v-bind:key="history.id">
                            <th>
                              <span class="d-inline-block fw-bold fz-14">
                                <span>{{ history.created_at }}</span>
                              </span>
                            </th>
                            <td>
                              <span class="d-inline-block fw-bold fz-14">
                                {{ history.currency }}
                              </span>
                            </td>
                            <td>
                              <span class="d-inline-block fw-bold fz-14">
                                <span v-if="history.type == 'withdraw'">{{
                                  $t("dashboard.withdraw")
                                }}</span>
                                <span v-if="history.type == 'pay_for_brocker_credit'">{{
                                  $t("dashboard.pay_for_brocker_credit")
                                }}</span>
                                <span v-if="history.type == 'brocker_credit'">{{
                                  $t("dashboard.brocker_credit")
                                }}</span>
                                <span v-if="history.type == 'admin_pay'">{{
                                  $t("dashboard.deposit")
                                }}</span>
                              </span>
                            </td>
                            <td>
                              <span class="d-inline-block fw-bold fz-14">
                                <span>{{ history.amount }}</span>
                              </span>
                            </td>
                            <td>
                              <span class="d-inline-block fw-bold fz-14">
                                <span v-if="history.status == 'paid'">{{
                                  $t("dashboard.paid")
                                }}</span>
                                <span v-else>{{
                                  history.status 
                                }}</span>
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "History",
  computed: mapState(["transactionHistory"]),
  mounted() {
    this.$store.dispatch("fetchTransactionHistory");
  }
};
</script>
