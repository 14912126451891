<template>
  <div class="actives">
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <tabs class="test actives-ui">
        <tab v-bind:name="$t('main.actives.forex')" :selected="true">
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div class="row carousel">
              <vueper-slides
                :arrows="true"
                :bullets="false"
                class="no-shadow"
                :visible-slides="4"
                :slide-ratio="1 / 4"
                :dragging-distance="10"
                autoplay
              >
                <vueper-slide
                  v-for="(slide, i) in renderSymbols(symbols, 'forex')"
                  :key="i"
                  :title="slide.front_view"
                  :content="slide.price"
                ></vueper-slide>
              </vueper-slides>
            </div>
          </div>
        </tab>
        <tab v-bind:name="$t('main.actives.indices')">
          <div class="col-lg-12">
            <div class="row carousel">
              <vueper-slides
                :arrows="true"
                :bullets="false"
                class="no-shadow"
                :visible-slides="4"
                :slide-ratio="1 / 4"
                :dragging-distance="10"
                autoplay
              >
                <vueper-slide
                  v-for="(slide, i) in renderSymbols(symbols, 'indices')"
                  :key="i"
                  :title="slide.front_view"
                  :content="slide.price"
                ></vueper-slide>
              </vueper-slides>
            </div>
          </div>
        </tab>
        <tab v-bind:name="$t('main.actives.crypto')">
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div class="row carousel">
              <vueper-slides
                :arrows="true"
                :bullets="false"
                class="no-shadow"
                :visible-slides="4"
                :slide-ratio="1 / 4"
                :dragging-distance="30"
                autoplay
              >
                <vueper-slide
                  v-for="(slide, i) in renderSymbols(symbols, 'crypto')"
                  :key="i"
                  :title="slide.front_view"
                  :content="slide.price"
                ></vueper-slide>
              </vueper-slides>
            </div>
          </div>
        </tab>
        <tab v-bind:name="$t('main.actives.stock')">
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div class="row carousel">
              <vueper-slides
                :arrows="true"
                :bullets="false"
                class="no-shadow"
                :visible-slides="4"
                :slide-ratio="1 / 4"
                :dragging-distance="30"
                autoplay
              >
                <vueper-slide
                  v-for="(slide, i) in renderSymbols(symbols, 'stock')"
                  :key="i"
                  :title="slide.front_view"
                  :content="slide.price"
                ></vueper-slide>
              </vueper-slides>
            </div>
          </div>
        </tab>
        <tab v-bind:name="$t('main.actives.commodities')">
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div class="row carousel">
              <vueper-slides
                :arrows="true"
                :bullets="false"
                class="no-shadow"
                :visible-slides="4"
                :slide-ratio="1 / 4"
                :dragging-distance="30"
                autoplay
              >
                <vueper-slide
                  v-for="(slide, i) in renderSymbols(symbols, 'metals')"
                  :key="i"
                  :title="slide.front_view"
                  :content="slide.price"
                ></vueper-slide>
              </vueper-slides>
            </div>
          </div>
        </tab>
      </tabs>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { VueperSlide, VueperSlides } from "vueperslides";
import "vueperslides/dist/vueperslides.css";
import Tabs from "@/components/layout/Tabs.vue";
import Tab from "@/components/layout/Tab.vue";

export default {
  name: "Actives",
  components: {
    VueperSlides,
    VueperSlide,
    Tabs,
    Tab
  },
  computed: mapState(["symbols"]),
  created() {
    this.fetchSymbols("all");
  },
  methods: {
    fetchSymbols: function(type) {
      const isStock = type === "stock" ? 1 : 0;
      if (isStock) {
        this.$store.dispatch("fetchSymbols", {
          symbolsType: type,
          countryId: 1,
          offset: 1,
          query: ""
        });
      } else {
        this.$store
          .dispatch("fetchSymbols", {
            symbolsType: type,
            countryId: 0,
            offset: 1,
            query: ""
          })
          .then(() => {
            this.all = this.symbols;
          })
          .bind(this);

        this.$store
          .dispatch("fetchSymbols", {
            symbolsType: "stock",
            countryId: 1,
            offset: 1,
            query: ""
          })
          .then(() => {
            this.stock = this.symbols;
          })
          .bind(this);
      }
    },

    renderSymbols: function(symbols, type) {
      const isStock = type === "stock" ? 1 : 0;
      const filter = symbol =>
        symbol.symbol_type.name.toLowerCase().includes(type) &&
        symbol.price > 0;

      if (isStock) {
        return this.stock.filter(filter);
      } else {
        return this.all.filter(filter);
      }
    }
  },
  data() {
    return {
      activeMenu: "forex",
      isFirstTimeLoading: true,
      stock: [],
      all: []
    };
  }
};
</script>

