<template>
  <div class="admin-wrapper">
    <BuySellDeal :showModal="showModalAtTerminal" @closeModal="closeModal"/>
    <div class="container-fluid">
      <div class="row">
          <div class="card border-0 shadow-sm">
            <div class="card-body">
              <trade-graph></trade-graph>
            </div>
          </div>
      </div>
      <div class="row row-mt-12" v-if="profitLossReachedBalance && !isLimitedAccess">
        <div class="card border-0 shadow-sm">
            <div class="card-body">
      <div class="profitReachedBalanceWarning-modal__text">
        <h2>{{$t('dashboard.attention')}}!!!</h2>
        <p>
          {{$t('dashboard.attention_text1')}} {{ userCreditLimitPercanteg }}% {{$t('dashboard.attention_text2')}}
        </p>
      </div>
      <div class="profitReachedBalanceWarning-modal__buttons" style="text-align: center;">
        <button
          class="btn btn-sm btn-primary rounded-pill mr-1 mt-1"
          v-on:click="addBalance"
        >
          {{$t('dashboard.attention_text3')}}<br />{{$t('dashboard.attention_text4')}}
        </button>
        <button
          class="btn btn-warning rounded-pill btn-sm mr-1 mt-1"
          v-on:click="takeCredit"
        >
          {{$t('dashboard.attention_text5')}}<br />{{$t('dashboard.attention_text6')}} - ${{
            userAvailableUserCreditAmount
          }}
        </button>
        <button
          class="btn btn-sm rounded-pill btn-danger mt-1"
          v-on:click="updateReachedBalance"
        >
          {{$t('dashboard.attention_text7')}}<br />{{$t('dashboard.attention_text8')}}
        </button>
      </div>
            </div>
        </div>
      </div>
      <div class="row row-mt-12">
          <div class="card border-0 shadow-sm">
            <div class="card-body">
              <trades-history-tabs></trades-history-tabs>
            </div>
          </div>
      </div>
    </div>
  </div>
</template>

<script>

import { SocketInstance } from "../../main";
import { mapState } from "vuex";
import TradeGraph from "../../components/dashboard/body/TradeGraph.vue";
import TradesHistoryTabs from '../../components/dashboard/body/TradesHistoryTabs.vue';
import BuySellDeal from "../../components/dashboard/body/BuySellDeal.vue";

export default {
  name: "DashboardMain",
  components: {
    TradesHistoryTabs,
    TradeGraph,
    BuySellDeal
  },
  data() {
    return {
      processingTransaction: false,
      isShow: false
    };
  },
  computed: {
    ...mapState([
      "user",
      "symbolsFilter",
      "profitLossReachedBalance",
      "userAvailableUserCreditAmount",
      "isLimitedAccess",
      "userCreditLimitPercanteg",
      "showModalAtTerminal"
    ]),
  },
  mounted() {},
  created() {
    SocketInstance.emit("auth", this.user.id);
  },
  methods: {
    closeModal(){
      this.$store.commit("SET_SHOW_MODAL_AT_TERMINAL", false);
    },
    updateReachedBalance() {
      this.$store.state.profitLossReachedBalance = false;
      this.$store.state.activeModalWindow = false;
    },
    addBalance() {
      window.location.href = "/account-profile/withdraw";
    },
    takeCredit() {
      if (this.processingTransaction) {
        return;
      }
      this.processingTransaction = true;
      this.$store.state.isLimitedAccess = true;
      this.$store.dispatch("takeCredit", {
        amount: this.$store.state.userAvailableUserCreditAmount
      });
    }
  }
};
</script>

<style scoped>
.card-body {
  padding-right: 0.2rem !important;
  padding-left: 0.2rem !important;
}

.row-mt-12 {
  margin-top: 12px;
}

.mr-1 {
  margin-right: 20px;
}

.container-fluid > .row {
  margin-right: 0 !important;
  margin-left: 0 !important;
  }

.container-fluid {
  padding-top: 12px;
  padding-bottom: 12px;
}

.mt-1 {
  margin-top: 10px;
}
</style>
