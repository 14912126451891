<template>
  <div class="row earningsCalendar">
    <h2 class="center-lg center-md center-sm center-xs">
      {{ $t("menu.economic_calendar") }}
    </h2>
    <div
      class="col-lg-12 col-md-12 col-sm-12 col-xs-12"
      v-if="economicCalendar"
    >
      <div v-html="economicCalendar">
        Loading, please wait...
      </div>
    </div>
    <advantages></advantages>
    <footer-promo></footer-promo>
  </div>
</template>

<script>
import Advantages from "@/components/layout/Advantages";
import FooterPromo from "@/components/layout/footer/FooterPromo.vue";
import { mapState } from "vuex";

export default {
  name: "EconomicCalendar",
  components: {
    Advantages,
    FooterPromo
  },
  computed: mapState(["economicCalendar"]),
  mounted() {
    this.getEconomicCalendar();
  },
  methods: {
    getEconomicCalendar() {
      this.$store.dispatch("getEconomicCalendar");
    }
  }
};
</script>
