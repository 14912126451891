import { render, staticRenderFns } from "./Statistic.vue?vue&type=template&id=412c5ecd&scoped=true&"
import script from "./Statistic.vue?vue&type=script&lang=js&"
export * from "./Statistic.vue?vue&type=script&lang=js&"
import style0 from "./Statistic.vue?vue&type=style&index=0&id=412c5ecd&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "412c5ecd",
  null
  
)

export default component.exports