<template>
  <div class="is-safe">
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 head">
        <span class="title">{{
          this.$i18n.messages[this.$i18n.locale].is_safe.title
        }}</span>
        <div
          v-html="this.$i18n.messages[this.$i18n.locale].is_safe.subtitle"
        ></div>
        <div class="promo-action col-lg-12">
          <button class="promo-button" @click="redirectToRegistration()">
            {{ this.$i18n.messages[this.$i18n.locale].main.banner_button_text }}
          </button>
        </div>
      </div>
    </div>

    <div class="row section">
      <div class="col-lg-6 col-md-6 col-sm-12 afmf-image-block">
        <img src="~@/assets/why-us/afmf.jpg" alt="image" class="afmf-image" />
      </div>

      <div class="col-lg-6 col-md-6 col-sm-12">
        <h3>
          {{ this.$i18n.messages[this.$i18n.locale].is_safe.block1_title }}
        </h3>
        <p>
          {{ this.$i18n.messages[this.$i18n.locale].is_safe.block1_text }}
        </p>
      </div>
    </div>

    <div class="row section bg-gray">
      <div class="col-lg-6 col-md-6 col-sm-12">
        <h3>
          {{ this.$i18n.messages[this.$i18n.locale].is_safe.block2_title }}
        </h3>
        <p>
          {{ this.$i18n.messages[this.$i18n.locale].is_safe.block2_text }}
        </p>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-12">
        <img src="~@/assets/about-us/is-safe-section-2.png" alt="image" />
      </div>
    </div>

    <div class="row section">
      <div class="col-lg-6 col-md-6 col-sm-12">
        <img src="~@/assets/why-us/afmfs.jpg" alt="image" />
      </div>

      <div class="col-lg-6 col-md-6 col-sm-12">
        <h3>
          {{ this.$i18n.messages[this.$i18n.locale].is_safe.block3_title }}
        </h3>
        <p>
          {{ this.$i18n.messages[this.$i18n.locale].is_safe.block3_text }}
        </p>
      </div>
    </div>

    <div class="row section bg-gray">
      <div class="col-lg-6 col-md-6 col-sm-12">
        <h3>
          {{ this.$i18n.messages[this.$i18n.locale].is_safe.block4_title }}
        </h3>
        <p>
          {{ this.$i18n.messages[this.$i18n.locale].is_safe.block4_text }}
        </p>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-12">
        <img src="~@/assets/about-us/is-safe-section-4.png" alt="image" />
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12">
        <why-us-promo></why-us-promo>
        <advantages></advantages>
      </div>
      <footer-promo></footer-promo>
    </div>
  </div>
</template>

<script>
import FooterPromo from "../../components/layout/footer/FooterPromo";
import Advantages from "../../components/Advantages";
import WhyUsPromo from "../../components/layout/WhyUsPromo";
import router from "@/router";

export default {
  name: "IsSafe",
  components: { WhyUsPromo, Advantages, FooterPromo },
  methods: {
    redirectToRegistration: function() {
      router.push("/registration");
    }
  }
};
</script>
