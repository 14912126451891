<template>
  <div class="promo col-lg-12 col-md-12 col-sm-12 col-xs-12">
    <div class="row wrap middle-lg middle-md middle-sm middle-xs">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <div class="promo-text row center-lg center-md center-sm center-xs">
          <span>{{ $t("main.footer.promo.title") }}</span>
        </div>
        <div class="promo-subtext row center-lg center-md center-sm center-xs">
          <span>{{ $t("main.footer.promo.text") }}</span>
        </div>
        <div class="promo-action row center-lg center-md center-sm center-xs">
          <button
            onclick="window.scrollTo(0,0);"
            class="promo-button"
            @click="redirectToRegistration()"
          >
            {{ $t("main.footer.promo.button_text") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import router from "@/router";
export default {
  name: "FooterPromo",
  methods: {
    redirectToRegistration: function() {
      router.push("/registration");
    }
  }
};
</script>


