<template>
  <div class="row">
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <div class="row title center-lg center-md center-sm center-xs">
        {{ $t("main.platfotm_title") }}
      </div>
      <div class="row our-description center-lg">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          {{ $t("main.platfotm_text") }}
        </div>
      </div>
      <div class="row center-lg center-md center-sm center-xs our-platform">
        <img src="~@/assets/main-page/platform_device_cluster_group.png" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "OurPlatform"
};
</script>

