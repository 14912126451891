<template>
  <div class="row">
    <div class="col-lg-12">
      <div class="row careers">
        <div class="col-lg-12">
          <div class="careers___box">
            <span class="section___title">{{
              this.$i18n.messages[this.$i18n.locale].careers.title
            }}</span>
            <span class="section___description"
              >{{ this.$i18n.messages[this.$i18n.locale].careers.subtitle }}
            </span>
          </div>
        </div>
      </div>
      <div class="row our-values">
        <div class="col-lg-12 section___title">
          <span>{{
            this.$i18n.messages[this.$i18n.locale].careers.our_values.title
          }}</span>
        </div>
        <div class="col-lg-12">
          <div class="box_item">
            <img src="~@/assets/careers/hands-holding-heart.png" />
            <span>{{
              this.$i18n.messages[this.$i18n.locale].careers.our_values.block1
                .title
            }}</span>
            <p>
              {{
                this.$i18n.messages[this.$i18n.locale].careers.our_values.block1
                  .text
              }}
            </p>
          </div>
          <div class="box_item">
            <img src="~@/assets/careers/support.png" />
            <span>{{
              this.$i18n.messages[this.$i18n.locale].careers.our_values.block2
                .title
            }}</span>
            <p>
              {{
                this.$i18n.messages[this.$i18n.locale].careers.our_values.block2
                  .text
              }}
            </p>
          </div>
          <div class="box_item">
            <img src="~@/assets/careers/thinking.png" />
            <span>{{
              this.$i18n.messages[this.$i18n.locale].careers.our_values.block3
                .title
            }}</span>
            <p>
              {{
                this.$i18n.messages[this.$i18n.locale].careers.our_values.block3
                  .text
              }}
            </p>
          </div>
          <div class="box_item">
            <img src="~@/assets/careers/lightbulb.png" />
            <span>{{
              this.$i18n.messages[this.$i18n.locale].careers.our_values.block4
                .title
            }}</span>
            <p>
              {{
                this.$i18n.messages[this.$i18n.locale].careers.our_values.block4
                  .text
              }}
            </p>
          </div>
          <div class="box_item">
            <img src="~@/assets/careers/clock.png" />
            <span>{{
              this.$i18n.messages[this.$i18n.locale].careers.our_values.block5
                .title
            }}</span>
            <p>
              {{
                this.$i18n.messages[this.$i18n.locale].careers.our_values.block5
                  .text
              }}
            </p>
          </div>
          <div class="box_item">
            <img src="~@/assets/careers/focus.png" />
            <span>{{
              this.$i18n.messages[this.$i18n.locale].careers.our_values.block6
                .title
            }}</span>
            <p>
              {{
                this.$i18n.messages[this.$i18n.locale].careers.our_values.block6
                  .text
              }}
            </p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12 start-your-career">
          <span class="section___title">{{
            this.$i18n.messages[this.$i18n.locale].careers.start_creer.title
          }}</span>
          <div class="section___description">
            {{
              this.$i18n.messages[this.$i18n.locale].careers.start_creer.text
            }}
          </div>
        </div>
      </div>
      <div class="row join-our-team">
        <span class="section___title">{{
          this.$i18n.messages[this.$i18n.locale].careers.join_team.title
        }}</span>
        <div class="col-lg-12 section___description">
          {{
            this.$i18n.messages[this.$i18n.locale].careers.join_team.subtitle
          }}
        </div>
        <div class="col-lg-12 features">
          <p class="text-center">
            {{
              this.$i18n.messages[this.$i18n.locale].careers.join_team
                .block_title
            }}
          </p>

          <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-12">
              <h3>
                {{
                  this.$i18n.messages[this.$i18n.locale].careers.join_team
                    .block_left_title
                }}
              </h3>
              <div
                v-html="
                  this.$i18n.messages[this.$i18n.locale].careers.join_team
                    .block_left_text
                "
              ></div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12">
              <h3>
                {{
                  this.$i18n.messages[this.$i18n.locale].careers.join_team
                    .block_right_title1
                }}
              </h3>
              <ul>
                <p>
                  {{
                    this.$i18n.messages[this.$i18n.locale].careers.join_team
                      .block_right_text1
                  }}
                </p>
              </ul>

              <h3>
                {{
                  this.$i18n.messages[this.$i18n.locale].careers.join_team
                    .block_right_title2
                }}
              </h3>
              <div
                v-html="
                  this.$i18n.messages[this.$i18n.locale].careers.join_team
                    .block_right_text2
                "
              ></div>
            </div>
          </div>

          <p
            class="text-center"
            v-html="
              this.$i18n.messages[this.$i18n.locale].careers.join_team
                .block_bottom_text
            "
          ></p>
        </div>
      </div>
      <why-us-promo></why-us-promo>
      <advantages></advantages>
    </div>
    <footer-promo></footer-promo>
  </div>
</template>

<script>
import FooterPromo from "../../components/layout/footer/FooterPromo";
import Advantages from "../../components/Advantages";
import WhyUsPromo from "../../components/layout/WhyUsPromo";
export default {
  name: "Careers",
  components: { WhyUsPromo, Advantages, FooterPromo }
};
</script>
