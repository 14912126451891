<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
require('@/assets/css/bootstrap.min.css');
require('@/assets/css/fonts/bootstrap-icons.woff');
require('@/assets/css/fonts/bootstrap-icons.woff2');
require('@/assets/css/all-css-libraries.css');
require('@/assets/css/style.css');


export default {
  name: "App",
  components: {
    
  },
};
</script>
