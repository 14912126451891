<template>
  <div class="row">
    <div class="col-xs-12 bl-bg">
      <div class="row">
        <div class="col-xs-12 bl-title">
          {{ $t("dashboard.wallets") }}
        </div>
        <div class="col-xs-12 bl-content">
          <div class="row center-xs">
            <div class="col-lg-3 col-xs-12">
              <div class="row wrapper-image">
                <img src="@/assets/personal-dashboard/balance/usd.png" />
              </div>
              <div class="row balances">
                <div class="col-xs-12">
                  <div class="row">
                    <div class="col-xs-6">
                      {{ $t("dashboard.balance") }}
                    </div>
                    <div class="col-xs-6">
                      {{ funds.purses.USD.balance }}
                    </div>
                  </div>
                  <div class="row">
                    <br /><br />
                    <div class="col-xs-6">
                      {{ $t("dashboard.available") }}
                    </div>
                    <div class="col-xs-6">
                      {{ funds.purses.USD.available }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-xs-12">
              <div class="row wrapper-image">
                <img src="@/assets/personal-dashboard/balance/eur.png" />
              </div>
              <div class="row balances">
                <div class="col-xs-12">
                  <div class="row">
                    <div class="col-xs-6">
                      {{ $t("dashboard.balance") }}
                    </div>
                    <div class="col-xs-6">
                      {{ funds.purses.EUR.balance }}
                    </div>
                  </div>
                  <div class="row">
                    <br /><br />
                    <div class="col-xs-6">
                      {{ $t("dashboard.available") }}
                    </div>
                    <div class="col-xs-6">
                      {{ funds.purses.EUR.available }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-xs-12">
              <div class="row wrapper-image">
                <img src="@/assets/personal-dashboard/balance/bitcoin.png" />
              </div>
              <div class="row balances">
                <div class="col-xs-12">
                  <div class="row">
                    <div class="col-xs-6">
                      {{ $t("dashboard.balance") }}
                    </div>
                    <div class="col-xs-6">
                      {{ funds.purses.BTC.balance }}
                    </div>
                  </div>
                  <div class="row">
                    <br /><br />
                    <div class="col-xs-6">
                      {{ $t("dashboard.available") }}
                    </div>
                    <div class="col-xs-6">
                      {{ funds.purses.BTC.available }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-xs-12 bl-title">
          {{ $t("dashboard.conversion") }}
        </div>
        <div class="col-xs-12 bl-content">
          <div class="row center-xs middle-lg">
            <div class="col-xs-3">
              <input type="text" />
            </div>
            <div class="col-xs-6">
              <!-- Arrows -->
            </div>
            <div class="col-xs-3">
              <input type="text" />
            </div>
          </div>
          <div class="row center-xs">
            <div class="col-xs-12">
              <button>{{ $t("dashboard.convert") }}</button>
              <br /><br /><br /><br /><br />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "Balance",
  computed: mapState(["funds"]),
  created() {
    this.$store.dispatch("fetchFunds");
  }
};
</script>

