import { render, staticRenderFns } from "./TradesHistoryTabs.vue?vue&type=template&id=545cc897&scoped=true&"
import script from "./TradesHistoryTabs.vue?vue&type=script&lang=js&"
export * from "./TradesHistoryTabs.vue?vue&type=script&lang=js&"
import style0 from "./TradesHistoryTabs.vue?vue&type=style&index=0&id=545cc897&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "545cc897",
  null
  
)

export default component.exports