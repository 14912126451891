<template>
  <div class="row earningsCalendar">
    <h2 class="center-lg center-md center-sm center-xs">
      {{ $t("menu.earnings_calendar") }}
    </h2>
    <div
      class="col-lg-12 col-md-12 col-sm-12 col-xs-12"
      v-if="earningsCalendar"
    >
      <div v-html="earningsCalendar">
        Loading, please wait...
      </div>
    </div>

    <advantages></advantages>
    <footer-promo></footer-promo>
  </div>
</template>

<script>
import Advantages from "@/components/layout/Advantages";
import FooterPromo from "@/components/layout/footer/FooterPromo.vue";
import { mapState } from "vuex";
export default {
  name: "EarningsCalendar",
  components: {
    Advantages,
    FooterPromo
  },
  computed: mapState(["earningsCalendar"]),
  mounted() {
    this.getEarningsCalendar();
  },
  methods: {
    getEarningsCalendar() {
      this.$store.dispatch("getEarningsCalendar");
    }
  }
};
</script>
