<template>
  <div id="trades-history" class="row mrl-0">
                  <!-- <vue-element-loading :active="!openTrades.length" spinner="bar-fade-scale" size="64" background-color="#0c153b" color="#ffd607"/> -->
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <div class="d-flex flex-wrap align-items-center justify-content-between mb-3">
        <ul class="nav nav-tabs border-0 mb-2 width-100" id="funtoTab" role="tablist">
          <li 
            class="nav-item" 
            @click="setActiveTab('open-trades');
                    fetchOpenTrades();
            " 
            :class="{ active: activeTab === 'open-trades' }"
            >
            <a :class="{ active: activeTab === 'open-trades', 'btn-warning': activeTab === 'open-trades', 'btn-primary': activeTab != 'open-trades'}" class="rounded-pill btn btn-sm me-1 hide-on-mobile" id="tab--1" data-bs-toggle="tab" href="#tab1" role="tab" aria-controls="tab1" aria-selected="true">{{ $t("dashboard.open_trades") }}</a>
            <a :class="{ active: activeTab === 'open-trades', 'btn-warning': activeTab === 'open-trades', 'btn-primary': activeTab != 'open-trades'}" class="rounded-pill btn btn-sm me-1 show-on-mobile" id="tab--1" data-bs-toggle="tab" href="#tab1" role="tab" aria-controls="tab1" aria-selected="true">TRADES</a>
          </li>
          <li class="nav-item" 
            @click="
              setActiveTab('trade-history');
              fetchTradeHistory();
            "
            :class="{ active: activeTab === 'trade-history' }">
              <a :class="{ active: activeTab === 'trade-history', 'btn-warning': activeTab === 'trade-history', 'btn-primary': activeTab != 'trade-history'}" class="rounded-pill btn btn-sm me-1 hide-on-mobile" id="tab--2" data-bs-toggle="tab" href="#tab2" role="tab" aria-controls="tab2" aria-selected="false">{{ $t("dashboard.trades_history") }}</a>
              <a :class="{ active: activeTab === 'trade-history', 'btn-warning': activeTab === 'trade-history', 'btn-primary': activeTab != 'trade-history'}" class="rounded-pill btn btn-sm me-1 show-on-mobile" id="tab--2" data-bs-toggle="tab" href="#tab2" role="tab" aria-controls="tab2" aria-selected="false">HISTORY</a>
            </li>
          <li class="nav-item"
            @click="setActiveTab('statistic')"
            :class="{ active: activeTab === 'statistic' }"
          ><a :class="{ active: activeTab === 'statistic', 'btn-warning': activeTab === 'statistic', 'btn-primary': activeTab != 'statistic'}" class="rounded-pill btn btn-sm me-1" id="tab--3" data-bs-toggle="tab" href="#tab3" role="tab" aria-controls="tab3" aria-selected="false">STATISTIC</a></li>
          <li class="nav-item"
              @click="
              setActiveTab('funds');
              fetchFunds();
            "
            :class="{ active: activeTab === 'funds' }"
          ><a :class="{ active: activeTab === 'funds', 'btn-warning': activeTab === 'funds', 'btn-primary': activeTab != 'funds'}" class="rounded-pill btn btn-sm me-1" id="tab--4" data-bs-toggle="tab" href="#tab4" role="tab" aria-controls="tab4" aria-selected="false">{{ $t("dashboard.funds") }}</a>
          </li>
          <li v-if="activeTab === 'trade-history'" class="nav-item ml-auto d-flex align-item-end">
                <div class="color-btn-custom mt-auto rounded-pill btn btn-sm me-1" @click="goBack()">&lt</div>
                <div class="color-btn-custom mt-auto rounded-pill btn btn-sm me-1" @click="goNext()">></div>
          </li>
        </ul>
      </div>
      <div class="tab-content">
        <!-- Tab Pane-->
        <div class="tab-pane fade show active" id="tab1" role="tabpanel" aria-labelledby="tab--1">
          <div class="table-responsive border shadow-sm dashboard-table activity-table elem-hide-1200">
            <div
              class="col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-3 shadow text-center"
              v-show="activeTab === 'open-trades'"
            >
              <div class="row" :class="{'add-mb': !openTrades.length}">
                <div class="wa-7 col-lg-1 col-md-1 col-sm-1 col-xs-1">{{ $t("dashboard.order") }}</div>
                <div class="wa-12 col-lg-1 col-md-1 col-sm-1 col-xs-1">{{ $t("dashboard.time") }}</div>
                <div class="wa-15 col-lg-1 col-md-1 col-sm-1 col-xs-1">{{ $t("dashboard.instrument") }}</div>
                <div class="wa-7 col-lg-1 col-md-1 col-sm-1 col-xs-1">{{ $t("dashboard.type") }}</div>
                <div class="wa-9 col-lg-1 col-md-1 col-sm-1 col-xs-1">{{ $t("dashboard.amount") }}</div>
                <div class="wa-10 col-lg-1 col-md-1 col-sm-1 col-xs-1">{{ $t("dashboard.opening_price") }}</div>
                <div class="wa-5 col-lg-1 col-md-1 col-sm-1 col-xs-1">{{ $t("dashboard.s_l") }}</div>
                <div class="wa-5 col-lg-1 col-md-1 col-sm-1 col-xs-1">{{ $t("dashboard.t_p") }}</div>
                <div class="wa-10 col-lg-1 col-md-1 col-sm-1 col-xs-1">{{ $t("dashboard.current_price") }}</div>
                <div class="wa-11 col-lg-1 col-md-1 col-sm-1 col-xs-1">{{ $t("dashboard.profit_loss") }}</div>
                <div class="wa-5 col-lg-1 col-md-1 col-sm-1 col-xs-1"></div>
              </div>
            </div>

            <div
              class="col-lg-12 col-md-12 col-sm-12 col-xs-12"
              v-show="activeTab === 'open-trades' && openTrades.length"
            >
              <div
                class="row middle-lg middle-md middle-sm middle-xs table-content mb-3 shadow-sm text-center align-items-center"
                v-for="trade in openTrades"
                v-bind:key="'open-trade-id-' + trade.id"
              >
                <div class="wa-7 col-lg-1 col-md-1 col-sm-1 col-xs-1">{{ hash(trade.id) }}</div>
                <div class="wa-12 col-lg-1 col-md-1 col-sm-1 col-xs-1">
                  {{
                    new Date(trade.time_start * 1000).toLocaleDateString("en-US")
                  }}
                  <br />
                  {{
                    new Date(trade.time_start * 1000).toLocaleTimeString("en-US", { hour12: false })
                  }}
                </div>
                <div class="wa-15 col-lg-1 col-md-1 col-sm-1 col-xs-1 open-trade-name" @click="changeChart(trade)">{{ trade.symbol.base_symbol }}/{{ trade.symbol.quote_symbol }}</div>
                <div class="wa-7 col-lg-1 col-md-1 col-sm-1 col-xs-1">{{ trade.direct }}</div>
                <div class="wa-9 col-lg-1 col-md-1 col-sm-1 col-xs-1">{{ trade.lot }}</div>
                <div class="wa-10 col-lg-1 col-md-1 col-sm-1 col-xs-1">{{ trade.start_price }}</div>
                <div class="wa-5 col-lg-1 col-md-1 col-sm-1 col-xs-1">{{ trade.stop_loss }}</div>
                <div class="wa-5 col-lg-1 col-md-1 col-sm-1 col-xs-1">{{ trade.take_profit }}</div>
                <div class="wa-10 col-lg-1 col-md-1 col-sm-1 col-xs-1">{{ trade.end_price }}</div>
                <div class="wa-10 col-lg-1 col-md-1 col-sm-1 col-xs-1">{{ trade.profit }}</div>
                <div class="wa-5 col-lg-1 col-md-1 col-sm-1 col-xs-1">
                  <div
                    class="btn btn-sm rounded-pill close"
                    v-if="!isLimitedAccess"
                    @click="closeTrade(trade)"
                    :class="{'btn-danger': trade.profit <= 0, 'btn-primary': trade.profit > 0}"
                  >X</div>
                </div>
              </div>
            </div>
          </div>
          <div class="row mrl-0 elem-show-1200" v-show="activeTab === 'open-trades'">
            <!-- Single Card -->
            <div class="col-xs-12 col-sm-12 col-md-6 col-xl-4"
                          v-for="trade in openTrades"
              v-bind:key="'open-trade-id-single-card-' + trade.id"
            >
              <div class="nft-card card shadow-sm">
                <div class="card-body">
                  <div class="row align-items-center g-2">
                    <div class="text-center col-lg-6 col-md-6 col-sm-12 col-xs-12 shadow-sm c-white">
                  {{ $t("dashboard.order") }}
                </div>
                <div class="text-center col-lg-6 col-md-6 col-sm-12 col-xs-12">{{ hash(trade.id) }}</div>

                <div class="text-center col-lg-6 col-md-6 col-sm-12 col-xs-12 shadow-sm c-white">
                  {{ $t("dashboard.time") }}
                </div>
                <div class="text-center col-lg-6 col-md-6 col-sm-12 col-xs-12">
                  {{
                    new Date(trade.time_start * 1000).toLocaleDateString("en-US")
                  }}
                  <br />
                  {{
                    new Date(trade.time_start * 1000).toLocaleTimeString("en-US", { hour12: false })
                  }}
                </div>
                <div class="text-center col-lg-6 col-md-6 col-sm-12 col-xs-12 shadow-sm c-white">
                  {{ $t("dashboard.instrument") }}
                </div>
                <div class="text-center col-lg-6 col-md-6 col-sm-12 col-xs-12">
                  {{ trade.symbol.base_symbol }}/{{ trade.symbol.quote_symbol }}
                </div>
                
                <div class="text-center col-lg-6 col-md-6 col-sm-12 col-xs-12 shadow-sm c-white">
                  {{ $t("dashboard.type") }}
                </div>
                <div class="text-center col-lg-6 col-md-6 col-sm-12 col-xs-12">{{ trade.direct }}</div>
                
                <div class="text-center col-lg-6 col-md-6 col-sm-12 col-xs-12 shadow-sm c-white">
                  {{ $t("dashboard.amount") }}
                </div>
                <div class="text-center col-lg-6 col-md-6 col-sm-12 col-xs-12">{{ trade.lot }}</div>
                
                <div class="text-center col-lg-6 col-md-6 col-sm-12 col-xs-12 shadow-sm c-white">
                  {{ $t("dashboard.opening_price") }}
                </div>
                <div class="text-center col-lg-6 col-md-6 col-sm-12 col-xs-12">
                  {{ trade.start_price }}
                </div>
                
                <div class="text-center col-lg-6 col-md-6 col-sm-12 col-xs-12 shadow-sm c-white">
                  {{ $t("dashboard.s_l") }}
                </div>
                <div class="text-center col-lg-6 col-md-6 col-sm-12 col-xs-12">
                  {{ trade.stop_loss }}
                </div>
                
                <div class="text-center col-lg-6 col-md-6 col-sm-12 col-xs-12 shadow-sm c-white">
                  {{ $t("dashboard.t_p") }}
                </div>
                <div class="text-center col-lg-6 col-md-6 col-sm-12 col-xs-12">
                  {{ trade.take_profit }}
                </div>
                
                <div class="text-center col-lg-6 col-md-6 col-sm-12 col-xs-12 shadow-sm c-white">
                  {{ $t("dashboard.current_price") }}
                </div>
                <div class="text-center col-lg-6 col-md-6 col-sm-12 col-xs-12">
                  {{ trade.end_price }}
                </div>
                
                <div class="text-center col-lg-6 col-md-6 col-sm-12 col-xs-12 shadow-sm c-white">
                  {{ $t("dashboard.profit_loss") }}
                </div>
                <div class="text-center col-lg-6 col-md-6 col-sm-12 col-xs-12">
                  {{ trade.profit }}
                </div>
                <div class="text-center col-lg-12 col-md-12 col-sm-12-col-xs-12">
                  <div
                    class="btn btn-sm text-center rounded-pill close"
                    v-if="!isLimitedAccess"
                    @click="closeTrade(trade)"
                    :class="{'btn-danger': trade.profit <= 0, 'btn-primary': trade.profit > 0}"
                  >X</div>
                </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Tab Pane-->
        <div class="tab-pane fade" id="tab2" role="tabpanel" aria-labelledby="tab--2">
          <div class="table-responsive border shadow-sm activity-table elem-hide-1200">
            <div
              class="col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-3 shadow text-center"
              v-show="activeTab === 'trade-history'">
              <div class="row">
                <div class="wa-8 col-lg-1 col-md-1 col-sm-1 col-xs-1">
                  #
                </div>
                <div class="wa-10 col-lg-1 col-md-1 col-sm-1 col-xs-1">
                  Start
                </div>
                <div class="wa-10 col-lg-1 col-md-1 col-sm-1 col-xs-1">
                  End
                </div>
                <div class="wa-12 col-lg-1 col-md-1 col-sm-1 col-xs-1">
                  {{ $t("dashboard.instrument") }}
                </div>
                <div class="wa-7 col-lg-1 col-md-1 col-sm-1 col-xs-1">
                  {{ $t("dashboard.type") }}
                </div>
                <div class="wa-9 col-lg-1 col-md-1 col-sm-1 col-xs-1">
                  {{ $t("dashboard.amount") }}
                </div>
                <div class="wa-10 col-lg-1 col-md-1 col-sm-1 col-xs-1">
                  Open
                </div>
                <div class="wa-3 col-lg-1 col-md-1 col-sm-1 col-xs-1">
                  {{ $t("dashboard.s_l") }}
                </div>
                <div class="wa-3 col-lg-1 col-md-1 col-sm-1 col-xs-1">
                  {{ $t("dashboard.t_p") }}
                </div>
                <div class="wa-10 col-lg-1 col-md-1 col-sm-1 col-xs-1">
                  Close
                </div>
                <div class="wa-7 col-lg-1 col-md-1 col-sm-1 col-xs-1">
                  Earn
                </div>
              </div>
            </div>
            <div
              class="col-lg-12 col-md-12 col-sm-12 col-xs-12 trade-history"
              v-show="activeTab === 'trade-history'"
              @scroll="handleScroll"
              >
              <div
                class="row middle-lg middle-md middle-sm middle-xs table-content mb-3 shadow-sm text-center"
                v-for="history in tradesHistory"
                v-bind:key="'history-trade-id-' + history.id"
              >
                <div class="wa-8 col-lg-1 col-md-1 col-sm-1 col-xs-1">{{ hash(history.id) }}</div>
                <div class="wa-10 col-lg-1 col-md-1 col-sm-1 col-xs-1">
                  {{
                    new Date(history.time_start * 1000).toLocaleDateString("en-US")
                  }}
                  <br />
                  {{
                    new Date(history.time_start * 1000).toLocaleTimeString("en-US", { hour12: false })
                  }}
                </div>
                <div class="wa-10 col-lg-1 col-md-1 col-sm-1 col-xs-1">
                  {{
                    new Date(history.time_end * 1000).toLocaleDateString("en-US")
                  }}
                  <br />
                  {{
                    new Date(history.time_end * 1000).toLocaleTimeString("en-US", { hour12: false })
                  }}
                </div>
                <div class="wa-12 col-lg-1 col-md-1 col-sm-1 col-xs-1">
                  {{ history.symbol.base_symbol }}/{{ history.symbol.quote_symbol }}
                </div>
                <div class="wa-7 col-lg-1 col-md-1 col-sm-1 col-xs-1">{{ history.direct }}</div>
                <div class="wa-8 col-lg-1 col-md-1 col-sm-1 col-xs-1">{{ history.lot }}</div>
                <div class="wa-10 col-lg-1 col-md-1 col-sm-1 col-xs-1">
                  {{ history.start_price }}
                </div>
                <div class="wa-3 col-lg-1 col-md-1 col-sm-1 col-xs-1">
                  {{ history.stop_loss }}
                </div>
                <div class="wa-3 col-lg-1 col-md-1 col-sm-1 col-xs-1">
                  {{ history.take_profit }}
                </div>
                <div class="wa-10 col-lg-1 col-md-1 col-sm-1 col-xs-1">
                  {{ history.end_price }}
                </div>
                <div class="wa-7 col-lg-1 col-md-1 col-sm-1 col-xs-1">{{ history.earn }}</div>
              </div>
            </div>
          </div>
          <div class="row mrl-0 elem-show-1200">
            <!-- Single Card -->
            <div class="col-xs-12 col-sm-12 col-md-6 col-xl-4"
                          v-for="history in tradesHistory"
              v-bind:key="'history-trade-id-single-card-' + history.id"
            >
              <div class="nft-card card shadow-sm">
                <div class="card-body">
                  <div class="row align-items-center g-2">
                    <div class="col-lg-6 col-md-6 col-sm-12 text-center col-xs-12 shadow-sm c-white">
                  {{ $t("dashboard.details") }}
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12 text-center col-xs-12">{{ hash(history.id) }}</div>

                <div class="col-lg-6 col-md-6 col-sm-12 text-center col-xs-12 shadow-sm c-white">
                  {{ $t("dashboard.open_time") }}
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12 text-center col-xs-12">
                  {{
                    new Date(history.time_start * 1000).toLocaleDateString("en-US")
                  }}
                  <br />
                  {{
                    new Date(history.time_start * 1000).toLocaleTimeString("en-US", { hour12: false })
                  }}
                </div>
                
                <div class="col-lg-6 col-md-6 col-sm-12 text-center col-xs-12 shadow-sm c-white">
                  {{ $t("dashboard.close_time") }}
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12 text-center col-xs-12">
                  {{
                    new Date(history.time_end * 1000).toLocaleDateString("en-US")
                  }}
                  <br />
                  {{
                    new Date(history.time_end * 1000).toLocaleTimeString("en-US", { hour12: false })
                  }}
                </div>
                
                <div class="col-lg-6 col-md-6 col-sm-12 text-center col-xs-12 shadow-sm c-white">
                  {{ $t("dashboard.instrument") }}
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12 text-center col-xs-12">
                  {{ history.symbol.base_symbol }}/{{ history.symbol.quote_symbol }}
                </div>
                
                <div class="col-lg-6 col-md-6 col-sm-12 text-center col-xs-12 shadow-sm c-white">
                  {{ $t("dashboard.type") }}
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12 text-center col-xs-12">{{ history.direct }}</div>
                
                <div class="col-lg-6 col-md-6 col-sm-12 text-center col-xs-12 shadow-sm c-white">
                  {{ $t("dashboard.amount") }}
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12 text-center col-xs-12">{{ history.lot }}</div>
                
                <div class="col-lg-6 col-md-6 col-sm-12 text-center col-xs-12 shadow-sm c-white">
                  {{ $t("dashboard.opening_price") }}
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12 text-center col-xs-12">
                  {{ history.start_price }}
                </div>
                
                <div class="col-lg-6 col-md-6 col-sm-12 text-center col-xs-12 shadow-sm c-white">
                  {{ $t("dashboard.s_l") }}
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12 text-center col-xs-12">
                  {{ history.stop_loss }}
                </div>
                
                <div class="col-lg-6 col-md-6 col-sm-12 text-center col-xs-12 shadow-sm c-white">
                  {{ $t("dashboard.t_p") }}
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12 text-center col-xs-12">
                  {{ history.take_profit }}
                </div>
                
                <div class="col-lg-6 col-md-6 col-sm-12 text-center col-xs-12 shadow-sm c-white">
                  {{ $t("dashboard.closing_price") }}
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12 text-center col-xs-12">
                  {{ history.end_price }}
                </div>
                
                <div class="col-lg-6 col-md-6 col-sm-12 text-center col-xs-12 shadow-sm c-white">
                  {{ $t("dashboard.earned") }}
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12 text-center col-xs-12">{{ history.earn }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Tab Pane-->
        <div class="tab-pane fade" id="tab3" role="tabpanel" aria-labelledby="tab--3">
          <div class="table-responsive border shadow-sm activity-table">
            <div class="coming-soon-wrapper stastic__image">
              <div class="cs-content">
                <div class="container" >
                  <div class="justify-content-center">
                    <div class="col-12 col-lg-12">
                      <!-- Countdown -->
                      <h2 class="display-4 mb-4 fw-bold">Statistic Is Coming Soon</h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Tab Pane-->
        <div class="tab-pane fade" id="tab4" role="tabpanel" aria-labelledby="tab--4">
          <div class="table-responsive border shadow-sm activity-table">
            <div class="card-body text-center p-4">
              <h6 class="mb-1">Balance: </h6>
              <h5 class="mb-0 text-dark d-flex align-items-center justify-content-center">
                <span class="counter btn btn-warning rounded-pill btn-sm w-100 ">{{ userBalance ? userBalance.balance : 0 }}<span class="ms-2">$</span></span>
              </h5>
              <h6 class="mb-1 mt-2">Available: </h6>
              <h5 class="mb-0 text-dark d-flex align-items-center justify-content-center">
                <span class="counter btn btn-warning rounded-pill btn-sm w-100 ">{{ userBalance ? userBalance.available : 0 }}<span class="ms-2">$</span></span>
              </h5>
              <h6 class="mb-1 mt-2">Profit/Loss: </h6>
              <h5 class="mb-0 text-dark d-flex align-items-center justify-content-center">
                <span class="counter btn btn-warning rounded-pill btn-sm w-100 ">{{ userBalance ? userBalance.profit : 0 }}<span class="ms-2">$</span></span>
              </h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment"; 
import Vue from "vue";

export default {
  name: "TradesHistoryTabs",
  computed: {
    activeTab() {
      return this.currentActiveTab
    },
    showSpinner() {
      return this.isLoading;
    },
    ...mapState([
      "openTrades",
      "tradesHistory",
      "funds",
      "profitLossReachedBalance",
      "checkIsLimited",
      "isLimitedAccess",
      "userBalance",
      "user"
    ]),
    getSDays() {
      return this.statisticDatedays;
    },
    getSHours() {
      return this.statisticDatehours;
    },
    getSMinutes() {
      return this.statisticDateminutes;
    },
    getSSeconds() {
      return this.statisticDateseconds;
    },
  },
  components: {},
  data() {
    return {
      currentActiveTab: "open-trades",
      page: 1,
      isHistoryLoading: false,
      errorMessage: "",
      isLoading: true,
      statisticDatedays: 1,
      statisticDatehours: 1,
      statisticDateminutes: 1,
      statisticDateseconds: 1,
      statisticDateinterval: 1,
    };
  },
  created() {
    setInterval(() => {
      this.fetchOpenTrades();
    }, 5000);
    clearInterval(this.statisticDateinterval);
    this.time();
  },
  methods: {
    changeChart(trade) {
      this.$store.dispatch("getSymbolLast", { symbol_id: trade.symbol.id, symbol_name: trade.symbol.symbol }).then(({last, first }) => {
        this.$store.commit("SET_DEAL_SYMBOL", {...first, ...last});
        this.$store.commit("SET_DEAL_DIRECT", trade.direct);
        
        this.$store.dispatch("fetchKLines", { symbol: {...first, ...last}, interval: "D1" });
        this.$store.dispatch(
          "sendMessage",
          JSON.stringify({
            join: "klines_d1_" + last.symbol.toLowerCase(),
            notificator: "notificator"
          })
        );
      });
    },
    time(){
      let userTime = moment(this.user.created_at).add(30, 'd');
      this.statisticDateinterval = setInterval(() => {
        const now = moment();
        const delta = userTime - now;

        Vue.set(this, 'statisticDatedays', userTime.diff(now, "days"));
        Vue.set(this, 'statisticDatehours', userTime.diff(now, "hours") % userTime.diff(now, "days"));
        Vue.set(this, 'statisticDateminutes', userTime.diff(now, "minutes") - (userTime.diff(now, "hours") * 60));
        Vue.set(this, 'statisticDateseconds', userTime.diff(now, "seconds") - userTime.diff(now, "minutes") * 60);
        // this.statisticDatedays = ;
        // this.statisticDatehours = ;
        // this.statisticDateminutes = ;
        // this.statisticDateseconds = ;
      }, 1000)
    },
    hash: function(str, cycles = 3) {      
      let hash = 0;
      let source = "" + str;
      for (let j = 0; j < cycles; j++) {
        for (let i = 0; i < source.length; i++) {
          const char = source.charCodeAt(i);
          hash = (hash << 4) - hash + char;
          hash &= hash; // Convert to 32bit integer
        }
      }
      
      return new Uint32Array([hash])[0].toString(36);
    },
    setActiveTab: function(tab) {
      this.currentActiveTab = tab;
    },
    fetchOpenTrades: function () {
      this.$store.dispatch("actualizeOpenTrades");
    },
    closeTrade: function(trade) {
      this.$store.dispatch("closeTrade", trade);
    },
    fetchTradeHistory: function() {
      this.page = 1;
      this.$store.dispatch("fetchTradeHistory", this.page);
    },
    fetchFunds: function() {
      this.$store.dispatch("fetchFunds");
    },
    goNext(){
      this.page += 1;
      this.$store.dispatch("fetchTradeHistory", this.page);
    },
    goBack(){
      this.page -= 1;
      if(this.page < 1){
        this.page = 1;
      }
      this.$store.dispatch("fetchTradeHistory", this.page);
    },
    handleScroll({ target: { scrollTop, clientHeight, scrollHeight } }) {
      if (scrollTop + clientHeight >= scrollHeight) {
        this.isHistoryLoading = true;
        this.$store
          .dispatch("fetchTradeHistory", ++this.page)
          .then(() => {
            this.isHistoryLoading = false;
            this.errorMessage = "";
          })
          .catch(() => {
            this.errorMessage = "Nothing to load..";
          });
      }
    }
  }
};
</script>
<style  scoped>

  .mrl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

.fz-24 { font-size: 24px; } 

.c-white { color: #0d6efd; }

.container{
  margin: auto !important;
}

@media only screen and (max-width: 992px) {
  .hide-on-mobile {
    display: none;
  }

  a.show-on-mobile {
    display: inherit;
  }


}

@media only screen and (max-width: 479px) {
  ul > li {
    margin-bottom: 10px;
  }
}

@media only screen and (max-width: 992px) {
  ul > li {
    margin-bottom: 10px;
  }
}

@media only screen and (max-width: 992px) {

}

@media only screen and (min-width: 992px) {
  a.show-on-mobile {
    display: none;
  }
}

.add-mb {
  margin-bottom: 48px;
}

.wa-5 {
  -ms-flex-preferred-size: 5% !important;
  flex-basis: 5% !important;
  max-width: 5% !important;
}

.wa-7 {
  -ms-flex-preferred-size: 7% !important;
  flex-basis: 7% !important;
  max-width: 7% !important;
}

.wa-8 {
  -ms-flex-preferred-size: 8% !important;
  flex-basis: 8% !important;
  max-width: 8% !important;
}

.wa-9 {
  -ms-flex-preferred-size: 9% !important;
  flex-basis: 9% !important;
  max-width: 9% !important;
}

.wa-10 {
  -ms-flex-preferred-size: 10% !important;
  flex-basis: 10% !important;
  max-width: 10% !important;
}

.wa-11 {
  -ms-flex-preferred-size: 11% !important;
  flex-basis: 11% !important;
  max-width: 11% !important;
}

.wa-12 {
  -ms-flex-preferred-size: 12% !important;
  flex-basis: 12% !important;
  max-width: 12% !important;
}

.wa-15 {
  -ms-flex-preferred-size: 15% !important;
  flex-basis: 15% !important;
  max-width: 15% !important;
}

.col-xs-2 {
  -ms-flex-preferred-size: 16.66666667%;
  flex-basis: 16.66666667%;
  max-width: 16.66666667%;
}
  .trade-history {
    font-size: 14px; 
  }

  .nav-item {
    margin-right: 15px;
  }

@media only screen and (max-width: 1280px) {
  .elem-hide-1200 {
    display: none;
  } 
  .elem-show-1200 {
    display: flex;
  }
}

@media only screen and (min-width: 1280px) {
  .elem-show-1200 {
    display: none;
  }
}

@media only screen and (max-width: 476px) {
  #funtoTab {
    width: 100%;
  }

  #funtoTab > li {
    width: 100%;
    margin-top: 10px;
    margin-left: 0;
    margin-right: 0;
  }

  #funtoTab > li > a {
    width: 100%;
  }
}

.stastic__image{
  background: url("~@/assets/img/bg-img/1.jpg") no-repeat;
    -moz-background-size: 100%; /* Firefox 3.6+ */
    -webkit-background-size: 100%; /* Safari 3.1+ и Chrome 4.0+ */
    -o-background-size: 100%; /* Opera 9.6+ */
    background-size: 100%;
    background-size: cover;
}

.open-trade-name:hover {
  cursor: pointer;
  color: white;
}

.coming-soon-wrapper {
  min-height: 100%;
  min-width: 100%;
}

.width-100{
  width: 100%;
}
div.color-btn-custom{
  background-color: #030205;
  color: #8480ae;
  padding: 0px 6px;
  align-self: self-end;
}
div.color-btn-custom:hover {
  background-color: #211832;
  color: #646185;;
}
.ml-auto{
  margin-left: auto;
}

.mt-auto{
  margin-top: auto;
}
</style>
