<template>
  <div class="row">
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <div class="row center-lg center-md center-sm center-xs trade-title">
        <h1>{{ $t("trade_forex.title") }}</h1>
      </div>
    </div>
    <div
      class="col-lg-12 col-md-12 col-sm-12 col-xs-12 trade-sub-title center-lg center-md center-sm center-xs"
    >
      {{ $t("trade_forex.text") }}
    </div>
    <div
      class="col-lg-12 col-md-12 col-sm-12 col-xs-12 center-lg center-md center-sm center-xs var-bl"
    >
      <div class="row" style="overflow: scroll;">
        <table class="margin-table col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <tr class="row head-tbl">
            <th class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
              {{ $t("trade_forex.currencies") }}
            </th>
            <th class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
              {{ $t("trade_forex.general_info") }}
            </th>
            <th
              class="col-lg-4 col-md-4 col-sm-4 col-xs-4 center-lg center-md center-sm center-xs"
            ></th>
          </tr>
          <tr class="row middle-lg middle-md middle-sm middle-xs">
            <td class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
              <img src="@/assets/market-types/forex/dolar-euro.png" />
            </td>
            <td class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
              <div class="market-type-description">
                {{ $t("trade_forex.table_text1") }} usd euro{{
                  $t("trade_forex.table_text2")
                }}
              </div>
            </td>
            <td class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
              <div class="button-start">
                <button @click="redirectToRegistration()">
                  {{ $t("trade_forex.start_trading") }}
                </button>
              </div>
            </td>
          </tr>
          <tr class="row middle-lg middle-md middle-sm middle-xs">
            <td class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
              <img src="@/assets/market-types/forex/euro-dolar.png" />
            </td>
            <td class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
              <div class="market-type-description">
                {{ $t("trade_forex.table_text1") }} euro usd{{
                  $t("trade_forex.table_text2")
                }}
              </div>
            </td>
            <td class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
              <div class="button-start">
                <button @click="redirectToRegistration()">
                  {{ $t("trade_forex.start_trading") }}
                </button>
              </div>
            </td>
          </tr>
          <tr class="row middle-lg middle-md middle-sm middle-xs">
            <td class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
              <img src="@/assets/market-types/forex/dolar-pound.png" />
            </td>
            <td class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
              <div class="market-type-description">
                {{ $t("trade_forex.table_text1") }} usd pound{{
                  $t("trade_forex.table_text2")
                }}
              </div>
            </td>
            <td class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
              <div class="button-start">
                <button @click="redirectToRegistration()">
                  {{ $t("trade_forex.start_trading") }}
                </button>
              </div>
            </td>
          </tr>
          <tr class="row middle-lg middle-md middle-sm middle-xs">
            <td class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
              <img src="@/assets/market-types/forex/pound-dolar.png" />
            </td>
            <td class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
              <div class="market-type-description">
                {{ $t("trade_forex.table_text1") }} pound usd{{
                  $t("trade_forex.table_text2")
                }}
              </div>
            </td>
            <td class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
              <div class="button-start">
                <button @click="redirectToRegistration()">
                  {{ $t("trade_forex.start_trading") }}
                </button>
              </div>
            </td>
          </tr>
          <tr class="row middle-lg middle-md middle-sm middle-xs">
            <td class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
              <img src="@/assets/market-types/forex/euro-pound.png" />
            </td>
            <td class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
              <div class="market-type-description">
                {{ $t("trade_forex.table_text1") }} euro pound{{
                  $t("trade_forex.table_text2")
                }}
              </div>
            </td>
            <td class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
              <div class="button-start">
                <button @click="redirectToRegistration()">
                  {{ $t("trade_forex.start_trading") }}
                </button>
              </div>
            </td>
          </tr>
          <tr class="row middle-lg middle-md middle-sm middle-xs">
            <td class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
              <img src="@/assets/market-types/forex/pound-euro.png" />
            </td>
            <td class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
              <div class="market-type-description">
                {{ $t("trade_forex.table_text1") }} pound euro{{
                  $t("trade_forex.table_text2")
                }}
              </div>
            </td>
            <td class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
              <div class="button-start">
                <button @click="redirectToRegistration()">
                  {{ $t("trade_forex.start_trading") }}
                </button>
              </div>
            </td>
          </tr>
          <tr class="row middle-lg middle-md middle-sm middle-xs">
            <td class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
              <img src="@/assets/market-types/forex/dolar-yen.png" />
            </td>
            <td class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
              <div class="market-type-description">
                {{ $t("trade_forex.table_text1") }} dolar yen{{
                  $t("trade_forex.table_text2")
                }}
              </div>
            </td>
            <td class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
              <div class="button-start">
                <button @click="redirectToRegistration()">
                  {{ $t("trade_forex.start_trading") }}
                </button>
              </div>
            </td>
          </tr>
          <tr class="row middle-lg middle-md middle-sm middle-xs">
            <td class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
              <img src="@/assets/market-types/forex/yen-dolar.png" />
            </td>
            <td class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
              <div class="market-type-description">
                {{ $t("trade_forex.table_text1") }} yen dolar{{
                  $t("trade_forex.table_text2")
                }}
              </div>
            </td>
            <td class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
              <div class="button-start">
                <button @click="redirectToRegistration()">
                  {{ $t("trade_forex.start_trading") }}
                </button>
              </div>
            </td>
          </tr>
        </table>
      </div>
    </div>
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <why-us-promo></why-us-promo>
    </div>
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <advantages></advantages>
    </div>
    <footer-promo></footer-promo>
  </div>
</template>

<script>
import WhyUsPromo from "@/components/layout/WhyUsPromo";
import Advantages from "@/components/layout/Advantages";
import FooterPromo from "@/components/layout/footer/FooterPromo.vue";
import router from "@/router";

export default {
  name: "TradeCommodities",
  components: {
    Advantages,
    FooterPromo,
    WhyUsPromo
  },
  methods: {
    redirectToRegistration: function() {
      router.push("/registration");
    }
  }
};
</script>
