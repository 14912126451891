<template>
    <div class="register-area pt-5">
      <div class="container">
        <div class="row g-4 g-lg-5 align-items-center justify-content-center">
          <div class="col-12 col-md-6 col-xl-6">
            <div class="register-card text-center">
              <h1>Your email successefully verified!</h1>
              <h2>{{ message }}</h2>
              <p>You can try to <router-link class="ms-1 hover-primary" to="/login">Log In</router-link></p>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import router from "@/router";

export default {
  name: "Registration",
  components: {
  },
  data() {
    return {
        message: '',
    };
  },  
  mounted() {
    const splittedPath = window.location.pathname.split('/');
    const token = splittedPath[splittedPath.length - 1];
    token.length === 60 && this.$store.dispatch("isEmailVerificationTokenValid", token).then((message) => this.message = message);
  }
};
</script>

<style scoped>
.reg-logo {
  max-width: 50%;
}

.v-select.form-control {
  padding: 0 !important;
  height: auto;
  margin-bottom: 16px;
}

/deep/.v-select.form-control > div {
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 7px;
  padding-bottom: 7px;

}

.text-left {
  text-align: left;
}

.lg-text {
  font-size: 25px;
}

#registerPassword,
#registerPasswordConfirm {
  color: #c2d4f8 !important;
  border-color: rgba(194, 212, 248, 0.15) !important;
  background-color: #0C153B !important;
  -webkit-tap-highlight-color: #0C153B !important;
}
</style>