<template>
  <div class="row middle-lg licensing-block">
    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
      <img src="~@/assets/main-page/fsma_afm.jpg" />
    </div>
    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
      <div class="row title">
        {{ $t("main.licensing.title") }}
      </div>
      <div class="row block-description">
        {{ $t("main.licensing.text") }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Licensing"
};
</script>

