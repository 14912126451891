<template>
  <div class="faq-tabs learn-nav">
    <tabs class="row">
      <tab
        :name="this.$i18n.messages[this.$i18n.locale].faq.account.title"
        @click="selectedTab = 'Account'"
        :selected="selectedTab === 'Account'"
      >
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <div class="row">
            <h2>{{ $t("faq.account.title") }}</h2>
            <div
              v-for="item in this.$i18n.messages[this.$i18n.locale].faq.account
                .items"
            >
              <h3>{{ item.title }}</h3>
              <p>{{ item.text }}</p>
            </div>

            <!--  <h3>In which currencies can i open an account?</h3>
            <p>
              With CME Investment Firm B.V. you can open a live account in USD
              ($)
            </p>
            <h3>How do i fund my account?</h3>
            <p>
              You can make your first deposit using a debit card, credit card,
              Bank Wire Transfer, Bitcoin as your payment method.
            </p>
            <h3>Is my money secure?</h3>
            <p>
              Yes, your money is secure. CME Investment Firm B.V. is a licensed
              and regulated by AFM and FSMA that keeps its clients’ money in
              separate bank accounts. In addition, we have introduced a
              compensation scheme as required by our regulator.
            </p>
            <h3>Why do I have to provide proof of Identity?</h3>
            <p>
              CME-invest.nl’s regulators, AFM and FSMA require us to identify
              every client who opens an account with us.
            </p>
            <h3>Do you keep my personal data safe?</h3>
            <p>
              CME-invest.nl guarantees the full protection of your personal
              information. It is held safe by us and is only shared with our
              regulator the AFM and FSMA. In no event do we disclose or sell
              your data to additional parties.
            </p>
            <h3>What is the minimum deposit?</h3>
            <p>
              The minimum deposit is 250 USD. However, please note that your
              trading flexibility depends on the amount you have in your
              balance. The higher the balance, the more successful and safer
              your trade.
            </p>
            <h3>How can I withdraw money from my account?</h3>
            <p>
              For security reasons, we return money by the same payment method
              you used to fund your account initially. This means, If you used a
              credit/debit card, we will transfer money back to the card you
              used. Similar situation with other payment methods. Funds are
              withdrawn within 5 business days.
            </p>
            <h3>Can I have more than one account?</h3>
            <p>
              No, there is a limit of one account per person at CME-invest.nl.
            </p>
            <h3>How do I open an account?</h3>
            <p>
              To open an account and begin trading, you should fill out our
              brief registration form and make a deposit. As a AFM and FSMA
              regulated investment firm, we need this info to verify your
              identity and guarantee your security when trading with us. Once
              all the required documents are uploaded and verified, you can
              access the markets and resume trading.
            </p>
            <h3>Do you provide support?</h3>
            <p>
              We have a team of friendly and considerate support managers, which
              ready to answer all your questions on our service. Use
              support@cme-invest.nl to contact them. We will reply to your email
              within 24 hours.
            </p>
            <h3>How can I restore my password?</h3>
            <p>
              If you’ve forgotten your password, use the ‘Forgot password?’ link
              on the login page. Then check your email address associated with
              your cme-invest.nl account for further instructions.
            </p>
            <h3>How can I change my password?</h3>
            <p>
              If you want to change your password, log in to your account, go to
              Settings, and use the ‘Change password’ option.
            </p> -->
          </div>
        </div>
      </tab>
      <tab
        :name="
          this.$i18n.messages[this.$i18n.locale].faq.address_verification.title
        "
        @click="selectedTab = 'Verification'"
        :selected="selectedTab === 'Verification'"
      >
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <div class="row">
            <!-- <h2>ID and Address Verification</h2> -->
            <h2>{{ $t("faq.address_verification.title") }}</h2>
            <div
              v-for="item in this.$i18n.messages[this.$i18n.locale].faq
                .address_verification.items"
            >
              <h3>{{ item.title }}</h3>
              <p>{{ item.text }}</p>
            </div>
            <!-- <h3>What kind of documents can I provide to prove my identity?</h3>
            <p>
              As a proof of identity, you can upload a photo/scan of one the
              following documents: Your passport (the photo page) Your national
              ID document (front and back) Your driver’s licence (front and
              back)
            </p>
            <h3>Are there any requirements for the proof of ID documents?</h3>
            <p>
              the document should be valid and contain your full name and date
              of birth. All the four sides of the photo/scan should be visible
              and the image should be clear.
            </p>
            <h3>What kind of documents can I provide to prove my address?</h3>
            <p>
              As a proof of address, you can upload a photo/scan of one of the
              following documents: bank statement, credit card statement,
              household utility bill for electricity, water, gas, tv or your
              landline A tax statement or local authority tax bill.
            </p>
            <h3>
              Are there any requirements for the proof of address documents?
            </h3>
            <p>
              The document should be issued within the last 3 months and contain
              your full name and address. All the four sides of the photo/scan
              should be visible and the text should be readable.
            </p>
            <h3>
              What is needed to verify a bank card?
            </h3>
            <p>
              It is necessary to make a photo / scan of a bank card on both
              sides. On the front side of the card, you must hide the first 6
              and last 4 digits of the card number. On the back of the card you
              need to hide the CVV/CVC code.
            </p> -->
          </div>
        </div>
      </tab>
      <tab
        :name="this.$i18n.messages[this.$i18n.locale].faq.trading.title"
        @click="selectedTab = 'Trading'"
        :selected="selectedTab === 'Trading'"
      >
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <div class="row">
            <h2>{{ $t("faq.trading.title") }}</h2>
            <div
              v-for="item in this.$i18n.messages[this.$i18n.locale].faq.trading
                .items"
            >
              <h3>{{ item.title }}</h3>
              <p>{{ item.text }}</p>
            </div>
            <!-- <h2>Trading</h2>
            <h3>What markets can I trade?</h3>
            <p>
              At CME-invest.nl you can trade Contracts for Difference (CFDs) on
              stocks (like Apple), commodities (like Gold), indices (like the
              DAX30), currency pairs (like EUR/USD) and cryptocurrencies (like
              Bitcoin).
            </p>
            <h3>Do financial markets have different trading hours?</h3>
            <p>
              The availability of each market is dependant on the trading hours
              of the exchange it is listed on.
            </p>
            <h3>What are the units used to measure different markets?</h3>
            <p>
              Below are measurement units for various markets: Gold, Silver,
              Palladium, Platinum – USD per troy ounce, Oil – USD per barrel,
              Gas – USD per 1 million British Thermal Units (MMBTU), Stocks –
              USD per share, Indices – USD per contract.
            </p>
            <h3>Can the value of markets exceed the funds in my account?</h3>
            <p>
              Yes. CME-invest.nl offers leveraged trading, in other words, it
              allows you to trade with more funds than you have in your account.
              However, leveraged trading is extremely risky. Read more about the
              business model we use.
            </p>
            <h3>Do you provide real-time prices?</h3>
            <p>
              Yes, you can open trades at the prices you see in the real time.
            </p>
            <h3>
              Do all brokerages offer the same price for a particular market?
            </h3>
            <p>
              No, sometimes there is a slight difference between the prices
              because brokerages have different business models.
            </p>
            <h3>Can I trade via my mobile phone?</h3>
            <p>
              CME-invest.nl provides mobile trading for iOS and Android devices.
            </p>
            <h3>Do you charge fees for trading?</h3>
            <p>
              Сommission averages 0.2% of the amount of investment in the
              transaction and is already included in the spread
            </p>
            <h3>How many markets do you offer?</h3>
            <p>
              We offer 1000+ markets at the moment.
            </p> -->
          </div>
        </div>
      </tab>
      <tab
        :name="
          this.$i18n.messages[this.$i18n.locale].faq.deposit_withdrawal.title
        "
        @click="selectedTab = 'Deposit'"
        :selected="selectedTab === 'Deposit'"
      >
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <div class="row">
            <h2>{{ $t("faq.deposit_withdrawal.title") }}</h2>
            <div
              v-for="item in this.$i18n.messages[this.$i18n.locale].faq
                .deposit_withdrawal.items"
            >
              <h3>{{ item.title }}</h3>
              <p>{{ item.text }}</p>
            </div>
            <!-- <h2>Deposit & Withdrawal Details</h2>
            <h3>How can I add funds to my account?</h3>
            <p>
              You can do it by debit card, credit card, Bank Wire Transfer,
              Bitcoin. Just tap the ‘Deposit’ button to fund your account.
            </p>
            <h3>
              I’d like to withdraw money from my account. How long will it take?
            </h3>
            <p>
              We transfer money back to your credit/debit card within 5 business
              days. Please note that the time between the transfer and receiving
              funds in your account may vary slightly due to your bank’s
              operating procedures.
            </p>
            <h3>How do I withdraw money from my account?</h3>
            <p>
              So that you can withdraw funds from your account, go to the
              "Withdraw" tab in your account. Then select the method that you
              have already used to deposit money and click "Confirm" after
              entering the withdrawal amount. After that, your request will be
              processed and will be completed within 5 business days.
            </p>
            <h3>Do I need to fund my account to start trading?</h3>
            <p>
              As soon as you have successfully registered an account, you can
              sign in and activate your account. However , in order to start
              trading, you need to deposit funds in your account and than verify
              your documents. Before making large-scale investments, you can
              start with the minimum deposit.
            </p>
            <h3>What is the minimum deposit?</h3>
            <p>
              The minimum deposit is 250 USD. However, please note that your
              trading flexibility depends on the amount you have in your
              balance. The higher the balance, the more successful and safer
              your trade.
            </p>
            <h3>Are there any payment limits? If yes, what are they?</h3>
            <p>
              Limits may be on the part of the payment systems that you use.
              CME-invest.nl does not limit the ability to deposit and withdraw
              funds for its customers
            </p> -->
          </div>
        </div>
      </tab>
      <tab
        :name="this.$i18n.messages[this.$i18n.locale].faq.orders_trades.title"
        @click="selectedTab = 'Orders'"
        :selected="selectedTab === 'Orders'"
      >
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <div class="row">
            <h2>{{ $t("faq.orders_trades.title") }}</h2>
            <div
              v-for="item in this.$i18n.messages[this.$i18n.locale].faq
                .orders_trades.items"
            >
              <h3>{{ item.title }}</h3>
              <p>{{ item.text }}</p>
            </div>
            <!-- <h2>Market Orders and Trades</h2>
            <h3>What is a market order?</h3>
            <p>
              A market order is an instruction to buy or sell a CFD, in a
              specified size, at the best available market price for that size.
              It is important to note that a market order can be executed at a
              price different from quoted at the time it is placed. Once
              executed, a market order immediately becomes an open trade that
              can be watched in the ‘Opening trades’ tab. A market order can
              have Take Profit/Stop Loss Orders attached.
            </p>
            <h3>How long can I keep my trade open?</h3>
            <p>
              If you have enough money on your account, you can keep your trades
              open for as long as you want. Please note that for some long and
              large orders, a commission (swap) is possible.
            </p>
            <h3>What should I know about margin trading?</h3>
            <p>
              Trading on margin means to trade securities using funds borrowed
              from a broker. To trade on margin, you have to open a margin
              account and deposit a certain amount of money, which will
              literally serve as a collateral for a loan. Margin trading offers
              you an exposure to bigger trades and profits, but involves greater
              risks as well.
            </p>
            <h3>What is margin call?</h3>
            <p>
              A margin call is sent when the ratio between your equity and your
              required margin fails to meet our requirements. A margin call is a
              key risk management tool preventing your losses from piling up. If
              your capital falls below a critical margin level, you will be
              notified of the need to add funds to the balance sheet to avoid
              closing all orders and losing funds invested in the asset.
            </p> -->
          </div>
        </div>
      </tab>
      <tab
        :name="this.$i18n.messages[this.$i18n.locale].faq.pending_orders.title"
        @click="selectedTab = 'Pending'"
        :selected="selectedTab === 'Pending'"
      >
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <div class="row">
            <h2>{{ $t("faq.pending_orders.title") }}</h2>
            <div
              v-for="item in this.$i18n.messages[this.$i18n.locale].faq
                .pending_orders.items"
            >
              <h3>{{ item.title }}</h3>
              <p>{{ item.text }}</p>
            </div>
            <!-- <h2>Pending Orders</h2>
            <h3>What is a pending order?</h3>
            <p>
              A pending order is an instruction to open or close a trade if the
              price reaches, or crosses the level that you have specified
              earlier. A pending order therefore becomes an open order, once it
              is triggered. This is especially useful when you don’t have time
              to constantly watch the market. Just decide a price you wish to
              trade at, and the system will do the rest.
            </p>
            <h3>What is a limit order?</h3>
            <p>
              A limit order is an instruction to buy a CFD at no more than a
              specific price, or to sell it at no less than a specific price.
              This gives you control over the price at which the limit order is
              executed.
            </p>
            <h3>What is a take profit order?</h3>
            <p>
              A take profit order automatically closes an open trade when the
              price reaches a specified threshold. Take profit orders are used
              to lock in profits when you are unavailable to monitor your open
              trades. For example, if you buy shares of company XYZ at 109.58
              and you want to take your profit when the price reaches 110.00,
              you can set this price as your take profit threshold. If the bid
              price reaches 110.00, the open trade is closed by the system and
              your profit is secured.
            </p>
            <h3>What is a stop loss order?</h3>
            <p>
              A stop loss order can help you protect against unnecessarily large
              losses, and therefore manage your risk effectively. A stop loss
              automatically closes an open trade if the price moves against you
              and reaches the level you specify. For example, if you buy shares
              of company XYZ at 109.58, you could set a stop loss at 107.00 –
              then, if the bid price falls to this level, the trade is
              automatically closed, thereby capping your losses.
            </p>
            <h3>Why has my pending order not been executed?</h3>
            <p>The price which you specified for the order was not reached.</p>
            <h3>Can I set both profit and loss amounts for my open trade?</h3>
            <p>
              Yes. You can determine both the profit you want to reach and the
              loss you can afford. Your trade will be closed out if one of these
              parameters is reached.
            </p> -->
          </div>
        </div>
      </tab>
      <tab
        :name="this.$i18n.messages[this.$i18n.locale].faq.profit.title"
        @click="selectedTab = 'Profit'"
        :selected="selectedTab === 'Profit'"
      >
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <div class="row">
            <h2>{{ $t("faq.profit.title") }}</h2>
            <div
              v-for="item in this.$i18n.messages[this.$i18n.locale].faq.profit
                .items"
            >
              <h3>{{ item.title }}</h3>
              <p>{{ item.text }}</p>
            </div>
            <!-- <h2>Profit</h2>
            <h3>In which currency do I receive profit from my orders?</h3>
            <p>Your profit is converted into your account currency(USD)</p>
            <h3>
              I don’t have any trading ideas. Can you recommend a trade for me
              to open?
            </h3>
            <p>
              CME-invest.nl doesn’t provide any investment advice. To gain more
              knowledge, please, visit tab “Learn to trade”.
            </p>
            <h3>Is my profit considered a taxable income?</h3>
            <p>
              It depends upon the regulations imposed by your payment method and
              by your local authorities.
            </p> -->
          </div>
        </div>
      </tab>
      <tab
        :name="this.$i18n.messages[this.$i18n.locale].faq.loss.title"
        @click="selectedTab = 'Loss'"
        :selected="selectedTab === 'Loss'"
      >
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <div class="row">
            <h2>{{ $t("faq.loss.title") }}</h2>
            <div
              v-for="item in this.$i18n.messages[this.$i18n.locale].faq.loss
                .items"
            >
              <h3>{{ item.title }}</h3>
              <p>{{ item.text }}</p>
            </div>
            <!-- <h2>Loss</h2>
            <h3>Can I lose more than I invested?</h3>
            <p>
              No. CME-invest.nl ensures you a Negative Balance Protection so
              that your balance won’t drop below zero.
            </p>
            <h3>Why has my trade been closed out?</h3>
            <p>
              To prevent you from incurring a negative balance, CME-invest.nl
              can partially or fully close your trade if your account equity
              doesn’t meet the margin requirement, and/or we don’t hear from you
              for a while. Please make sure you maintain adequate capital in
              your account to keep your trades open.
            </p>
            <h3>Can I somehow limit my loss?</h3>
            <p>
              Yes. Just set a stop loss order. In this way, you will be able to
              manage your risk and lose no more than you can afford.
            </p> -->
          </div>
        </div>
      </tab>
      <tab
        :name="this.$i18n.messages[this.$i18n.locale].faq.other.title"
        @click="selectedTab = 'Other'"
        :selected="selectedTab === 'Other'"
      >
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <div class="row">
            <h2>{{ $t("faq.other.title") }}</h2>
            <div
              v-for="item in this.$i18n.messages[this.$i18n.locale].faq.other
                .items"
            >
              <h3>{{ item.title }}</h3>
              <p>{{ item.text }}</p>
            </div>
            <!-- <h2>Other</h2>
            <h3>I want to make a complaint. What are my next steps?</h3>
            <p>
              Please contact our support and your problem will be resolved as
              soon as possible.
            </p>
            <h3>Who is your regulator?</h3>
            <p>
              CME-invest.nl is registered in Netherlands, (Nieuwezijds
              Voorburgwal 104 Units 1.04,, Amsterdam, 1012SG, Netherlands) with
              company registration number 859089824 and Legal Entity Identifier
              Code - 213800UC9Y217JGUQ791. Authorised and regulated by AMF and
              FSMA.
            </p> -->
          </div>
        </div>
      </tab>
    </tabs>
    <div class="row">
      <footer-promo></footer-promo>
    </div>
  </div>
</template>

<script>
import FooterPromo from "@/components/layout/footer/FooterPromo.vue";
import Tabs from "@/components/layout/Tabs.vue";
import Tab from "@/components/layout/Tab.vue";

export default {
  name: "FAQ",
  components: {
    FooterPromo,
    Tabs,
    Tab
  },
  data() {
    return {
      selectedTab: "Account"
    };
  },
  created() {
    if (this.$route.query.tab === "loss") {
      this.selectedTab = "Loss";
    }
  }
};
</script>
