<template>
  <div class="middle-lg middle-md middle-sm middle-xs promo">
    <div class="row why-us-promo">
      <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">
        <span class="question"
          ><span class="first-word">{{ $t("main.why") }}</span>
          {{ $t("main.site_name") }}?</span
        >
      </div>
      <div class="col-lg-9 col-md-9 col-sm-12 col-xs-12">
        <div class="icon-box">
          <img src="~@/assets/why-us/investment.png" />
          <span>{{ $t("main.why_us_promo.text1") }}</span>
        </div>

        <div class="icon-box">
          <img src="~@/assets/why-us/coins.png" />
          <span>{{ $t("main.why_us_promo.text2") }}</span>
        </div>

        <div class="icon-box">
          <img src="~@/assets/why-us/graph.png" />
          <span>{{ $t("main.why_us_promo.text3") }}</span>
        </div>

        <button class="promo-button" @click="redirectToRegistration()">
          {{ $t("main.start_tarding_text") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import router from "@/router";
export default {
  name: "WhyUsPromo",
  methods: {
    redirectToRegistration: function() {
      router.push("/registration");
    }
  }
};
</script>
