<template>
<div class="container">
  <FlashMessage></FlashMessage>
  <div class="row g-4 justify-content-center">
    <div class="col-12 col-lg-10 col-xl-9 col-xxl-8">
      <div class="tab-content">
        <div class="tab-pane fade show active" id="tab1" role="tabpanel" aria-labelledby="tab--1">
          <div class="card">
            <div class="card-body p-2 p-sm-4">
              <form action="#">
                <div class="row g-4" v-show="!isEditProfile">
                  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 bl-title">
                    {{ $t("dashboard.personal_account") }}
                  </div>
                  <div class="col-12">
                    <div class="form-control  border-0 row">
                      <div class="col-lg-4 align-items-center  align-items-center">
                        <span>{{ $t("dashboard.first_name") }}</span>
                      </div>
                      <div class="col-lg-7 col-12  align-items-center">
                        {{ user.first_name }}
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-control  border-0 row">
                      <div class="col-lg-4  align-items-center">
                          <span>{{ $t("dashboard.last_name") }}</span>
                      </div>
                      <div class="col-lg-7 col-12  align-items-center">
                          {{ user.last_name }}
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-control  border-0 row">
                      <div class="col-lg-4 align-items-center">
                        <span>{{ $t("dashboard.email") }}</span>
                      </div>
                      <div class="col-lg-7 col-12  align-items-center">
                        {{ user.email }}
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-control  border-0 row">
                      <div class="col-lg-4 align-items-center">
                        <span>{{ $t("dashboard.tel_number") }}</span>
                      </div>
                      <div class="col-lg-7 col-12  align-items-center">
                        {{ user.phone }}
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-control  border-0 row" >
                      <div class="col-lg-4 align-items-center">
                        <span>{{ $t("dashboard.country") }}</span>
                      </div>
                      <div class="col-lg-7 col-12  align-items-center">
                         {{ user.country.name }}
                      </div>
                    </div>        
                  </div>
                  <div class="col-12">
                    <div class="form-control  border-0 row">
                      <div class="col-lg-4 align-items-center">
                        <span>{{ $t("dashboard.account_id") }}</span>
                      </div>
                      <div class="col-lg-7 col-12  align-items-center">
                        {{ hash }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row g-4" v-show="isEditProfile">
                  
                  <div class="form-group" :class="{ 'form-group--error': $v.profileInfo.first_name.$error }">
                    <label class="form__label">First Name</label>
                    <input class="form__input" v-model.trim="$v.profileInfo.first_name.$model"/>
                  </div>
                  <div class="error" v-if="$v.profileInfo.first_name.$dirty && !$v.profileInfo.first_name.required">Name is required.</div>
                  <div class="error" v-if="$v.profileInfo.first_name.$dirty && !$v.profileInfo.first_name.minLength">Name must have at least {{ $v.profileInfo.first_name.$params.minLength.min }} letters.</div>

                  <div class="form-group" :class="{ 'form-group--error': $v.profileInfo.last_name.$error }">
                    <label class="form__label">Last Name</label>
                    <input class="form__input" v-model.trim="$v.profileInfo.last_name.$model"/>
                  </div>
                  <div class="error" v-if="$v.profileInfo.last_name.$dirty && !$v.profileInfo.last_name.required">Name is required.</div>
                  <div class="error" v-if="$v.profileInfo.last_name.$dirty && !$v.profileInfo.last_name.minLength">Name must have at least {{ $v.profileInfo.last_name.$params.minLength.min }} letters.</div>

                  <div class="col-12">
                    <input class="form-control bg-gray border-0 " v-model="profileInfo.email" disabled :placeholder="$t('dashboard.email')"/>
                  </div>

                  <div class="col-12">
                    <input class="form-control bg-gray border-0 " v-model="profileInfo.phone" disabled :placeholder="$t('dashboard.tel_number')"/>
                  </div>

                  <div class="col-12">
                    <v-select
                      class="form-control bg-gray border-0 "
                      label="name"
                      v-model="selectedCountry"
                      :options="countriesList"
                      :searchable="false"
                      :filterable="false"
                      item-text="name"
                      item-value="id"
                    ></v-select>
                  </div>
                </div>
                <div class="col-12 mt-4" v-show="!isEditProfile">
                  <button 
                    @click="editProfileInfo()" 
                    name="EditInfo" 
                    class="btn btn-primary w-100 rounded-pill" 
                    type="button"><i class="bi bi-sd-card-fill me-1"></i>Edit
                  </button>
                </div>
                <div
                  class="col-12 mt-4"
                  v-show="isEditProfile"
                >
                <div class="d-flex">
                  <button
                    class="btn btn-primary w-100 rounded-pill"
                    type="button"
                    name="Save"
                    @click="saveProfileInfo()"
                  >
                    {{ $t("dashboard.save_info") }}
                  </button>
                  <button
                    class="btn btn-primary w-100 rounded-pill"
                    type="button"
                    name="Cancel"
                    @click="cancelEditProfileInfo()"
                  >
                    {{ $t("dashboard.cancel") }}
                  </button>
                </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>          
  <div class="row g-4 justify-content-center mt-2">
    <div class="col-12 col-lg-10 col-xl-9 col-xxl-8">
      <div class="tab-content">
        <div class="tab-pane fade show active" id="tab1" role="tabpanel" aria-labelledby="tab--1">
          <div class="card">
            <div class="card-body p-4 p-sm-5">
              <div class="row g-4">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 bl-title">
                  {{ $t("dashboard.verification") }}
                </div>
                <div class="row g-4">
                  <div v-show="user.status !== 'verified' && user.is_docs_uploaded !== 1">
                    <div
                      v-if="isContinueUpload === false && !successUploaded"  
                    >
                      <div class="tab-content">
                        <div class="tab-pane fade show active" id="tab1" role="tabpanel" aria-labelledby="tab--1">
                          <div class="notification-content-wrap">
                            <ul class="notification-list ps-0 mb-0">
                              <li><a><p> To verify your trading account, you need to upload following documents: </p></a></li>
                              <li><a><i class="bg-warning me-1 bi bi-star"></i><p> Photo or scan copy of passport</p></a></li>
                              <li><a><i class="bg-warning me-1 bi bi-star"></i><p> Bank account statement or passport page with details of the place of registration </p></a></li>
                              <li><a><i class="bg-warning me-1 bi bi-star"></i><p> Photo or scan copy of the front side of your bank card</p></a></li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      v-if="successUploaded || user.is_docs_uploaded === 1"
                    >
                      <p class="lg-text">
                        {{ $t("dashboard.upload_successfully") }}
                      </p>
                    </div>
                    <div
                      v-if="isContinueUpload === false && !successUploaded"
                    >
                      <button
                        class="btn btn-primary w-100 rounded-pill mt-3"
                        id="continue-upload"
                        type="button"
                        name="upload"
                        @click="isContinueUpload = true"
                      >
                        {{ $t("dashboard.continue_upload") }}
                      </button>
                    </div>
                    <div
                      v-if="isContinueUpload && !successUploaded"
                    >
                      <div class="row center-lg">
                        <vue-upload-multiple-image
                          @upload-success="uploadImageSuccess"
                          @before-remove="beforeRemove"
                          @edit-image="editImage"
                          :data-images="images"
                          idUpload="myIdUpload"
                          editUpload="myIdEdit"
                          :maxImage="6"
                          :showPrimary="false"
                          dragText="Drop images here"
                          markIsPrimaryText=""
                          browseText=""
                          primaryText=""
                          popupText=""
                          dropText=""
                        ></vue-upload-multiple-image>
                      </div>
                    </div>
                    <div
                      v-if="isContinueUpload && !successUploaded"
                    >
                      <button class="btn btn-primary w-100 rounded-pill mt-3" type="button" name="upload" @click="uploadImages()">
                        {{ $t("dashboard.upload") }}
                      </button>
                    </div>
                  </div>
                  <div
                    v-show="user.status === 'verified' || user.is_docs_uploaded === 1"
                  >
                    <div>
                      <span class="lg-text">{{ $t("dashboard.profile_varified_successfully") }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              </div>
            </div>
        </div>
      </div>
    </div>

  <div class="row g-4 justify-content-center  mt-2">
    <div class="col-12 col-lg-10 col-xl-9 col-xxl-8">
      <div class="tab-content">
        <div class="tab-pane fade show active" id="tab1" role="tabpanel" aria-labelledby="tab--1">
          <div class="card">
            <div class="card-body p-4 p-sm-5">
              <form action="#">
                <div class="row g-4">
                  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 bl-title">
                    {{ $t("dashboard.change_password") }}
                  </div>
                  <div class="col-12">
                    
                    <div class="form-group full-w" :class="{ 'form-group--error': $v.oldPassword.$error }">
                      <label class="form__label">Current Password</label>
                      <input class="form__input" v-model.trim="$v.oldPassword.$model"/>
                    </div>
                    <div class="error" v-if="$v.oldPassword.$dirty && !$v.oldPassword.required">Password is required.</div>
                    <div class="error" v-if="$v.oldPassword.$dirty && !$v.oldPassword.minLength">Password must have at least {{ $v.oldPassword.$params.minLength.min }} letters.</div>
                    
                    <div class="form-group full-w" :class="{ 'form-group--error': $v.newPassword.$error }">
                      <label class="form__label">New Password</label>
                      <input class="form__input" v-model.trim="$v.newPassword.$model"/>
                    </div>
                    <div class="error" v-if="$v.newPassword.$dirty && !$v.newPassword.required">New Password is required.</div>
                    <div class="error" v-if="$v.newPassword.$dirty && !$v.newPassword.minLength">Password must have at least {{ $v.newPassword.$params.minLength.min }} letters.</div>
                    <div class="error" v-if="$v.newPassword.$dirty && $v.newPassword.sameAsPassword">New Password can't be the same as old.</div>
                    
                    <div class="form-group full-w" :class="{ 'form-group--error': $v.repeatedPassword.$error }">
                      <label class="form__label">Repeat New Password</label>
                      <input class="form__input" v-model.trim="$v.repeatedPassword.$model"/>
                    </div>
                    <div class="error" v-if="$v.repeatedPassword.$dirty && !$v.repeatedPassword.sameAsPassword">Passwords must be identical.</div>
                  </div>

                  <button class="btn btn-primary w-100 rounded-pill mt-4" type="button" name="confirm" @click="sendPassword()">
                    {{ $t("dashboard.confirm") }}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row g-4 justify-content-center  mt-2">
    <div class="col-12 col-lg-10 col-xl-9 col-xxl-8">
      <div class="tab-content">
        <div class="tab-pane fade show active" id="tab1" role="tabpanel" aria-labelledby="tab--1">
          <div class="card">
            <div class="card-body p-4 p-sm-5">
              <div class="register-form mt-5">
              <form action="#">
                  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 bl-title">
                    {{ $t("dashboard.wallet_number") }}
                  </div>
                  <div class="form-group mb-4" v-if="clientWallet === ''">
                    <label class="label-psswd" for="registerPassword" @click="togleInputView">Show</label>
                    <input :type="isInputView" id="registerPassword" class="form-control" v-model="wallet" autocomplete="off"/>
                  </div>
                  <button v-if="clientWallet === ''" class="btn btn-primary w-100 rounded-pill mt-4" type="button" name="confirm" @click="sendWallet()">
                    {{ $t("dashboard.confirm") }}
                  </button>
                  <div class="form-group mb-4" v-if="clientWallet !== ''">
                    <label class="label-psswd" for="registerPasswordConfirm" @click="togleInputView">Show</label>
                    <input class="form-control" :type="isInputView" id="registerPasswordConfirm" v-model="clientWallet" autocomplete="off" disabled/>
                  </div>

              </form>
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
/* eslint-disable */
import { mapState } from "vuex";
import "vue-select/dist/vue-select.css";
import vSelect from "vue-select";
import VueUploadMultipleImage from "vue-upload-multiple-image";
import { required, sameAs, minLength, email, numeric, alpha } from 'vuelidate/lib/validators';

export default {
  name: "PersonalInfo",
  components: {
    vSelect,
    VueUploadMultipleImage
  },
  computed: {
    ...mapState(["user", "countriesList", "clientWallet"]),
    hash() {
      const simpleHash = str => {
        let hash = 0;
        for (let i = 0; i < str.length; i++) {
          const char = str.charCodeAt(i);
          hash = (hash << 5) - hash + char;
          hash &= hash; // Convert to 32bit integer
        }
        return new Uint32Array([hash])[0].toString(36);
      };
      return simpleHash("12000600900000" + this.user.id);
    }
  },
  data() {
    return {
      isContinueUpload: false,
      previewImage: null,
      selectedCountry: {},
      oldPassword: "",
      newPassword: "",
      repeatedPassword: "",
      isEditProfile: false,
      changePassword: {},
      profileInfo: {
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        country_id: ""
      },
      identityImages: [],
      images: [],
      successUploaded: false,
      wallet: "",
      isInputView: 'password'
    };
  },
  validations: {
    profileInfo: {
      first_name: {
        required,
        alpha,
        minLength: minLength(2)
      },
      last_name: {
        required,
        alpha,
        minLength: minLength(2)
      },
    },
    newPassword: {
      required,
      minLength: minLength(6),
      sameAsPassword: sameAs('oldPassword')
    },
    repeatedPassword: {
      sameAsPassword: sameAs('newPassword')
    },
    oldPassword: {
      required,
      minLength: minLength(6)
    }
  },
  created() {
    this.$store.dispatch("getCountries");
    this.$store.dispatch("getWallet");
    this.profileInfo.first_name = this.user.first_name;
    this.profileInfo.last_name = this.user.last_name;
    this.profileInfo.email = this.user.email;
    this.profileInfo.phone = this.user.phone;
    this.profileInfo.country_id = this.user.country_id;
  },

  methods: {
    
    editProfileInfo: function() {
      this.isEditProfile = !this.isEditProfile;
    },
    saveProfileInfo: function() {
      if (
        !this.profileInfo.email ||
        !this.profileInfo.first_name ||
        !this.profileInfo.last_name ||
        !this.selectedCountry.id ||
        !this.profileInfo.phone ||
        this.profileInfo.email.length < 6 ||
        this.profileInfo.phone.length < 9
      ) {
        this.flashMessage.show({
          status: 'error',
          title: 'The Profile Form is Uncompleted!',
          message: 'Please, check all fields before form submitting!'
        });
        return;
      }
      this.profileInfo.country_id = this.selectedCountry.id;
      this.$store.dispatch("updateUserProfile", this.profileInfo).then(() => {
        this.isEditProfile = false;
        this.flashMessage.show({
          status: 'success',
          title: 'Updated!',
          message: 'Administration of the platform can ask you for aditional verification and documents after update!'
        });
      });
    },
    cancelEditProfileInfo: function() {
      this.editProfileInfo();
      this.profileInfo = {};
    },
    sendWallet(){
      if(this.wallet === ""){
        return
      }

      this.$store.dispatch("updateWallet", { wallet: this.wallet }).then(() =>{
        this.flashMessage.show({
          status: 'success',
          title: 'Wallet is updated successefull!',
          message: 'If you need to update number, write to support'
        });
      }).catch((res) => {
        this.flashMessage.show({
          status: 'error',
          title: 'Form data not correct!',
          message: 'Try to check form for additional error messages!'
        })
      });
    },
    sendPassword() {
      /* eslint-disable no-console */
      if (
        !this.oldPassword ||
        !this.newPassword ||
        !this.repeatedPassword ||
        this.newPassword.length < 6 ||
        this.newPassword != this.repeatedPassword
      ) {
        this.flashMessage.show({
          status: 'error',
          title: 'Change Password is Unsuccessefull!',
          message: 'Please, check form errors and try to resend!'
        });
        return;
      }
      
      this.changePassword.old_password = this.oldPassword;
      this.changePassword.password = this.newPassword;
      this.changePassword.password_confirmation = this.repeatedPassword;

      this.$store.dispatch("updateUserPassword", this.changePassword).then(() =>{
        this.flashMessage.show({
          status: 'success',
          title: 'Password is updated successefull!',
          message: 'Please, login next time with new password!'
        });
      }).catch((res) => {
        res.status !== 500 ? this.flashMessage.show({
          status: 'error',
          title: 'Form data not correct!',
          message: 'Try to check form for additional error messages!'
        }) : this.flashMessage.show({
          status: 'success',
          title: 'Password is updated successefull!',
          message: 'Please, login next time with new password!'
        })
      });
    },
    // formData, index, fileList
    uploadImageSuccess(formData) {
      this.identityImages.push([...formData]);
    },
    // index, done, fileList
    beforeRemove(index, done) {
      var r = confirm("remove image");
      if (r == true) {
        done();
      }
    },
    editImage() {
      // console.log('edit data', formData, index, fileList)
    },
    uploadImages() {
      this.identityImages.forEach(image => {
        this.$store
          .dispatch("uploadProfileIdentity", {
            subdirectory: "passport",
            document: image[0][1]
          })
          .then(() => {
            this.$store
              .dispatch("saveProfileIdentities", {
                subdirectory: "passport"
              })
              .then(() => {
                this.successUploaded = true;
                this.$store.dispatch("updateUserData");
              });
          });
      }).then(() => {

      });
    },
    togleInputView(){
      if(this.isInputView == "password"){
        this.isInputView = "text"
      }else {
        this.isInputView = "password"
      }
    }
  }
};
</script>
<style scoped>
.full-w > .form__label {
  width: 100%;
}

.form__input {
  color: #8480ae;
  border-radius: 0.75rem;
  background-color: #242135;
  padding: 5px 10px;
  border: none;
  outline: none;
}

.full-w > .form__input {
  width: 100%;
  margin-top: 10px;
}

/deep/._vue-flash-msg-body {
  z-index: 999;
}
  .border-0 {
    padding-bottom: 60px;
  }
@media(min-width: 992px){
  .border-0 {
    padding-bottom: 10px;
  }

  .border-0 > div {
    display: inline-block;
  }
}
.notification-list p {
  margin-bottom: 0;
}

.lg-text {
  font-size: 25px;
}

img{
  min-width: 60px;
}
</style>