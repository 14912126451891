<template>
  <div class="row content">
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <div class="row ticker-carousel" id="ticker-carousel">
        <vueper-slides
          fixed-height="64px"
          :arrows="false"
          :bullets="false"
          class="no-shadow"
          :visible-slides="4"
          :slide-ratio="1 / 7"
          :dragging-distance="30"
          autoplay
        >
          <vueper-slide
            v-for="(slide, i) in renderSymbols()"
            :key="i"
            :title="slide.front_view"
          >
            <template v-slot:content>
              <div class="ticker">
                <span class="label Count"
                  >{{ slide.front_view }} {{ slide.price }} </span
                ><span
                  v-bind:class="[
                    parseFloat(slide.change) > 0
                      ? 'change-plus'
                      : 'change-minus'
                  ]"
                  >{{ slide.change }}</span
                >
              </div>
            </template>
          </vueper-slide>
        </vueper-slides>
      </div>
      <div id="main-carousel" class="row">
        <vueper-slides
          fixed-height="100%"
          class="no-shadow"
          :pause-on-hover="true"
          :dragging-distance="70"
        >
          <vueper-slide
            v-for="(slide, i) in mainSlider"
            :key="i"
            :image="slide.image"
            :content="slide.content"
          ></vueper-slide>
        </vueper-slides>
      </div>
      <live-counts></live-counts>
      <actives class="row"></actives>
      <security></security>
      <licensing></licensing>
      <why-us-promo></why-us-promo>
      <advantages></advantages>
      <our-platform></our-platform>
    </div>
    <footer-promo></footer-promo>
  </div>
</template>

<script>
import { mapState } from "vuex";
import LiveCounts from "@/components/layout/LiveCounts.vue";
import Actives from "@/components/layout/Actives.vue";
import Security from "@/components/layout/Security.vue";
import Licensing from "@/components/layout/Licensing.vue";
import WhyUsPromo from "@/components/layout/WhyUsPromo.vue";
import Advantages from "@/components/layout/Advantages.vue";
import OurPlatform from "@/components/layout/OurPlatform.vue";
import { VueperSlides, VueperSlide } from "vueperslides";
import FooterPromo from "@/components/layout/footer/FooterPromo.vue";

import "vueperslides/dist/vueperslides.css";

export default {
  name: "MainPage",
  components: {
    LiveCounts,
    Actives,
    Security,
    Licensing,
    WhyUsPromo,
    Advantages,
    OurPlatform,
    FooterPromo,
    VueperSlides,
    VueperSlide
  },
  computed: {
    ...mapState(["symbols"]),
    mainSlider() {
      return [
        {
          content:
            "<div class='row middle-lg'>" +
            "<div class='col-lg-12 col-md-12 col-sm-12 col-xs-12'>" +
            "<div class='banner-title'>" +
            this.$i18n.messages[this.$i18n.locale].main.banner_title +
            " <span class='title-logo'>" +
            this.$i18n.messages[this.$i18n.locale].main.title_logo +
            "</span>" +
            "</div>" +
            "<div class='banner-button'>" +
            "<a href='/registration'>" +
            this.$i18n.messages[this.$i18n.locale].main.banner_button_text +
            "</a>" +
            "</div>" +
            "</div>" +
            "</div>",
          image: require("@/assets/main-page/mainBannerBg-1.jpeg")
        },
        {
          content:
            "<div class='row'>" +
            "<div class='col-lg-12 col-md-12 col-sm-12 col-xs-12'>" +
            "<div class='banner-title'>" +
            this.$i18n.messages[this.$i18n.locale].main.banner_title +
            "  " +
            this.$i18n.messages[this.$i18n.locale].main.title_logo +
            "</div>" +
            "<div class='banner-button'>" +
            "<a href='/registration'>" +
            this.$i18n.messages[this.$i18n.locale].main.banner_button_text +
            "</a>" +
            "</div>" +
            "</div>" +
            "</div>",
          image: require("@/assets/main-page/bg2.jpg")
        },
        {
          content:
            "<div class='row'>" +
            "<div class='col-lg-12 col-md-12 col-sm-12 col-xs-12'>" +
            "<div class='banner-title'>" +
            this.$i18n.messages[this.$i18n.locale].main.banner_title +
            "  " +
            this.$i18n.messages[this.$i18n.locale].main.title_logo +
            "</div>" +
            "<div class='banner-button'>" +
            "<a href='/registration'>" +
            this.$i18n.messages[this.$i18n.locale].main.banner_button_text +
            "</a>" +
            "</div>" +
            "</div>" +
            "</div>",
          image: require("@/assets/main-page/mainBannerBg-2.jpg")
        }
      ];
    }
  },
  methods: {
    renderSymbols: function() {
      if (this.isFirstTimeLoading === true) {
        this.$store
          .dispatch("fetchSymbols", {
            symbolsType: "forex",
            countryId: 1,
            offset: 1,
            query: ""
          })
          .then(() => {
            this.all = this.symbols;
          })
          .bind(this);

        this.isFirstTimeLoading = false;
      }

      const filter = symbol =>
        symbol.symbol_type.name.toLowerCase().includes("forex") &&
        symbol.price > 0;
      return this.all.filter(filter);
    }
  },
  data() {
    return {
      all: [],
      isFirstTimeLoading: true
      // TODO: <a href='/registration'> - looking for better solution
      /*      mainSlider: [
        {
          content:
            "<div class='row middle-lg'>" +
            "<div class='col-lg-12 col-md-12 col-sm-12 col-xs-12'>" +
            "<div class='banner-title'>" +
            this.$i18n.messages[this.$i18n.locale].main.banner_title+" <span class='title-logo'>"+this.$i18n.messages[this.$i18n.locale].main.title_logo+"</span>" +
            "</div>" +
            "<div class='banner-button'>" +
            "<a href='/registration'>"+this.$i18n.messages[this.$i18n.locale].main.banner_button_text+"</a>" +
            "</div>" +
            "</div>" +
            "</div>",
          image: require("@/assets/main-page/mainBannerBg-1.jpeg")
        },
        {
          content:
            "<div class='row'>" +
            "<div class='col-lg-12 col-md-12 col-sm-12 col-xs-12'>" +
            "<div class='banner-title'>" +
            this.$i18n.messages[this.$i18n.locale].main.banner_title+"  "+this.$i18n.messages[this.$i18n.locale].main.title_logo +
            "</div>" +
            "<div class='banner-button'>" +
            "<a href='/registration'>"+this.$i18n.messages[this.$i18n.locale].main.banner_button_text+"</a>" +
            "</div>" +
            "</div>" +
            "</div>",
          image: require("@/assets/main-page/bg2.jpg")
        },
        {
          content:
            "<div class='row'>" +
            "<div class='col-lg-12 col-md-12 col-sm-12 col-xs-12'>" +
            "<div class='banner-title'>" +
            this.$i18n.messages[this.$i18n.locale].main.banner_title+"  "+this.$i18n.messages[this.$i18n.locale].main.title_logo+
            "</div>" +
            "<div class='banner-button'>" +
            "<a href='/registration'>"+this.$i18n.messages[this.$i18n.locale].main.banner_button_text+"</a>" +
            "</div>" +
            "</div>" +
            "</div>",
          image: require("@/assets/main-page/mainBannerBg-2.jpg")
        }
      ]*/
    };
  }
};
</script>
