var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"faq-tabs learn-nav"},[_c('tabs',{staticClass:"row"},[_c('tab',{attrs:{"name":this.$i18n.messages[this.$i18n.locale].faq.account.title,"selected":_vm.selectedTab === 'Account'},on:{"click":function($event){_vm.selectedTab = 'Account'}}},[_c('div',{staticClass:"col-lg-12 col-md-12 col-sm-12 col-xs-12"},[_c('div',{staticClass:"row"},[_c('h2',[_vm._v(_vm._s(_vm.$t("faq.account.title")))]),_vm._l((this.$i18n.messages[this.$i18n.locale].faq.account
              .items),function(item){return _c('div',[_c('h3',[_vm._v(_vm._s(item.title))]),_c('p',[_vm._v(_vm._s(item.text))])])})],2)])]),_c('tab',{attrs:{"name":this.$i18n.messages[this.$i18n.locale].faq.address_verification.title,"selected":_vm.selectedTab === 'Verification'},on:{"click":function($event){_vm.selectedTab = 'Verification'}}},[_c('div',{staticClass:"col-lg-12 col-md-12 col-sm-12 col-xs-12"},[_c('div',{staticClass:"row"},[_c('h2',[_vm._v(_vm._s(_vm.$t("faq.address_verification.title")))]),_vm._l((this.$i18n.messages[this.$i18n.locale].faq
              .address_verification.items),function(item){return _c('div',[_c('h3',[_vm._v(_vm._s(item.title))]),_c('p',[_vm._v(_vm._s(item.text))])])})],2)])]),_c('tab',{attrs:{"name":this.$i18n.messages[this.$i18n.locale].faq.trading.title,"selected":_vm.selectedTab === 'Trading'},on:{"click":function($event){_vm.selectedTab = 'Trading'}}},[_c('div',{staticClass:"col-lg-12 col-md-12 col-sm-12 col-xs-12"},[_c('div',{staticClass:"row"},[_c('h2',[_vm._v(_vm._s(_vm.$t("faq.trading.title")))]),_vm._l((this.$i18n.messages[this.$i18n.locale].faq.trading
              .items),function(item){return _c('div',[_c('h3',[_vm._v(_vm._s(item.title))]),_c('p',[_vm._v(_vm._s(item.text))])])})],2)])]),_c('tab',{attrs:{"name":this.$i18n.messages[this.$i18n.locale].faq.deposit_withdrawal.title,"selected":_vm.selectedTab === 'Deposit'},on:{"click":function($event){_vm.selectedTab = 'Deposit'}}},[_c('div',{staticClass:"col-lg-12 col-md-12 col-sm-12 col-xs-12"},[_c('div',{staticClass:"row"},[_c('h2',[_vm._v(_vm._s(_vm.$t("faq.deposit_withdrawal.title")))]),_vm._l((this.$i18n.messages[this.$i18n.locale].faq
              .deposit_withdrawal.items),function(item){return _c('div',[_c('h3',[_vm._v(_vm._s(item.title))]),_c('p',[_vm._v(_vm._s(item.text))])])})],2)])]),_c('tab',{attrs:{"name":this.$i18n.messages[this.$i18n.locale].faq.orders_trades.title,"selected":_vm.selectedTab === 'Orders'},on:{"click":function($event){_vm.selectedTab = 'Orders'}}},[_c('div',{staticClass:"col-lg-12 col-md-12 col-sm-12 col-xs-12"},[_c('div',{staticClass:"row"},[_c('h2',[_vm._v(_vm._s(_vm.$t("faq.orders_trades.title")))]),_vm._l((this.$i18n.messages[this.$i18n.locale].faq
              .orders_trades.items),function(item){return _c('div',[_c('h3',[_vm._v(_vm._s(item.title))]),_c('p',[_vm._v(_vm._s(item.text))])])})],2)])]),_c('tab',{attrs:{"name":this.$i18n.messages[this.$i18n.locale].faq.pending_orders.title,"selected":_vm.selectedTab === 'Pending'},on:{"click":function($event){_vm.selectedTab = 'Pending'}}},[_c('div',{staticClass:"col-lg-12 col-md-12 col-sm-12 col-xs-12"},[_c('div',{staticClass:"row"},[_c('h2',[_vm._v(_vm._s(_vm.$t("faq.pending_orders.title")))]),_vm._l((this.$i18n.messages[this.$i18n.locale].faq
              .pending_orders.items),function(item){return _c('div',[_c('h3',[_vm._v(_vm._s(item.title))]),_c('p',[_vm._v(_vm._s(item.text))])])})],2)])]),_c('tab',{attrs:{"name":this.$i18n.messages[this.$i18n.locale].faq.profit.title,"selected":_vm.selectedTab === 'Profit'},on:{"click":function($event){_vm.selectedTab = 'Profit'}}},[_c('div',{staticClass:"col-lg-12 col-md-12 col-sm-12 col-xs-12"},[_c('div',{staticClass:"row"},[_c('h2',[_vm._v(_vm._s(_vm.$t("faq.profit.title")))]),_vm._l((this.$i18n.messages[this.$i18n.locale].faq.profit
              .items),function(item){return _c('div',[_c('h3',[_vm._v(_vm._s(item.title))]),_c('p',[_vm._v(_vm._s(item.text))])])})],2)])]),_c('tab',{attrs:{"name":this.$i18n.messages[this.$i18n.locale].faq.loss.title,"selected":_vm.selectedTab === 'Loss'},on:{"click":function($event){_vm.selectedTab = 'Loss'}}},[_c('div',{staticClass:"col-lg-12 col-md-12 col-sm-12 col-xs-12"},[_c('div',{staticClass:"row"},[_c('h2',[_vm._v(_vm._s(_vm.$t("faq.loss.title")))]),_vm._l((this.$i18n.messages[this.$i18n.locale].faq.loss
              .items),function(item){return _c('div',[_c('h3',[_vm._v(_vm._s(item.title))]),_c('p',[_vm._v(_vm._s(item.text))])])})],2)])]),_c('tab',{attrs:{"name":this.$i18n.messages[this.$i18n.locale].faq.other.title,"selected":_vm.selectedTab === 'Other'},on:{"click":function($event){_vm.selectedTab = 'Other'}}},[_c('div',{staticClass:"col-lg-12 col-md-12 col-sm-12 col-xs-12"},[_c('div',{staticClass:"row"},[_c('h2',[_vm._v(_vm._s(_vm.$t("faq.other.title")))]),_vm._l((this.$i18n.messages[this.$i18n.locale].faq.other
              .items),function(item){return _c('div',[_c('h3',[_vm._v(_vm._s(item.title))]),_c('p',[_vm._v(_vm._s(item.text))])])})],2)])])],1),_c('div',{staticClass:"row"},[_c('footer-promo')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }