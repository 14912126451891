<template>
  <div class="row middle-lg security-section">
    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
      <div class="row title">
        {{ $t("main.security.title") }}
      </div>
      <div class="row block-description">
        {{ $t("main.security.text") }}
        <br />
        {{ $t("main.security.code") }}
      </div>
    </div>
    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
      <img src="~@/assets/main-page/MiFIDII.png" alt="secure" />
    </div>
  </div>
</template>

<script>
export default {
  name: "Security"
};
</script>

