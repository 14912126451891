<template>
  <div class="row">
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <div class="center-lg center-md center-sm center-xs trade-title">
        <h1>{{ $t("market_crypto.title") }}</h1>
      </div>
    </div>
    <div
      class="col-lg-12 col-md-12 col-sm-12 col-xs-12 trade-sub-title center-lg center-md center-sm center-xs"
    >
      {{ $t("market_crypto.text") }}
    </div>
    <div
      class="col-lg-12 col-md-12 col-sm-12 col-xs-12 center-lg center-md center-sm center-xs var-bl"
    >
      <div class="row">
        <table class="margin-table col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <tr class="row head-tbl">
            <th class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
              {{ $t("market_crypto.currency_type") }}
            </th>
            <th class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
              {{ $t("market_crypto.general_info") }}
            </th>
            <th
              class="col-lg-4 col-md-4 col-sm-4 col-xs-4 center-lg center-md center-sm center-xs"
            ></th>
          </tr>
          <tr class="row middle-lg middle-md middle-sm middle-xs">
            <td class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
              <img src="@/assets/market-types/crypto/bitcoin.png" />
            </td>
            <td class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
              <div class="market-type-description">
                {{ $t("market_crypto.table_text1") }} Bitcoin
                {{ $t("market_crypto.table_text2") }}
              </div>
            </td>
            <td class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
              <div class="button-start">
                <button @click="redirectToRegistration()">
                  {{ $t("market_crypto.start_trading") }}
                </button>
              </div>
            </td>
          </tr>
          <tr class="row middle-lg middle-md middle-sm middle-xs">
            <td class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
              <img src="@/assets/market-types/crypto/etherium.png" />
            </td>
            <td class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
              <div class="market-type-description">
                {{ $t("market_crypto.table_text1") }} Etherium
                {{ $t("market_crypto.table_text2") }}
              </div>
            </td>
            <td class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
              <div class="button-start">
                <button @click="redirectToRegistration()">
                  {{ $t("market_crypto.start_trading") }}
                </button>
              </div>
            </td>
          </tr>
          <tr class="row middle-lg middle-md middle-sm middle-xs">
            <td class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
              <img src="@/assets/market-types/crypto/XRP.png" />
            </td>
            <td class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
              <div class="market-type-description">
                {{ $t("market_crypto.table_text1") }} XRP
                {{ $t("market_crypto.table_text2") }}
              </div>
            </td>
            <td class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
              <div class="button-start">
                <button @click="redirectToRegistration()">
                  {{ $t("market_crypto.start_trading") }}
                </button>
              </div>
            </td>
          </tr>
          <tr class="row middle-lg middle-md middle-sm middle-xs">
            <td class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
              <img src="@/assets/market-types/crypto/LTC.png" />
            </td>
            <td class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
              <div class="market-type-description">
                {{ $t("market_crypto.table_text1") }} LTC
                {{ $t("market_crypto.table_text2") }}
              </div>
            </td>
            <td class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
              <div class="button-start">
                <button @click="redirectToRegistration()">
                  {{ $t("market_crypto.start_trading") }}
                </button>
              </div>
            </td>
          </tr>
          <tr class="row middle-lg middle-md middle-sm middle-xs">
            <td class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
              <img src="@/assets/market-types/crypto/z.png" />
            </td>
            <td class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
              <div class="market-type-description">
                {{ $t("market_crypto.table_text1") }} Z
                {{ $t("market_crypto.table_text2") }}
              </div>
            </td>
            <td class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
              <div class="button-start">
                <button @click="redirectToRegistration()">
                  {{ $t("market_crypto.start_trading") }}
                </button>
              </div>
            </td>
          </tr>
          <tr class="row middle-lg middle-md middle-sm middle-xs">
            <td class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
              <img src="@/assets/market-types/crypto/6.png" />
            </td>
            <td class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
              <div class="market-type-description">
                {{ $t("market_crypto.table_text1") }} Stratis
                {{ $t("market_crypto.table_text2") }}
              </div>
            </td>
            <td class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
              <div class="button-start">
                <button @click="redirectToRegistration()">
                  {{ $t("market_crypto.start_trading") }}
                </button>
              </div>
            </td>
          </tr>
          <tr class="row middle-lg middle-md middle-sm middle-xs">
            <td class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
              <img src="@/assets/market-types/crypto/n.png" />
            </td>
            <td class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
              <div class="market-type-description">
                {{ $t("market_crypto.table_text1") }} Bitcoin
                {{ $t("market_crypto.table_text2") }}
              </div>
            </td>
            <td class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
              <div class="button-start">
                <button @click="redirectToRegistration()">
                  {{ $t("market_crypto.start_trading") }}
                </button>
              </div>
            </td>
          </tr>
          <tr class="row middle-lg middle-md middle-sm middle-xs">
            <td class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
              <img src="@/assets/market-types/crypto/8.png" />
            </td>
            <td class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
              <div class="market-type-description">
                {{ $t("market_crypto.table_text1") }} Bitcoin
                {{ $t("market_crypto.table_text2") }}
              </div>
            </td>
            <td class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
              <div class="button-start">
                <button @click="redirectToRegistration()">
                  {{ $t("market_crypto.start_trading") }}
                </button>
              </div>
            </td>
          </tr>
        </table>
      </div>
    </div>

    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <why-us-promo></why-us-promo>
    </div>
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <advantages></advantages>
    </div>
    <footer-promo></footer-promo>
  </div>
</template>

<script>
import WhyUsPromo from "@/components/layout/WhyUsPromo";
import FooterPromo from "@/components/layout/footer/FooterPromo.vue";
import Advantages from "@/components/layout/Advantages";
import router from "@/router";

export default {
  name: "TradeCommodities",
  components: {
    Advantages,
    FooterPromo,
    WhyUsPromo
  },
  methods: {
    redirectToRegistration: function() {
      router.push("/registration");
    }
  }
};
</script>
