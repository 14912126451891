<template>
  <div>
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 tabs">
      <ul>
        <li
          v-for="(tab, i) in tabs"
          :class="{ 'is-active': tab.isActive }"
          v-bind:key="i"
        >
          <a :href="tab.href" @click="selectTab(tab)">{{ tab.name }}</a>
        </li>
      </ul>
    </div>

    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 tabs-details">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "Tabs",
  data() {
    return { tabs: [] };
  },

  created() {
    this.tabs = this.$children;
  },
  methods: {
    selectTab(selectedTab) {
      this.tabs.forEach(tab => {
        tab.isActive = tab.name == selectedTab.name;
      });
    }
  }
};
</script>


