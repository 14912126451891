var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"is-safe"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12 col-md-12 col-sm-12 col-xs-12 head"},[_c('span',{staticClass:"title"},[_vm._v(_vm._s(this.$i18n.messages[this.$i18n.locale].why_us.title))]),_c('div',{domProps:{"innerHTML":_vm._s(this.$i18n.messages[this.$i18n.locale].why_us.subtitle)}}),_c('div',{staticClass:"promo-action col-lg-12"},[_c('button',{staticClass:"promo-button",on:{"click":function($event){return _vm.redirectToRegistration()}}},[_vm._v(" "+_vm._s(this.$i18n.messages[this.$i18n.locale].main.banner_button_text)+" ")])])])]),_c('div',{staticClass:"row section"},[_c('div',{staticClass:"col-lg-6 col-md-6 col-sm-12"},[_c('h3',[_vm._v(" "+_vm._s(this.$i18n.messages[this.$i18n.locale].why_us.block1_title)+" ")]),_c('p',[_vm._v(" "+_vm._s(this.$i18n.messages[this.$i18n.locale].why_us.block1_text)+" "),_c('router-link',{staticClass:"border-button",attrs:{"onclick":"window.scrollTo(0,0);","to":"/registration"}},[_c('img',{attrs:{"src":require("@/assets/about-us/icon-1.png"),"alt":"image"}}),_c('span',{domProps:{"innerHTML":_vm._s(
              this.$i18n.messages[this.$i18n.locale].why_us.block1_button
            )}})])],1)]),_vm._m(0)]),_c('div',{staticClass:"row section bg-gray"},[_vm._m(1),_c('div',{staticClass:"col-lg-6 col-md-6 col-sm-12"},[_c('h3',[_vm._v(" "+_vm._s(this.$i18n.messages[this.$i18n.locale].why_us.block2_title)+" ")]),_c('p',[_vm._v(" "+_vm._s(this.$i18n.messages[this.$i18n.locale].why_us.block2_text)+" "),_c('router-link',{staticClass:"border-button",attrs:{"onclick":"window.scrollTo(0,0);","to":"/markets"}},[_c('img',{attrs:{"src":require("@/assets/about-us/stocks.png"),"alt":"image"}}),_c('span',{domProps:{"innerHTML":_vm._s(
              this.$i18n.messages[this.$i18n.locale].why_us.block2_button
            )}})])],1)])]),_c('div',{staticClass:"row section"},[_c('div',{staticClass:"col-lg-6 col-md-6 col-sm-12"},[_c('h3',[_vm._v(" "+_vm._s(this.$i18n.messages[this.$i18n.locale].why_us.block3_title)+" ")]),_c('p',[_vm._v(" "+_vm._s(this.$i18n.messages[this.$i18n.locale].why_us.block3_text)+" "),_c('router-link',{staticClass:"border-button",attrs:{"onclick":"window.scrollTo(0,0);","to":"/registration"}},[_c('img',{attrs:{"src":require("@/assets/about-us/settings.png"),"alt":"image"}}),_c('span',{domProps:{"innerHTML":_vm._s(
              this.$i18n.messages[this.$i18n.locale].why_us.block3_button
            )}})])],1)]),_vm._m(2)]),_c('div',{staticClass:"row section bg-gray"},[_vm._m(3),_c('div',{staticClass:"col-lg-6 col-md-6 col-sm-12"},[_c('h3',[_vm._v(" "+_vm._s(this.$i18n.messages[this.$i18n.locale].why_us.block4_title)+" ")]),_c('p',[_vm._v(" "+_vm._s(this.$i18n.messages[this.$i18n.locale].why_us.block4_text)+" "),_c('router-link',{staticClass:"border-button",attrs:{"onclick":"window.scrollTo(0,0);","to":"/faq?tab=loss"}},[_c('img',{attrs:{"src":require("@/assets/about-us/shield.png"),"alt":"image"}}),_c('span',{domProps:{"innerHTML":_vm._s(
              this.$i18n.messages[this.$i18n.locale].why_us.block4_button
            )}})])],1)])]),_c('div',{staticClass:"row section"},[_c('div',{staticClass:"col-lg-6 col-md-6 col-sm-12"},[_c('h3',[_vm._v(" "+_vm._s(this.$i18n.messages[this.$i18n.locale].why_us.block5_title)+". ")]),_c('p',[_vm._v(" "+_vm._s(this.$i18n.messages[this.$i18n.locale].why_us.block5_text)+" "),_c('router-link',{staticClass:"border-button",attrs:{"onclick":"window.scrollTo(0,0);","to":"/learn-to-trade"}},[_c('img',{attrs:{"src":require("@/assets/about-us/bank.png"),"alt":"image"}}),_c('span',{domProps:{"innerHTML":_vm._s(
              this.$i18n.messages[this.$i18n.locale].why_us.block5_button
            )}})])],1)]),_vm._m(4)]),_c('div',{staticClass:"row section bg-gray"},[_vm._m(5),_c('div',{staticClass:"col-lg-6 col-md-6 col-sm-12"},[_c('h3',[_vm._v(" "+_vm._s(this.$i18n.messages[this.$i18n.locale].why_us.block6_title)+". ")]),_c('p',[_vm._v(" "+_vm._s(this.$i18n.messages[this.$i18n.locale].why_us.block6_text)+" "),_c('router-link',{staticClass:"border-button",attrs:{"onclick":"window.scrollTo(0,0);","to":"/about/compliance?tab=regulations"}},[_c('img',{attrs:{"src":require("@/assets/about-us/info.png"),"alt":"image"}}),_c('span',{domProps:{"innerHTML":_vm._s(
              this.$i18n.messages[this.$i18n.locale].why_us.block6_button
            )}})])],1)])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12"},[_c('why-us-promo'),_c('advantages')],1),_c('footer-promo')],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-lg-6 col-md-6 col-sm-12"},[_c('img',{attrs:{"src":require("@/assets/about-us/i_first.png"),"alt":"image"}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-lg-6 col-md-6 col-sm-12"},[_c('img',{attrs:{"src":require("@/assets/about-us/i_second.png"),"alt":"image"}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-lg-6 col-md-6 col-sm-12"},[_c('img',{attrs:{"src":require("@/assets/about-us/i_third.png"),"alt":"image"}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-lg-6 col-md-6 col-sm-12"},[_c('img',{attrs:{"src":require("@/assets/about-us/4_Block.png"),"alt":"image"}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-lg-6 col-md-6 col-sm-12"},[_c('img',{attrs:{"src":require("@/assets/about-us/i_fourth.png"),"alt":"image"}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-lg-6 col-md-6 col-sm-12 afmf-image-block"},[_c('img',{staticClass:"afmf-image",attrs:{"src":require("@/assets/why-us/afmf.jpg"),"alt":"image"}})])}]

export { render, staticRenderFns }