<template>
  <div class="row faq-tabs">
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 first-question">
      <div
        class="row center-lg center-md center-sm center-xs middle-lg middle-md middle-sm middle-xs"
      >
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 question">
          {{ $t("learn_to_trade.banner_title") }}
        </div>
        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 question-sub-title">
          {{ $t("learn_to_trade.banner_text") }}
        </div>
      </div>
    </div>
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 faq-tabs learn-nav">
      <tabs class="row">
        <tab
          :name="
            this.$i18n.messages[this.$i18n.locale].learn_to_trade
              .what_is_trading.title
          "
          :selected="true"
        >
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div class="row">
              <div
                v-for="item in this.$i18n.messages[this.$i18n.locale]
                  .learn_to_trade.what_is_trading.items"
                v-bind:key="item.title"
              >
                <h2>{{ item.title }}</h2>
                <p v-html="item.text"></p>
              </div>
              <!--   <h2>CFD trading meaning: What is a contract for difference?</h2>
              <p>
                A contract for difference (CFD) is a popular type of derivative
                that allows you to trade on margin, providing you with greater
                exposure to the financial markets. CFDs are a type of
                derivative, meaning you do not buy the underlying asset itself.
                Instead, you buy or sell units for a given financial instrument
                depending on whether you think the underlying price will rise or
                fall. The broker enters the market with the full amount of funds
                providing leverage for clients. A CFD is a contract between a
                broker and a trader who agree to exchange the difference in
                value of an underlying security between the beginning and the
                end of the contract.
              </p> -->
              <!-- <h2>
                CFDs and futures are both derivatives, so what is the
                difference?
              </h2>
              <p>
                A futures contract (or simply ‘futures’) and a contract for
                difference are both derivative products. When you purchase a
                CFD, you are buying a set number of contacts on a market if you
                are expecting that market to appreciate and selling a set number
                if you expect the market to fall. The change in the value of the
                position you take is reflected in the movements of the
                underlying market. With CFD trading, you can close your position
                at any time when the market is open.
              </p>
              <p>
                Futures, on the other hand, are contracts that mean you are
                agreeing to buy a financial instrument at a set point in the
                future at a predetermined price. Unlike CFD trading, you have a
                set date and price for this transaction, which means closing
                your position could be costly. The value of a futures contract
                depends on both the current movements in the underlying market
                and the market sentiment about the future price of an asset.
              </p>
              <h2>What can you trade with a CFD?</h2>
              <p>
                When CFD trading, you can open positions on a variety of
                different asset classes including shares, indices, currencies,
                commodities and cryptocurrencies – all within one single
                platform.
              </p>
              <p>
                A trader can enter the stock market without having to deal
                directly with share purchases, providing greater liquidity and
                easier execution. This has the added benefit of being able to
                profit in a falling market by short selling.
              </p>
              <p>
                Trading CFDs is one of the very few ways to gain access to the
                indices market. CFDs on indices mirror the composition of a
                certain index. The FX market is suited to CFDs and leveraged
                trading due to the relatively small price movements that occur
                in these markets.
              </p>
              <p>
                Remember to employ risk management techniques when trading at
                all times and be even more cautious of assets that have a
                history of being highly volatile like cryptocurrencies.
              </p>
              <h2>Why trade CFDs with CME-invest.nl?</h2>
              <ul class="li-block">
                <li>
                  Trading on margin: Providing trading on margin (up to 1:100
                  leverage), CME-invest.nl gives you access to financial markets
                  with the help of CFDs.
                </li>
                <li>
                  Trading the difference: When trading CFDs, you don’t buy the
                  underlying asset itself, meaning you are not tied to it. You
                  only speculate on the rise or fall of the asset price. When
                  CFD trading you employ the same strategies as you would in
                  traditional markets, with the exception that you can
                  short-sell with CFDs. A CFD investor can go short or long, set
                  stop and limit losses and apply trading scenarios that align
                  with their objectives.
                </li>
                <li>
                  CME-invest.nl puts a special emphasis on safety. Licensed by
                  the AFM and FSMA, it complies with all regulations and ensures
                  that its clients’ data security comes first. The company
                  allows to withdraw money 24/7 and keeps traders’ funds across
                  segregated bank accounts.
                </li>
              </ul> -->
            </div>
          </div>
        </tab>
        <tab
          :name="
            this.$i18n.messages[this.$i18n.locale].learn_to_trade.how_to_trade
              .title
          "
        >
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div class="row">
              <div
                v-for="item in this.$i18n.messages[this.$i18n.locale]
                  .learn_to_trade.how_to_trade.items"
                v-bind:key="item.title"
              >
                <h2>{{ item.title }}</h2>
                <p v-html="item.text"></p>
              </div>
              <!-- <h2>
                Looking how to trade CFDs? Follow these five steps to get
                started:
              </h2>
              <ul class="li-block">
                <li>
                  Understand CFDs and how they work
                </li>
                <li>
                  Create and fund an account
                </li>
                <li>
                  Comprise a trading plan and risk management strategy
                </li>
                <li>
                  Find trading opportunities
                </li>
                <li>
                  Open, monitor and close your first position
                </li>
              </ul>

              <h2>Understand CFD trading</h2>
              <p>
                Many people new to trading often ask “how does CFD trading
                work?” A CFD is a derivative product that lets you trade whilst
                only putting up a fraction of the value of your trade, this is
                known as trading on margin. Trading on margin, or leveraged
                trading, gives greater exposure to global financial markets.
                Leverage trading involves only putting down a fraction of the
                value of your trade and essentially borrowing the remaining
                amount from your broker. This allows traders to open larger
                positions given their initial capital. Leverage trading can
                amplify your wins, but can also amplify your losses. When
                trading CFDs you do not own the underlying security. You simply
                buy or sell a contract depending whether you want to long or
                short a security.
              </p>
              <p>
                How CFD trading works: When you open a CFD position you select
                the number of contracts (i.e. trade size) you would like to buy
                or sell and your profit will rise in line with each point the
                market moves in your favour. If you think the price of an asset
                will appreciate, then you would open a long (buy) position and
                profit if the market moves in line with your expectations.
                Conversely, if you think the price of an asset will depreciation
                then you would open a short (sell) position and profit if the
                market moves in line with your expectations.
              </p>
              <h2>Create and fund an account</h2>
              <p>
                Creating a CFD account with CME-invest.nl is a simple and
                straightforward process, and will only take minutes to complete.
                First, you have to create an account using an email address and
                password. Then you have to provide proof of address and identity
                verification which can be done electronically. Finally, after
                this has been processed, all you need to do is fund your account
                in accordance with the minimum deposit.
              </p>
              <h2>Comprise a trading plan</h2>
              <p>
                Once your have you account set up, the next step is to devise a
                trading plan for trading CFDs. A trading plan is a structured
                method for identifying and trading assets that considers various
                factors such as timeframe, propensity to risk and the investor’s
                objective.
              </p>
              <p>
                Contrary to popular belief, trading plans do not necessarily
                need to be pages long – they can simply be a comprehensive
                blueprint that has considered all essential factors. When
                devising a trading plan, you will consider: your motivation for
                trading, the time commitment you wish to make, trading goals,
                attitude to risk and risk management strategies, available
                capital, markets to trade and preferred trading strategies.
              </p>
              <p>
                Once you have outlined why you want to start trading and how
                much time you have to commit to this, this will shape your
                goals. Be realistic with your goals. If you plan to commit a few
                hours a week, then don’t expect to make vast sums of money
                within a short timeframe; having unrealistic goals will
                encourage you to make riskier trades in a given timeframe.
              </p>
              <p>
                It is crucial to determine how much you are willing to risk as
                well as your attitude to risk in order to formulate appropriate
                risk management strategies. If you are risk averse, then you
                will be looking for opportunities with lower risk-to-reward
                (R-R) ratios. Once you have considered risk management, you need
                to decide what asset classes you are going to trade, and this
                can be somewhat influenced by your goals. For instance, if you
                are looking for slow and steady growth, asset classes with
                higher volatility should form a proportionally small part of
                your portfolio. It is strongly recommended to diversify across
                all asset classes to increase the likelihood of good trading
                opportunities, as well as to mitigate risk.
              </p>
              <p>
                You must decide what trading strategies you plan to use based on
                the factors mentioned above. A few basic strategies and
                techniques include:
              </p>
              <ul class="li-block">
                <li>
                  <strong>Trading the trend.</strong> Trend trading is a trading
                  strategy that attempts to capture financial gains through the
                  analysis of an asset's momentum in a particular direction.
                </li>
                <li>
                  <strong>Day trading.</strong> Capitalise on short-term
                  opportunities that are triggered by a variety of stimulus from
                  developing news or emerging trends. With day trading all
                  positions are closed by the end of the day.
                </li>
                <li>
                  <strong>Scalping.</strong> Scalp trading is a type of
                  short-term trading that aims to make small and regular profits
                  from numerous trades. Scalp trading tends to generate the
                  smallest gains per each successful trade, when compared to
                  other types of trading. It tends to be based on extensive
                  technical analysis.
                </li>
              </ul>
              <p>
                Find out more about trading strategies and different techniques
                here.
              </p>
              <h2>Find opportunities across asset classes when CFD trading</h2>
              <p>
                Now that you have formulated your trading plan, use
                CME-invest.nl’s award-winning platform to browse through more
                1,000 financial instruments to find opportunities within a
                variety of different markets. We offer markets across the
                following asset classes: shares, indices, forex pairs,
                commodities and cryptocurrencies.
              </p>
              <p>
                With CFD trading, you have the option to open long and short
                positions, meaning you can spot opportunities in rising or
                falling markets.
              </p>

              <h2>
                How to place a CFD trade: open, monitor and close your first
                position
              </h2>
              <h3>Watchlist</h3>
              <p>
                Within our platform you will find the watchlist under at the
                trade terminal. The watchlist consists of two parts. Firstly, it
                shows the extensive list of instruments we offer, accompanied
                with useful filters to help you find the exact market you’re
                looking for. These filters include Major Indices and Popular
                Tech German Stocks for instance. Once you tap on an instrument
                you’ll become familiar with the other part of our watchlist that
                illustrates security prices dynamically in the form of real-time
                charts. You will see a graph, relevant news and buy-sell options
                for the instrument.
              </p>
              <p>
                Once you’ve spot an opportunity in the market and you’re ready
                to trade, open the position in accordance with where you think
                the market will go. From this point, your profit or loss will
                move in line with the underlying asset price in real time.
                You’ll be able to monitor all positions that you have opened
                within the platform as well as close the positions when you
                want.
              </p>
              <h3>Buy and sell prices</h3>
              <p>
                In the platform, you’re always offered two prices based on the
                value of the underlying instrument you are trading: the buy
                (bid) price and the sell (offer) price. The price to buy will
                always be higher than the current underlying value and the sell
                price will always be lower. The difference between these prices
                is called the spread.
              </p>
              <h3>Number of contracts</h3>
              <p>
                When CFD trading, you’ll need to decide how many contracts you
                want to trade. Each market has its own minimum number of
                contracts. For instance, Brent crude oil has a minimum of 1
                contracts to enter a trade.
              </p>
              <h3>Stops and limits</h3>
              <p>
                You can set up limit orders to automatically close out a
                position at a given profit level so you do not have to watch the
                market constantly. Limit orders also reduce the likelihood of
                holding onto a winning trade for too long, as emotion can take
                over and blind you of your initial expectations.
              </p>
              <p>
                Similarly you can place stop-losses to restrict your potential
                losses. A stop-loss is the point at which a position is
                automatically closed out if the price of the security drops
                below the trader’s entry point. Stops and limits are crucial
                risk management tools and are strongly advised.
              </p>
              <h3>Margin closeout</h3>
              <p>
                If you make a trade and it is not going how you expected it to,
                CME-invest.nl protects you from losing more than you initially
                invested. In order to keep positions open a trader must meet the
                maintenance margin requirement; the minimum value of funds
                needed to be kept in a margin account to cover any credit risks
                whilst trading. The value maintained in a margin account acts as
                collateral for credit.
              </p>
              <p>
                When a trader’s exposure is about to exceed the maintenance
                margin requirement, CME-invest.nl notifies them via a ‘margin
                call’. This is where a trade will either need to top up their
                balance or close some of their positions. If they do not act,
                their positions will be automatically closed if the close out
                level is reached.
              </p> -->
            </div>
          </div>
        </tab>
        <tab
          :name="
            this.$i18n.messages[this.$i18n.locale].learn_to_trade.cfd_markets
              .title
          "
        >
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div class="row">
              <div
                v-for="item in this.$i18n.messages[this.$i18n.locale]
                  .learn_to_trade.cfd_markets.items"
                v-bind:key="item.title"
              >
                <h2>{{ item.title }}</h2>
                <p v-html="item.text"></p>
              </div>
              <!-- <h2>What can you trade with a CFD?</h2>
              <p>
                Contracts for difference (CFDs) enable investors to trade
                numerous markets with easy-to-use platform at CME-invest.nl
              </p>
              <p>
                CFDs are derivative products that allow investors to trade on
                margin, facilitating greater exposure to financial markets. You
                can trade CFDs on multiple asset classes such as shares,
                indices, commodities, currencies and cryptocurrencies.
                CME-invest platform provides access to more 1,000 different
                financial instruments across these asset classes, so you are
                only ever a few clicks away from trading the world’s most
                popular markets all in one place.
              </p>
              <h2>Trading CFDs on shares</h2>
              <p>
                A share represents a portion of ownership in a company. The
                value of shares rise and fall respective to the company’s value,
                which provides a basis for speculation on these movements in a
                stock market. However, an investor need not buy a share to gain
                the benefits of share trading; they can make profit through CFD
                trading markets.
              </p>
              <p>
                A traditional way of investing in the stock markets involves the
                purchasing of shares in a company on a stock exchange, hence
                owning the underlying asset itself. This is considered a
                long-term approach, as the investor is waiting for a sustained
                price increase.
              </p>
              <p>
                Another way of gaining exposure in the stock markets is to trade
                CFDs on shares, speculating the price changes of an underlying
                stock, without having to own it. The tends to be considered a
                shorter-term approach.
              </p>
              <p>
                Shares prices are determined by a combination of factors such as
                the supply and demand for the share in question, which is
                affected by current company earnings as well as future
                performance predictions.
              </p>
              <p>
                CFDs require a lower initial outlay than traditional share
                trading because CFDs are traded on margin. Consequently, CFD
                traders are able to speculate on stock price movements of the
                most valuable and popular share markets around the globe.
              </p>
              <h2>Trading CFDs on indices</h2>
              <p>
                A stock index is a measure of a particular section of the stock
                market, whether that be a list of the biggest companies in that
                market or a particular sector in the markets. The value of the
                index is the sum of its components and tracks accumulated
                changes in the individual companies.
              </p>
              <p>
                You can gain exposure to indices markets by trading CFDs. CFDs
                on indices mirror the composition of a certain index.
              </p>
              <p>
                If an investor wants to speculate on an industry's performance,
                or the performance of a whole economy, they can trade CFDs on
                indices. Our trading platform enables investors to benefit from
                price movements in major global indices.
              </p>
              <h2>Trading CFDs on commodities</h2>
              <p>
                Commodities trading is where raw material assets such as sugar
                or wheat are bought and sold. In other words, commodities are
                the raw materials that comprise the global economy.
              </p>
              <p>
                Commodities can be categorised into two types: hard and soft.
                Soft commodities are commodities that tend to be grown, these
                include: coffee, sugar, fruit, livestock and many others.
                Whereas hard commodities are commodities that must be extracted
                in some variety, including those such as: gold, silver and oil.
              </p>
              <p>
                You can trade CFDs on commodities. With CFDs, you do not own the
                underlying asset physically. For instance, there’s no need to
                store any crude oil when you trade Brent Crude CFDs. You simply
                trade the price of the commodity and you’ll never have to take
                delivery.
              </p>
              <h2>Trading forex CFDs</h2>
              <p>
                Trading CFDs on currency pairs is a common way to profit from
                the FX market. In a contract, the buyer and seller agree to
                exchange the difference in value between the contract is opened
                and closed. When CFD trading, you speculate on currency pairings
                by trading a specified amount of contracts in the base currency.
                The FX market is suited to CFDs and leveraged trading due to the
                relatively small price movements that occur in these markets.
                CFDs provide a simpler and more cost effective way to trade
                commodities compared to futures.
              </p>
              <h2>Trading cryptocurrency CFDs</h2>
              <p>
                There are many ways to gain exposure in the cryptocurrency
                markets. A trader can purchase the cryptocurrency itself on
                numerous exchanges. For example, purchasing Bitcoin on Bitfinex,
                Exmo etc. In this instance, the trader owns the cryptocurrency
                themselves. This tends to be considered a longer-term
                investment, as traders are waiting for a substantial price
                increase in order to profit from their holdings.
              </p>
              <p>
                Otherwise, the alternative is to trade CFDs on cryptocurrencies,
                speculating on the price movements, without holding the
                underlying asset. This tends to be considered a shorter-term
                investment.
              </p>
              <p>
                There are crucial differences between trading CFDs on
                cryptocurrencies and exchanging cryptocurrencies themselves.
                When you purchase cryptocurrency, it is stored in a wallet,
                whereas when you trade CFDs on cryptocurrencies, the position is
                held in your account, which is regulated by a financial
                authority.
              </p>
              <p>
                You have more flexibility when you trade using CFDs because you
                are not tied to the asset; you have merely bought or sold the
                underlying contract. Additionally, CFDs are a more established
                and regulated financial product. It is worth noting, however,
                that the increased volatility of bitcoin, matched with leveraged
                trading, can provide amplified wins as well as losses. Ensure
                you employ rigorous risk management techniques and utilises
                stops and limit orders.
              </p> -->
            </div>
          </div>
        </tab>
        <tab
          :name="
            this.$i18n.messages[this.$i18n.locale].learn_to_trade
              .cfd_trading_explained.title
          "
        >
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div class="row">
              <div
                v-for="item in this.$i18n.messages[this.$i18n.locale]
                  .learn_to_trade.cfd_trading_explained.items"
                v-bind:key="item.title"
              >
                <h2>{{ item.title }}</h2>
                <p v-html="item.text"></p>
              </div>
              <!-- <h2>CFD trading explained</h2>
              <h3>Margin trading</h3>
              <p>
                For example, you buy 10 CFDs on Apple at a price of 302.64. Your
                initial outlay is $605.28 (due to the 5:1 margin). The value of
                Apple stock moves to 323.8, and you decide to sell at this value
                – a 21.16 point increase. The profit you have made from this
                trade is $211.60, calculated by multiplying the point increase
                with the number of contracts purchased.
              </p>

              <h3>Spread and commission</h3>
              <p>
                With CFD trading, you’re always offered two prices based on the
                value of the underlying instrument: the buy (bid) price and the
                sell (offer) price. The price to buy will always be higher than
                the current underlying value and the sell price will always be
                lower. The difference between these prices is called the spread.
              </p>
              <h3>Contract size</h3>
              <p>
                The contract size of a CFD depends on the underlying asset. For
                example, a share CFD implies 1 share. So, if you intend to trade
                1,000 shares of Tesla using contracts for difference, you should
                buy 1,000 CFDs. Commodities are far more interesting from this
                perspective. The contract size of gold is a troy ounce. Soybean
                is traded in bushels. Coffee is traded in pounds, and so on.
              </p>
              <h3>Going long and short</h3>
              <p>
                When you open a CFD position, you select the number of contracts
                you would like to trade (buy or sell) and your profit will rise
                in line with each point the market moves in your favour. If you
                think the price of an asset will appreciate, then you would open
                a long (buy) position and profit if the market moves in line
                with your expectations. Conversely, if you think the price of an
                asset will depreciate, then you would open a short (sell)
                position and profit if the market moves in line with your
                expectations.
              </p>
              <h3>Going long with CFDs</h3>
              <p>
                For example, you think Apple shares are going to appreciate and
                you want to open a long position to profit from this
                opportunity. You purchase 100 CFDs on Apple shares at $160, so
                the total value of the trade will be $16,000. If Apple
                appreciated to $170, you make $10 per share, which is a $1000
                profit. This is illustrated below.
              </p>
              <h3>Going short with CFDs</h3>
              <p>
                For example, you think that the Apple price will depreciate, and
                you want to profit from this movement. With CFDs you can open a
                short position (known as short-selling) and profit from a
                falling market. This time, let’s say, you decide to sell 100
                CFDs on Apple at $170 per share, which then falls to $160 per
                share. You will have made a profit of $1000, or $10 per share.
              </p>
              <h3>Risk management: stops and limits</h3>
              <p>
                You can set up limit orders to automatically close out a
                position at a given profit level, meaning you don’t have to
                spend time watching the price fluctuate. Limit orders also
                reduce the likelihood of holding onto a winning trade for too
                long, as emotion can take over and blind you of your initial
                expectations. Similarly you can place stop-losses to restrict
                your potential losses. A stop-loss is the point at which a
                position is automatically closed out if the price of the
                security drops below the trader’s entry point. Stops and limits
                are crucial risk management tools and are strongly advised.
              </p>
              <p>
                Setting stop-losses is extremely good practice for any trader,
                even for a seasoned one (and that’s not speaking of beginners).
                You may question the importance of stop-losses when you don’t
                have open positions. But once you feel the emotional weight of a
                falling market, you will understand how painful it is to lose
                your money in a matter of seconds. The market is volatile, and
                you should accept that; it will help make you a smarter trader.
              </p>
              <h3>Duration</h3>
              <p>
                One of the major points to remember when trading CFDs is that
                they do not have an expiration date. A trade is closed only when
                placed in the opposite direction – it is as simple as that. For
                example, you can close a buy trade on 100 CFDs on silver only by
                selling these CFDs.
              </p>
              <h3>Profit and loss</h3>
              <p>
                Profit and loss are easily calculated with a simple formula. You
                just multiply the number of contracts you hold by the difference
                in price.
              </p>
              <p>
                After you’ve considered extra costs like spred and swap, then
                you will have the ‘take home’ value of your wins or losses.
              </p>
              <p>
                Your profit to loss ratio, often abbreviated to P&L, can be
                calculated using the following formula:
              </p>
              <h3>P&L = number of CFDs x (closing price – opening price)</h3> -->
            </div>
          </div>
        </tab>
        <tab
          :name="
            this.$i18n.messages[this.$i18n.locale].learn_to_trade
              .cdf_vs_share_trading.title
          "
        >
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div class="row">
              <div
                v-for="item in this.$i18n.messages[this.$i18n.locale]
                  .learn_to_trade.cdf_vs_share_trading.items"
                v-bind:key="item.title"
              >
                <p v-html="item.text"></p>
              </div>
              <!-- <p>
                When investing in shares a trader has two options. One option is
                to purchase actual shares in companies on different exchanges
                where they are listed; this is known as share trading. For
                example, you can purchase Apple shares on the NASDAQ stock
                exchange, where you have a stake in the company. This is known
                as a more long-term approach to investing, where the trader is
                usually expecting the price to rise over a the time frame of
                months to years. Share trading is popular but lacks the
                flexibility, in the form of leverage, that CFDs offer.
              </p>
              <p>
                The alternative to buying shares is CFD trading on shares. A
                trader can purchase a contract for difference (CFD) on a
                particular equity, speculating of the price difference of an
                underlying asset (in this case a share) without having to own
                it. A CFD is a derivative product where a broker typically
                agrees to pay an investor the difference in the value of a
                security between an opening and closing price. Traders can open
                long positions (speculating that the price will rise) or short
                positions (speculating that the price will fall). CFD trading
                tends to be considered a short-term investment, where trades are
                opened and closed within day to week timeframes.
              </p>
              <p>
                The vital difference between taking a long position with a CFD
                and purchasing a security is the ability to make leveraged
                trades. CFDs are traded on margin, which means that a trader can
                open larger positions relative to the amount of initial capital
                they have.
              </p>

              <div class="col-xs-12 bl-table-cnt">
                <div class="row center-xs middle-xs bl-table-hed">
                  <th class="col-xs-6  head-coll">
                    Contracts for difference (CFDs)
                  </th>
                  <th class="col-xs-6  head-coll">
                    Traditional shares
                  </th>
                </div>
                <div class="row center-xs middle-xs bl-table-content">
                  <div class="col-xs-6 content-coll">
                    Trade CFDs using leverage to gain greater exposure with your
                    initial capital
                  </div>
                  <div class="col-xs-6 content-coll">
                    Pay the full value of your trade up front
                  </div>
                </div>
                <div class="row center-xs middle-xs bl-table-content">
                  <div class="col-xs-6 content-coll">
                    CFDs are exempt from stamp duty
                  </div>
                  <div class="col-xs-6 content-coll">
                    When you buy shares, you pay stamp duty of 0.5% on the
                    transaction
                  </div>
                </div>
                <div class="row center-xs middle-xs bl-table-content">
                  <div class="col-xs-6 content-coll">
                    With CFDs, you can open a long or short position, so you can
                    profit from rising and falling markets
                  </div>
                  <div class="col-xs-6 content-coll">
                    When purchasing shares, you can only profit from rising
                    prices
                  </div>
                </div>
                <div class="row center-xs middle-xs bl-table-content">
                  <div class="col-xs-6 content-coll">
                    Trade a variety of financial instruments across shares,
                    indices, commodities, currencies and cryptocurrencies
                  </div>
                  <div class="col-xs-6 content-coll">
                    Trade equities only
                  </div>
                </div>
                <div class="row center-xs middle-xs bl-table-content">
                  <div class="col-xs-6 content-coll">
                    Trade at all hours on numerous markets
                  </div>
                  <div class="col-xs-6 content-coll">
                    Trade only during stock exchange opening hours
                  </div>
                </div>
                <div class="row center-xs middle-xs bl-table-content">
                  <div class="col-xs-6 content-coll">
                    No shareholder privileges
                  </div>
                  <div class="col-xs-6 content-coll">
                    Receive shareholder privileges
                  </div>
                </div>
              </div>

              <h2>Comparing CFD trading and shares trading</h2>

              <div class="col-xs-12 bl-table-cnt">
                <div class="row center-xs middle-xs bl-table-hed">
                  <div class="col-xs-4 head-coll">
                    Contracts for difference (CFDs)
                  </div>
                  <div class="col-xs-4 head-coll">
                    CFD trading
                  </div>
                  <div class="col-xs-4 head-coll">
                    Share trading
                  </div>
                </div>
                <div class="row center-xs middle-xs bl-table-content">
                  <div class="col-xs-4 content-coll">
                    What is it?
                  </div>
                  <div class="col-xs-4 content-coll">
                    Trading a contract, on margin, where the value is derived
                    from an underlying asset, which you do not own
                  </div>
                  <div class="col-xs-4 content-coll">
                    The buying and selling of deliverable shares in a company on
                    an exchange
                  </div>
                </div>
                <div class="row center-xs middle-xs bl-table-content">
                  <div class="col-xs-4 content-coll">
                    What kind of trading is it suitable for?
                  </div>
                  <div class="col-xs-4 content-coll">
                    Suitable for intra-day, day, and medium-term trading
                  </div>
                  <div class="col-xs-4 content-coll">
                    More suitable for long-term trading
                  </div>
                </div>
                <div class="row center-xs middle-xs bl-table-content">
                  <div class="col-xs-4 content-coll">
                    Do I pay to keep positions open?
                  </div>
                  <div class="col-xs-4 content-coll">
                    There is a charge levied for keeping CFD positions open
                    overnight
                  </div>
                  <div class="col-xs-4 content-coll">
                    No
                  </div>
                </div>
                <div class="row center-xs middle-xs bl-table-content">
                  <div class="col-xs-4 content-coll">
                    Range of markets
                  </div>
                  <div class="col-xs-4 content-coll">
                    You can trade CFDs across shares, indices, currencies,
                    commodities and cryptocurrencies, with 1,000 to choose from
                    on CME-incest platform
                  </div>
                  <div class="col-xs-4 content-coll">
                    CME-incest offers 1,700 equities to trade with CFDs on
                    CNE-invest platform
                  </div>
                  <div class="col-xs-4 content-coll">
                    CME-invest offers 1,700 equities to trade with CFDs
                  </div>
                </div>
              </div>

              <h2>Is CFD trading cheaper than share trading?</h2>
              <p>
                With CFD trading, you are only required to pay a fraction of the
                upfront value of your trade, known as the margin, which means
                you can access a position of equivalent size in the stock market
                for less money. When you buy a physical share you are paying for
                the full cost of the asset upfront. This, however, does not mean
                your total exposure is any different with either method. While
                leverage can amplify profits, it can also amplify losses.
              </p>
              <p>
                The costs associated with CFDs and share trading also differ.
                When trading CFDs with CME-invest.nl, you do not get charged any
                commission; you simply pay the spread and any associated
                overnight fees. However, with shares, depending on your broker,
                you will probably be charged commission, and you have to pay the
                stamp duty exercised on the security.
              </p>
              <h2>Can I use CFDs to hedge my existing share portfolio?</h2>
              <p>
                CFDs provide an excellent insurance opportunity to hedge your
                existing portfolio due to the fact that you can sell short,
                speculating on a price downtrend. Say, for example, that you
                have an existing portfolio of blue chip shares. You want to them
                hold for the long term, but you feel as if the market is about
                to witness a short dip, and you are concerned about how this
                will affect the value of your portfolio. With leveraged trading,
                you can short-sell this market in order to hedge against this
                possibility. If the market slides, what you lose on your
                portfolio can be offset by the gain from your short hedge using
                CFDs. If the market rises, you will lose on your hedge but gain
                on your portfolio.
              </p>
              <h2>Why CFDs?</h2>
              <div class="col-xs-12 bl-table-cnt">
                <div class="row center-xs middle-xs">
                  <div class="col-xs-6 cont-block">
                    <h3>Leverage</h3>
                    The principle selling point of CFDs is that you can trade
                    them on margin, which is known as leveraged trading. When
                    you trade on margin you only need to put down a fraction of
                    the value of the initial outlay and you effectively borrow
                    the remaining capital from your broker. Leverage provides
                    greater exposure with a smaller deposit, making global
                    markets more accessible.
                  </div>
                  <div class="col-xs-6 cont-block">
                    <h3>Speculation</h3>
                    People trade CFDs to capitalise on price movements in the
                    short-term. It is often called ‘swing trading’ and it tends
                    to happen on both bullish and bearish markets. You don’t
                    need expansive research or deep market analysis to trade
                    CFDs. The tricky thing is that it is sometimes very hard to
                    specify the exact date or moment when a price adjustment
                    will come to an end.
                  </div>
                </div>
                <div class="row center-xs middle-xs">
                  <div class="col-xs-6 cont-block">
                    <h3>Hedging</h3>
                    CFDs provide an excellent insurance opportunity to hedge
                    against your existing portfolio due to the fact that you can
                    sell short, speculating on a price downtrend. Say, for
                    example, that you have an existing portfolio of blue chip
                    shares. You want to them hold for the long term, but you
                    feel as if the market is about to witness a short dip, and
                    you are concerned about how this will affect the value of
                    your portfolio. With leveraged trading, you can short-sell
                    on this market in order to hedge against this possibility.
                    If the market slides, what you lose on your portfolio can be
                    offset by the gain from your short hedge using CFDs. If the
                    market rises, then you will lose on your hedge but gain on
                    your portfolio.
                  </div>
                  <div class="col-xs-6 cont-block">
                    <h3>Short selling</h3>
                    You can benefit from a falling market. If you open a short
                    trade when a position is dramatically falling in value, you
                    can benefit from the dip in price.
                  </div>
                </div>
              </div> -->
            </div>
          </div>
        </tab>
      </tabs>
    </div>
    <footer-promo></footer-promo>
  </div>
</template>

<script>
import Tab from "../../components/layout/Tab";
import Tabs from "../../components/layout/Tabs";
import FooterPromo from "@/components/layout/footer/FooterPromo.vue";

export default {
  name: "LearnToTrade",
  components: {
    FooterPromo,
    Tabs,
    Tab
  }
};
</script>
