<template>
  <div class="row advantage-block">
    <div class="col-lg-1 col-md-1 col-sm-12 col-xs-12"></div>
    <div
      v-for="item in this.$i18n.messages[this.$i18n.locale].main.advantages.top"
      v-bind:key="item.title"
      class="col-lg-5 col-md-12 col-sm-12 col-xs-12 advantage-col"
    >
      <div class="row">
        <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
          <i class="fa big-icon" v-bind:class="item.icon"></i>
        </div>
        <div class="col-lg-8 col-md-8 col-sm-12 col-xs-12">
          <div class="row title">
            {{ item.title }}
          </div>
          <div class="row advantages-description middle-lg middle-sm">
            {{ item.text }}
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-1 col-md-1 col-sm-12 col-xs-12"></div>

    <div class="col-lg-1 col-md-1 col-sm-12 col-xs-12"></div>
    <div
      v-for="item in this.$i18n.messages[this.$i18n.locale].main.advantages
        .bottom"
      v-bind:key="item.title"
      class="col-lg-5 col-md-12 col-sm-12 col-xs-12 advantage-col"
    >
      <div class="row">
        <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
          <i class="fa big-icon" v-bind:class="item.icon"></i>
        </div>
        <div class="col-lg-8 col-md-8 col-sm-12 col-xs-12">
          <div class="row title">
            {{ item.title }}
          </div>
          <div class="row advantages-description middle-lg middle-sm">
            {{ item.text }}
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-1 col-md-1 col-sm-12 col-xs-12"></div>
  </div>
</template>

<script>
export default {
  name: "Advantages"
};
</script>


