<template>
  <div class="row">
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <div class="row center-lg center-md center-sm center-xs trade-title">
        <h1>{{ $t("trade_indices.title") }}</h1>
      </div>
    </div>
    <div
      class="col-lg-12 col-md-12 col-sm-12 col-xs-12 trade-sub-title center-lg center-md center-sm center-xs"
    >
      {{ $t("trade_indices.text") }}
    </div>
    <div
      class="col-lg-12 col-md-12 col-sm-12 col-xs-12 center-lg center-md center-sm center-xs var-bl"
    >
      <div class="row">
        <table class="margin-table col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <tr class="row head-tbl">
            <th class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
              {{ $t("trade_indices.country") }}
            </th>
            <th class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
              {{ $t("trade_indices.general_info") }}
            </th>
            <th class="col-lg-4 center-lg center-md center-sm center-xs"></th>
          </tr>
          <tr class="row middle-lg middle-md middle-sm middle-xs">
            <td class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
              <img src="@/assets/market-types/indices/usa.png" />
            </td>
            <td class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
              <div class="market-type-description">
                {{ $t("trade_indices.table_text1") }} US
                {{ $t("trade_indices.table_text2") }}
              </div>
            </td>
            <td class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
              <div class="button-start">
                <button @click="redirectToRegistration()">
                  {{ $t("trade_indices.start_trading") }}
                </button>
              </div>
            </td>
          </tr>
          <tr class="row middle-lg middle-md middle-sm middle-xs">
            <td class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
              <img src="@/assets/market-types/indices/united-kingdom.png" />
            </td>
            <td class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
              <div class="market-type-description">
                {{ $t("trade_indices.table_text1") }} UK
                {{ $t("trade_indices.table_text2") }}
              </div>
            </td>
            <td class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
              <div class="button-start">
                <button @click="redirectToRegistration()">
                  {{ $t("trade_indices.start_trading") }}
                </button>
              </div>
            </td>
          </tr>
          <tr class="row middle-lg middle-md middle-sm middle-xs">
            <td class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
              <img src="@/assets/market-types/indices/china.png" />
            </td>
            <td class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
              <div class="market-type-description">
                {{ $t("trade_indices.table_text1") }} CN
                {{ $t("trade_indices.table_text2") }}
              </div>
            </td>
            <td class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
              <div class="button-start">
                <button @click="redirectToRegistration()">
                  {{ $t("trade_indices.start_trading") }}
                </button>
              </div>
            </td>
          </tr>
          <tr class="row middle-lg middle-md middle-sm middle-xs">
            <td class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
              <img src="@/assets/market-types/indices/germany.png" />
            </td>
            <td class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
              <div class="market-type-description">
                {{ $t("trade_indices.table_text1") }} DE
                {{ $t("trade_indices.table_text2") }}
              </div>
            </td>
            <td class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
              <div class="button-start">
                <button @click="redirectToRegistration()">
                  {{ $t("trade_indices.start_trading") }}
                </button>
              </div>
            </td>
          </tr>
          <tr class="row middle-lg middle-md middle-sm middle-xs">
            <td class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
              <img src="@/assets/market-types/indices/france.png" />
            </td>
            <td class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
              <div class="market-type-description">
                {{ $t("trade_indices.table_text1") }} FR
                {{ $t("trade_indices.table_text2") }}
              </div>
            </td>
            <td class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
              <div class="button-start">
                <button @click="redirectToRegistration()">
                  {{ $t("trade_indices.start_trading") }}
                </button>
              </div>
            </td>
          </tr>
          <tr class="row middle-lg middle-md middle-sm middle-xs">
            <td class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
              <img src="@/assets/market-types/indices/russia.png" />
            </td>
            <td class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
              <div class="market-type-description">
                {{ $t("trade_indices.table_text1") }} RU
                {{ $t("trade_indices.table_text2") }}
              </div>
            </td>
            <td class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
              <div class="button-start">
                <button @click="redirectToRegistration()">
                  {{ $t("trade_indices.start_trading") }}
                </button>
              </div>
            </td>
          </tr>
          <tr class="row middle-lg middle-md middle-sm middle-xs">
            <td class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
              <img src="@/assets/market-types/indices/spain.png" />
            </td>
            <td class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
              <div class="market-type-description">
                {{ $t("trade_indices.table_text1") }} ES
                {{ $t("trade_indices.table_text2") }}
              </div>
            </td>
            <td class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
              <div class="button-start">
                <button @click="redirectToRegistration()">
                  {{ $t("trade_indices.start_trading") }}
                </button>
              </div>
            </td>
          </tr>
          <tr class="row middle-lg middle-md middle-sm middle-xs">
            <td class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
              <img src="@/assets/market-types/indices/switzerland.png" />
            </td>
            <td class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
              <div class="market-type-description">
                {{ $t("trade_indices.table_text1") }} CH
                {{ $t("trade_indices.table_text2") }}
              </div>
            </td>
            <td class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
              <div class="button-start">
                <button @click="redirectToRegistration()">
                  {{ $t("trade_indices.start_trading") }}
                </button>
              </div>
            </td>
          </tr>
        </table>
      </div>
    </div>
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <why-us-promo></why-us-promo>
    </div>
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <advantages></advantages>
    </div>
    <footer-promo></footer-promo>
  </div>
</template>

<script>
import WhyUsPromo from "@/components/layout/WhyUsPromo";
import Advantages from "@/components/layout/Advantages";
import FooterPromo from "@/components/layout/footer/FooterPromo.vue";
import router from "@/router";

export default {
  name: "TradeCommodities",
  components: {
    Advantages,
    FooterPromo,
    WhyUsPromo
  },
  methods: {
    redirectToRegistration: function() {
      router.push("/registration");
    }
  }
};
</script>
