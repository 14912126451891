<template>
  <div class="row">
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <div class="row center-lg center-md center-sm center-xs trade-title">
        <h1>{{ $t("markets.title") }}</h1>
      </div>
    </div>
    <div
      class="col-lg-12 col-md-12 col-sm-12 col-xs-12 trade-sub-title center-lg center-md center-sm center-xs"
    >
      {{ $t("markets.text") }}
    </div>
    <div
      class="col-lg-12 col-md-12 col-sm-12 col-xs-12 trade-image center-lg center-md center-sm center-xs"
    >
      <img src="@/assets/market-types/shares/world-logo.png" />
    </div>
    <div
      class="col-lg-12 col-md-12 col-sm-12 col-xs-12 center-lg center-md center-sm center-xs var-bl"
    >
      <div class="row">
        <table class="margin-table col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <tr class="row head-tbl">
            <th class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
              {{ $t("markets.country") }}
            </th>
            <th class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
              {{ $t("markets.general_info") }}
            </th>
            <th
              class="col-lg-4 col-md-4 col-sm-4 col-xs-4 center-lg center-md center-sm center-xs"
            ></th>
          </tr>
          <tr class="row middle-lg">
            <td class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
              <img src="@/assets/market-types/shares/usa.png" />
            </td>
            <td class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
              <div class="market-type-description">
                {{ $t("markets.table_text1") }} US
                {{ $t("markets.table_text2") }}
              </div>
            </td>
            <td class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
              <div class="button-start">
                <button @click="redirectToRegistration()">
                  {{ $t("markets.start_trading") }}
                </button>
              </div>
            </td>
          </tr>
          <tr class="row middle-lg">
            <td class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
              <img src="@/assets/market-types/shares/united-kingdom.png" />
            </td>
            <td class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
              <div class="market-type-description">
                {{ $t("markets.table_text1") }} UK
                {{ $t("markets.table_text2") }}
              </div>
            </td>
            <td class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
              <div class="button-start">
                <button @click="redirectToRegistration()">
                  {{ $t("markets.start_trading") }}
                </button>
              </div>
            </td>
          </tr>
          <tr class="row middle-lg">
            <td class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
              <img src="@/assets/market-types/shares/china.png" />
            </td>
            <td class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
              <div class="market-type-description">
                {{ $t("markets.table_text1") }} CN
                {{ $t("markets.table_text2") }}
              </div>
            </td>
            <td class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
              <div class="button-start">
                <button @click="redirectToRegistration()">
                  {{ $t("markets.start_trading") }}
                </button>
              </div>
            </td>
          </tr>
          <tr class="row middle-lg">
            <td class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
              <img src="@/assets/market-types/shares/germany.png" />
            </td>
            <td class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
              <div class="market-type-description">
                {{ $t("markets.table_text1") }} DE
                {{ $t("markets.table_text2") }}
              </div>
            </td>
            <td class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
              <div class="button-start">
                <button @click="redirectToRegistration()">
                  {{ $t("markets.start_trading") }}
                </button>
              </div>
            </td>
          </tr>
          <tr class="row middle-lg">
            <td class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
              <img src="@/assets/market-types/shares/france.png" />
            </td>
            <td class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
              <div class="market-type-description">
                {{ $t("markets.table_text1") }} FN
                {{ $t("markets.table_text2") }}
              </div>
            </td>
            <td class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
              <div class="button-start">
                <button @click="redirectToRegistration()">
                  {{ $t("markets.start_trading") }}
                </button>
              </div>
            </td>
          </tr>
          <tr class="row middle-lg">
            <td class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
              <img src="@/assets/market-types/shares/russia.png" />
            </td>
            <td class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
              <div class="market-type-description">
                {{ $t("markets.table_text1") }} RU
                {{ $t("markets.table_text2") }}
              </div>
            </td>
            <td class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
              <div class="button-start">
                <button @click="redirectToRegistration()">
                  {{ $t("markets.start_trading") }}
                </button>
              </div>
            </td>
          </tr>
          <tr class="row middle-lg">
            <td class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
              <img src="@/assets/market-types/shares/spain.png" />
            </td>
            <td class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
              <div class="market-type-description">
                {{ $t("markets.table_text1") }} ES
                {{ $t("markets.table_text2") }}
              </div>
            </td>
            <td class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
              <div class="button-start">
                <button @click="redirectToRegistration()">
                  {{ $t("markets.start_trading") }}
                </button>
              </div>
            </td>
          </tr>
          <tr class="row middle-lg">
            <td class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
              <img src="@/assets/market-types/shares/switzerland.png" />
            </td>
            <td class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
              <div class="market-type-description">
                {{ $t("markets.table_text1") }} CH
                {{ $t("markets.table_text2") }}
              </div>
            </td>
            <td class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
              <div class="button-start">
                <button @click="redirectToRegistration()">
                  {{ $t("markets.start_trading") }}
                </button>
              </div>
            </td>
          </tr>
        </table>
      </div>
    </div>

    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <why-us-promo></why-us-promo>
    </div>
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <advantages></advantages>
    </div>
    <footer-promo></footer-promo>
  </div>
</template>

<script>
import WhyUsPromo from "@/components/layout/WhyUsPromo";
import Advantages from "@/components/layout/Advantages";
import FooterPromo from "@/components/layout/footer/FooterPromo.vue";
import router from "@/router";

export default {
  name: "MarketShares",
  components: {
    Advantages,
    FooterPromo,
    WhyUsPromo
  },
  methods: {
    redirectToRegistration: function() {
      router.push("/registration");
    }
  }
};
</script>
