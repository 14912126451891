<template>
  <div class="row">
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <div class="row center-lg center-md center-sm center-xs trade-title">
        <h1>{{ $t("trade_commodities.title") }}</h1>
      </div>
    </div>
    <div
      class="col-lg-12 col-md-12 col-sm-12 col-xs-12 trade-sub-title center-lg center-md center-sm center-xs"
    >
      {{ $t("trade_commodities.text") }}
    </div>
    <div
      class="col-lg-12 col-md-12 col-sm-12 col-xs-12 center-lg center-md center-sm center-xs var-bl"
    >
      <div class="row">
        <table class="margin-table col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <tr class="row head-tbl">
            <th class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
              {{ $t("trade_commodities.commodity") }}
            </th>
            <th class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
              {{ $t("trade_commodities.general_info") }}
            </th>
            <th
              class="col-lg-4 col-md-4 col-sm-4 col-xs-4 center-lg center-md center-sm center-xs"
            ></th>
          </tr>
          <tr class="row middle-lg middle-md middle-sm middle-xs">
            <td class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
              <img src="@/assets/market-types/commodities/metalls.png" />
            </td>
            <td class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
              <div class="market-type-description">
                {{ $t("trade_commodities.table_text1") }} metalls{{
                  $t("trade_commodities.table_text2")
                }}
              </div>
            </td>
            <td class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
              <div class="button-start">
                <button @click="redirectToRegistration()">
                  {{ $t("trade_commodities.start_trading") }}
                </button>
              </div>
            </td>
          </tr>
          <tr class="row middle-lg middle-md middle-sm middle-xs">
            <td class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
              <img src="@/assets/market-types/commodities/fuel.png" />
            </td>
            <td class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
              <div class="market-type-description">
                {{ $t("trade_commodities.table_text1") }} fuel{{
                  $t("trade_commodities.table_text2")
                }}
              </div>
            </td>
            <td class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
              <div class="button-start">
                <button @click="redirectToRegistration()">
                  {{ $t("trade_commodities.start_trading") }}
                </button>
              </div>
            </td>
          </tr>
          <tr class="row middle-lg middle-md middle-sm middle-xs">
            <td class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
              <img src="@/assets/market-types/commodities/corn.png" />
            </td>
            <td class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
              <div class="market-type-description">
                {{ $t("trade_commodities.table_text1") }} corn{{
                  $t("trade_commodities.table_text2")
                }}
              </div>
            </td>
            <td class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
              <div class="button-start">
                <button @click="redirectToRegistration()">
                  {{ $t("trade_commodities.start_trading") }}
                </button>
              </div>
            </td>
          </tr>
          <tr class="row middle-lg middle-md middle-sm middle-xs">
            <td class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
              <img src="@/assets/market-types/commodities/beans.png" />
            </td>
            <td class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
              <div class="market-type-description">
                {{ $t("trade_commodities.table_text1") }} beans{{
                  $t("trade_commodities.table_text2")
                }}
              </div>
            </td>
            <td class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
              <div class="button-start">
                <button @click="redirectToRegistration()">
                  {{ $t("trade_commodities.start_trading") }}
                </button>
              </div>
            </td>
          </tr>
          <tr class="row middle-lg middle-md middle-sm middle-xs">
            <td class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
              <img src="@/assets/market-types/commodities/balks.png" />
            </td>
            <td class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
              <div class="market-type-description">
                {{ $t("trade_commodities.table_text1") }} balks{{
                  $t("trade_commodities.table_text2")
                }}
              </div>
            </td>
            <td class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
              <div class="button-start">
                <button @click="redirectToRegistration()">
                  {{ $t("trade_commodities.start_trading") }}
                </button>
              </div>
            </td>
          </tr>
          <tr class="row middle-lg middle-md middle-sm middle-xs">
            <td class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
              <img src="@/assets/market-types/commodities/oil.png" />
            </td>
            <td class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
              <div class="market-type-description">
                {{ $t("trade_commodities.table_text1") }} oils{{
                  $t("trade_commodities.table_text2")
                }}
              </div>
            </td>
            <td class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
              <div class="button-start">
                <button @click="redirectToRegistration()">
                  {{ $t("trade_commodities.start_trading") }}
                </button>
              </div>
            </td>
          </tr>
          <tr class="row middle-lg middle-md middle-sm middle-xs">
            <td class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
              <img src="@/assets/market-types/commodities/boards.png" />
            </td>
            <td class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
              <div class="market-type-description">
                {{ $t("trade_commodities.table_text1") }} boards{{
                  $t("trade_commodities.table_text2")
                }}
              </div>
            </td>
            <td class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
              <div class="button-start">
                <button @click="redirectToRegistration()">
                  {{ $t("trade_commodities.start_trading") }}
                </button>
              </div>
            </td>
          </tr>
          <tr class="row middle-lg middle-md middle-sm middle-xs">
            <td class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
              <img src="@/assets/market-types/commodities/rubber.png" />
            </td>
            <td class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
              <div class="market-type-description">
                {{ $t("trade_commodities.table_text1") }} rubber{{
                  $t("trade_commodities.table_text2")
                }}
              </div>
            </td>
            <td class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
              <div class="button-start">
                <button @click="redirectToRegistration()">
                  {{ $t("trade_commodities.start_trading") }}
                </button>
              </div>
            </td>
          </tr>
        </table>
      </div>
    </div>

    <div class="col-lg-12">
      <why-us-promo></why-us-promo>
    </div>
    <div class="col-lg-12">
      <advantages></advantages>
    </div>
    <footer-promo></footer-promo>
  </div>
</template>

<script>
import WhyUsPromo from "../../components/layout/WhyUsPromo";
import Advantages from "../../components/layout/Advantages";
import FooterPromo from "@/components/layout/footer/FooterPromo.vue";
import router from "@/router";

export default {
  name: "TradeCommodities",
  components: {
    Advantages,
    FooterPromo,
    WhyUsPromo
  },
  methods: {
    redirectToRegistration: function() {
      router.push("/registration");
    }
  }
};
</script>
