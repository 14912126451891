var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row faq-tabs"},[_c('div',{staticClass:"col-lg-12 col-md-12 col-sm-12 col-xs-12 first-question"},[_c('div',{staticClass:"row center-lg center-md center-sm center-xs middle-lg middle-md middle-sm middle-xs"},[_c('div',{staticClass:"col-lg-12 col-md-12 col-sm-12 col-xs-12 question"},[_vm._v(" "+_vm._s(_vm.$t("learn_to_trade.banner_title"))+" ")]),_c('div',{staticClass:"col-lg-6 col-md-6 col-sm-6 col-xs-6 question-sub-title"},[_vm._v(" "+_vm._s(_vm.$t("learn_to_trade.banner_text"))+" ")])])]),_c('div',{staticClass:"col-lg-12 col-md-12 col-sm-12 col-xs-12 faq-tabs learn-nav"},[_c('tabs',{staticClass:"row"},[_c('tab',{attrs:{"name":this.$i18n.messages[this.$i18n.locale].learn_to_trade
            .what_is_trading.title,"selected":true}},[_c('div',{staticClass:"col-lg-12 col-md-12 col-sm-12 col-xs-12"},[_c('div',{staticClass:"row"},_vm._l((this.$i18n.messages[this.$i18n.locale]
                .learn_to_trade.what_is_trading.items),function(item){return _c('div',{key:item.title},[_c('h2',[_vm._v(_vm._s(item.title))]),_c('p',{domProps:{"innerHTML":_vm._s(item.text)}})])}),0)])]),_c('tab',{attrs:{"name":this.$i18n.messages[this.$i18n.locale].learn_to_trade.how_to_trade
            .title}},[_c('div',{staticClass:"col-lg-12 col-md-12 col-sm-12 col-xs-12"},[_c('div',{staticClass:"row"},_vm._l((this.$i18n.messages[this.$i18n.locale]
                .learn_to_trade.how_to_trade.items),function(item){return _c('div',{key:item.title},[_c('h2',[_vm._v(_vm._s(item.title))]),_c('p',{domProps:{"innerHTML":_vm._s(item.text)}})])}),0)])]),_c('tab',{attrs:{"name":this.$i18n.messages[this.$i18n.locale].learn_to_trade.cfd_markets
            .title}},[_c('div',{staticClass:"col-lg-12 col-md-12 col-sm-12 col-xs-12"},[_c('div',{staticClass:"row"},_vm._l((this.$i18n.messages[this.$i18n.locale]
                .learn_to_trade.cfd_markets.items),function(item){return _c('div',{key:item.title},[_c('h2',[_vm._v(_vm._s(item.title))]),_c('p',{domProps:{"innerHTML":_vm._s(item.text)}})])}),0)])]),_c('tab',{attrs:{"name":this.$i18n.messages[this.$i18n.locale].learn_to_trade
            .cfd_trading_explained.title}},[_c('div',{staticClass:"col-lg-12 col-md-12 col-sm-12 col-xs-12"},[_c('div',{staticClass:"row"},_vm._l((this.$i18n.messages[this.$i18n.locale]
                .learn_to_trade.cfd_trading_explained.items),function(item){return _c('div',{key:item.title},[_c('h2',[_vm._v(_vm._s(item.title))]),_c('p',{domProps:{"innerHTML":_vm._s(item.text)}})])}),0)])]),_c('tab',{attrs:{"name":this.$i18n.messages[this.$i18n.locale].learn_to_trade
            .cdf_vs_share_trading.title}},[_c('div',{staticClass:"col-lg-12 col-md-12 col-sm-12 col-xs-12"},[_c('div',{staticClass:"row"},_vm._l((this.$i18n.messages[this.$i18n.locale]
                .learn_to_trade.cdf_vs_share_trading.items),function(item){return _c('div',{key:item.title},[_c('p',{domProps:{"innerHTML":_vm._s(item.text)}})])}),0)])])],1)],1),_c('footer-promo')],1)}
var staticRenderFns = []

export { render, staticRenderFns }